import { React, useState, forwardRef, useEffect, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import '../../../components/styles/SortingTable.css';
import {
  Avatar,
  Box,
  Card,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  AvatarGroup,
  Table,
  FormControl,
  InputLabel,
  Select,
  TableBody,
  MenuItem,
  TableCell,
  TableHead,
  Autocomplete,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  useTheme,
  useMediaQuery,
  Typography,
  Dialog,
  Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import numeral from "numeral";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);
const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};
     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (projects, query, filters) => {
  return projects.filter((project) => {
    let matches = true;

    if (query) {
      const properties = ['broj_predmet','status_klient', 'sud', 'faza_predmet', 'tip_predmet', 'status' , 'osnov' , 'system_number' , 'reference_broj' , 'vrednost', 'interen_broj'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (project[property] && project[property].toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      project.members && project.members.forEach((member) => {
        if (member.first_name && member.first_name.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      project.clients && project.clients.forEach((member) => {
        if (member.Naziv && member.Naziv.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      project.contacts && project.contacts.forEach((member) => {
        if (member.naziv_sprotivna_stranka && member.naziv_sprotivna_stranka.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      if (filters.status && project.status !== filters.status) {
        matches = false;
      }
      if (!containsQuery) {
        matches = false;
      }
    }


    if (filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if(key === 'status' && filters.status !== null) {
          if (value && project[key] !== value) {
            matches = false;
          }
        }

        if(key === 'client' && filters.client !== null){
          if(!project.clients.find((r) => r.ID === filters.client)){
            matches = false
          }


        }


        // if(value === null && project[key] === 'Завршен') {
        //   matches = false;
        // }
      });
    }

    return matches;
  });
};
const applyPagination = (projects, page, limit) => {
  return projects.slice(page * limit, page * limit + limit);
};

const Results = ({ projects,reload,type,clients, ...rest }) => {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({
    status: null,
    client: null,
  });
  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };
  const statusOptions = [
    {
      id: 'all',
      name: 'Сите'
    },
    {
      id: 'Активен',
      name: 'Активен'
    },
    {
      id: 'Незапочнат',
      name: 'Незапочнат'
    },
    {
      id: 'Завршен',
      name: 'Завршен'
    }
  ];
  const handleStatusChange = (e) => {
    let value = null;
    if (e.target.value !== 'all') {
      value = e.target.value;
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value,
    }));
  };

  const handleClientChange = (client) => {
    if(client && client.hasOwnProperty('ID')){
      setFilters((prevFilters) => ({
        ...prevFilters,
        client: client.ID,
      }));
    }else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        client: null,
      }));
    }

  };
  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };
  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };
  const filteredProjects = applyFilters(projects, query, filters);
  const paginatedProjects = applyPagination(filteredProjects, page, limit);
  function stringAvatar(name) {
    if(name && name.length !== 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}`,
      };
    } else {
      return name;
    }

  }
  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const handleConfirmDelete = (id) => {
    setOpenConfirmDelete(true);
    setDeleteID(id);
  };
  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setDeleteID(null);
  };
  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);
    window.makeRequest("DEL", `/api/Predmet?ID=${deleteID}`, false, true, (callback) => {
      reload();
      enqueueSnackbar('Предметот е успешно избришан', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
      setDeleteID(null);
    }, (err) => {
      console.log('err', err);
    });


  };
  const minimizeWindow = useMediaQuery(theme.breakpoints.down('lg'));

  //sort

  const { items, requestSort, sortConfig } = useSortableData(paginatedProjects);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };


  return (
    <>
      <Card sx={{ p: 1, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} display={'flex'}>
            <Grid item p={1} xs={6}>
              <TextField
                sx={{ m: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={'Пребарувај по име на предмет...'}
                value={query}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item p={1} xs={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e,v) => handleClientChange(v)}
                  options={clients}
                  getOptionLabel={option => option.Naziv}
                  renderInput={(params) => <TextField {...params} fullWidth label="Клиенти" />}
                />
            </Grid>
            <Grid item p={1} xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>Статус</InputLabel>
                <Select
                  value={filters.status || 'all'}
                  onChange={handleStatusChange}
                  label={'Прикажи'}
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      {projects.length > 0 && (
        <Card>
            <Box
              px={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography component="span" variant="subtitle1">
                  {'Прикажано'}:
                </Typography>{' '}
                <b>{paginatedProjects.length}</b> <b>{'предмети'}</b>
              </Box>
              <TablePagination
                component="div"
                count={filteredProjects.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          <Divider />

          {paginatedProjects.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {"Нема пронајдено предмет"}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {filters.status === 'Завршен' && <TableCell className={getClassNamesFor('sud')} onClick={() => requestSort('klauzuli')}>{'Клаузули'}</TableCell>}
                      <TableCell >{'Назив на странка'}</TableCell>
                      <TableCell >{'Спротивна странка'}</TableCell>
                      <TableCell className={getClassNamesFor('sud')}  onClick={() => requestSort('sud')}>{'Институција'}</TableCell>
                      <TableCell className={getClassNamesFor('broj_predmet')} onClick={() => requestSort('broj_predmet')}>{'Број Предмет'}</TableCell>
                      <TableCell className={getClassNamesFor('osnov')} onClick={() => requestSort('osnov')}>{'Основ'}</TableCell>
                      {type !== 'client' &&<TableCell className={getClassNamesFor('vrednost')}  onClick={() => requestSort('vrednost')}>{'Вредност'}</TableCell>}
                      <TableCell className={getClassNamesFor('faza_predmet')}  onClick={() => requestSort('faza_predmet')}>{'Фаза на предмет'}</TableCell>
                      <TableCell className={getClassNamesFor('status')}  onClick={() => requestSort('status')}>{'Статус'}</TableCell>
                      <TableCell>{'Вработени'}</TableCell>
                      <TableCell align="center">{'Actions'}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((project) => {
                      return (
                        <TableRow
                          hover
                          key={project.ID}
                        >
                          {filters.status === 'Завршен' && <TableCell align="center">
                            <Typography noWrap>
                                <IconButton color="success">
                                  {project.klauzuli && <CheckCircleIcon fontSize="small" />}
                                </IconButton>
                            </Typography>
                          </TableCell>}
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.clients.length > 0 && (
                                <AvatarGroup max={4}>
                                  {project.clients.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.Naziv}
                                    >
                                      {project.clients.length === 1 ?
                                       <Typography
                                        key={member.ID}>{member.Naziv}</Typography>
                                      : <Avatar
                                        {...stringAvatar(member.Naziv)}
                                        sx={{ width: 30, height: 30 }}
                                        key={member.ID}
                                      />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.contacts.length > 0 && (
                                <AvatarGroup max={4}>
                                  {project.contacts.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.naziv_sprotivna_stranka}
                                    >
                                      {project.contacts.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.naziv_sprotivna_stranka}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.naziv_sprotivna_stranka)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                            {minimizeWindow ? <Tooltip arrow placement="top" key={project.sud} title={project.sud} ><Typography noWrap variant="h5">
                              {project.sud}
                            </Typography></Tooltip> :
                              <Typography noWrap variant="h5">
                                {project.sud}
                              </Typography>}
                          </TableCell>
                          <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                            {minimizeWindow ? <Tooltip arrow placement="top" key={project.broj_predmet} title={project.broj_predmet} ><Typography noWrap variant="h5">
                                {project.broj_predmet}
                              </Typography></Tooltip> :
                              <Typography noWrap variant="h5">
                                {project.broj_predmet}
                              </Typography>}
                          </TableCell>
                          <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                            {minimizeWindow ? <Tooltip arrow placement="top" key={project.osnov} title={project.osnov} ><Typography noWrap variant="h5">
                                {project.osnov}
                              </Typography></Tooltip> :
                              <Typography noWrap variant="h5">
                                {project.osnov}
                              </Typography>}
                          </TableCell>
                          {type !== 'client' &&<TableCell>
                            <Typography noWrap variant="h5">
                              {numeral(project.vrednost).format(`0,0`)}
                            </Typography>
                          </TableCell>}
                          <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                            {minimizeWindow ? <Tooltip arrow placement="top" key={project.faza_predmet} title={project.faza_predmet} ><Typography noWrap variant="h5">
                                {project.faza_predmet}
                              </Typography></Tooltip> :
                              <Typography noWrap variant="h5">
                                {project.faza_predmet}
                              </Typography>}
                          </TableCell>
                          <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                            {minimizeWindow ? <Tooltip arrow placement="top" key={project.status} title={project.status} ><Typography noWrap variant="h5">
                                {project.status}
                              </Typography></Tooltip> :
                              <Typography noWrap variant="h5">
                                {project.status}
                              </Typography>}
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.members.length > 0 && (
                                <AvatarGroup variant={'circular'} max={4}>
                                  {project.members.map((member) => (
                                    member.uloga !== 'client' &&<Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.first_name}
                                    >
                                      {project.members.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.first_name}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.first_name)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={'Преглед'} component={RouterLink}
                                  to={'/predmet/' + project.ID} arrow>
                                <IconButton color="primary">
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {project.user_write === true &&<Tooltip title={'Избриши'} arrow>
                                <IconButton
                                  onClick={() => handleConfirmDelete(project.ID)}
                                  color="primary"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box px={2}>
                <TablePagination
                  component="div"
                  count={filteredProjects.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
      {projects.length === 0 && (
        <Card sx={{ textAlign: 'center', p: 3 }}>
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{ my: 5 }}
            gutterBottom
          >
            {'Во моментов нема прикажани предмети'}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ pt: 4, px: 6 }} variant="h1">
            {'Дали сакате да го избришете предметот'}?
          </Typography>

          <Typography
            align="center"
            sx={{ pt: 2, pb: 4, px: 6 }}
            fontWeight="normal"
            color="text.secondary"
            variant="h3"
          >
            {"Нема можност за повторно враќање"}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {'Затвори'}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {'Избриши'}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  projects: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
  type: PropTypes.any,
};

Results.defaultProps = {
  projects: []
};

export default Results;

const useSortableData = (items, config = null, filter, sort) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
