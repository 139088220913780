import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom,
  Typography,
  TextField,
  CircularProgress,
  Button
} from '@mui/material';
import { withSnackbar } from 'notistack';
class AddContactModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: false,
        naziv_sprotivna_stranka: '',
        embg_embs: '',
        banka: '',
        isSubmitting: false,
        polnomosnikID: '',
        adresa: '',
        telefon: '',
        email: '',
    }

  }

  componentDidMount() {
   
  }


  handleCreateContactClose = () => {
    this.setState({
      open :false,
      naziv_sprotivna_stranka: '',
      embg_embs: '',
      adresa: '',
      telefon: '',
      email: '',
      banka: '',
      isSubmitting: false,
      polnomosnikID: '',
    }, () => this.props.onClose());
  };

  handleChange = (e) => {this.setState({[e.target.name]: e.target.value,})}
  createContact = () => {
    window.makeRequest("POST", `/api/Contacts`, {
      naziv_sprotivna_stranka: this.state.naziv_sprotivna_stranka,
      embg_embs: this.state.embg_embs,
      adresa: this.state.adresa,
      telefon: this.state.telefon,
      email: this.state.email,
      polnomosnikID: this.state.polnomosnikID,
      created_at: new Date(),
    }, true, (callback) => {
      this.setState({open: false}, this.handleCreateContactClose)
    }, (err) => {
      console.log('err', err);
    });
    this.props.enqueueSnackbar('Контактот е успешно креиран', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });

  }
  handleCreateContactSuccess = () => {
    this.setState({isSubmitting: true});
    setTimeout(() => {
      this.createContact();
    }, 1000)
  };


  render() {
    return (
      <>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.props.open ? this.props.open : false}
          onClose={this.handleCreateContactClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Нов контакт'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Потполнете ги празните полина за да креирате и да додадете нов контакт'
              }
            </Typography>
          </DialogTitle>
        
          <DialogContent dividers sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Назив'}
                      name="naziv_sprotivna_stranka"
                      onChange={this.handleChange}
                      value={this.state.naziv_sprotivna_stranka}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'ЕМБГ/ЕМБС'}
                      name="embg_embs"
                      onChange={this.handleChange}
                      value={this.state.embg_embs}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'Адреса'}
                      name="adresa"
                      onChange={this.handleChange}
                      value={this.state.adresa}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'Телефонски број'}
                      name="telefon"
                      onChange={this.handleChange}
                      value={this.state.telefon}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'Е-маил адреса'}
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      value={this.state.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Полномошник'}
                      name="polnomosnikID"
                      onChange={this.handleChange}
                      value={this.state.polnomosnikID}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button color="secondary" onClick={this.handleCreateContactClose}>
              {'Затвори'}
            </Button>
            <Button
              type="submit"
              startIcon={
                this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
              }
              disabled={this.state.isSubmitting}
              onClick={this.handleCreateContactSuccess}
              variant="contained"
            >
              {'Креирај'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  
}

export default withSnackbar(AddContactModal);
