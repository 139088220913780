import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom,
  Typography,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  CircularProgress,
  Autocomplete,
  Button
} from '@mui/material';
import { withSnackbar } from 'notistack';

const banks = [
  { label: 'Стопанска Банка АД Скопје', value: 'stopanska_ad_skopje' },
  { label: 'НЛБ Тутунска Банка АД Скопје', value: 'nlb_tutunska_skopje' },
  { label: 'Комерцијална банка АД Скопје', value: 'komercijalna_skopje' },
  { label: 'Халк Банка АД Скопје', value: 'halk_skopje' },
  { label: 'Шпаркасе Банка Македонија АД Скопје', value: 'sparkase' },
  { label: 'Силк Роуд Банка АД Скопје', value: 'silk_road' },
  { label: 'ТТК Банка АД Скопје', value: 'ttk_skopje' },
  { label: 'Капитал Банка АД Скопје', value: 'kapital_skopje' },
  { label: 'Прокредит Банка АД Скопје', value: 'prokredit' },
  { label: 'Стопанска Банка АД Битола', value: 'stopanska_ad_bitola' },
  { label: 'Народната Банка на Република Северна Македонија', value: 'narodna' },
  { label: 'Универзална Инвестициона Банка АД Скопје', value: 'univerzalna_investiciona' },
  { label: 'Македонска Банка за подршка на развојот АД Скопје', value: 'makedonska_banka_za_poddrska_na_razvojot' },
];

const status = [
  { label: 'Активен', value: 'aktiven' },
  { label: 'Неактивен', value: 'neaktiven' },
  { label: 'Блокиран', value: 'blokiran' },

];
class AddClientModal extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isSubmitting: false,
      naziv: '',
      embg_embs: '',
      Adresa: '',
      telefon: '',
      email: '',
      banka: '',
      broj_smetka: '',
  }

  }

  handleCreateUserOpen = () => {
    this.setState({open :true});
  };

  handleCreateClientClose = () => {

    this.setState({
      open :false,
      naziv: '',
      embg_embs: '',
      Adresa: '',
      telefon: '',
      email: '',
      banka: '',
      broj_smetka: '',
      isSubmitting: false,
    }, this.props.onClose);
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value,})
  }

  handleInputChangeBank = (e, value) => {
        value ? this.setState({banka: e.target.value}) : this.setState({banka: ""})
  }

  handleInputChangeStatus = (e, value, test) => {
    status.map((stat) => {
      if(stat.label === value) {
        this.setState({status: stat.value})
      }
    })
  }

  createClient = () => {
    window.makeRequest("POST", `/api/Clients`, {
      Naziv: this.state.naziv,
      embg_embs: this.state.embg_embs,
      Adresa: this.state.Adresa,
      tel_broj: this.state.tel_broj,
      broj_smetka: this.state.broj_smetka,
      banka: this.state.banka,
      email: this.state.email,
      status: this.state.status,
      IsPrivate: false,
      created_at: new Date(),
    }, true, (callback) => {
      this.setState({open: false}, this.handleCreateClientClose)
    }, (err) => {
      console.log('err', err);
    });

    this.props.enqueueSnackbar('Клиентот е успешно креиран', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  }

  handleCreateClientSuccess = () => {
    this.setState({isSubmitting: true});
    setTimeout(() => {
      this.createClient();
    }, 1000)
  };


  render() {
    return (
      <>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.props.open}
          onClose={() => this.props.onClose()}        
          >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Нов клиент'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Потполнете ги празните полина за да креирате и да додадете нов клиент'
              }
            </Typography>
          </DialogTitle>
        
          <DialogContent dividers sx={{ p: 3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={'Назив'}
                      name="naziv"
                      onChange={this.handleChange}
                      value={this.state.naziv}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'ЕМБГ/ЕМБС'}
                      name="embg_embs"
                      onChange={this.handleChange}
                      value={this.state.embg_embs}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'Адреса'}
                      name="Adresa"
                      onChange={this.handleChange}
                      value={this.state.Adresa}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'Телефонски број'}
                      name="tel_broj"
                      onChange={this.handleChange}
                      value={this.state.tel_broj}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label={'Е-маил адреса'}
                      name="email"
                      onChange={this.handleChange}
                      type="email"
                      value={this.state.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}  >
                    <FormControl fullWidth>
                      <InputLabel >Банка</InputLabel>
                      <Select
                        value={this.state.banka}
                        label="Банка"
                        name='banka'
                        onChange={this.handleInputChangeBank}
                      >
                        {banks.map((bank) => {
                          return<MenuItem key={bank.value} value={bank.value}>{bank.label}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6} >
                    <TextField
                      fullWidth
                      label={'Број на сметка'}
                      name="broj_smetka"
                      onChange={this.handleChange}
                      value={this.state.broj_smetka}
                      variant="outlined"
                    />
                  </Grid>  

                  <Grid item xs={12} md={6} >
                    <Autocomplete
                      disablePortal
                      options={status}
                      defaultValue={status[0]}
                      getOptionLabel={(option) => option.label}
                      onInputChange={this.handleInputChangeStatus}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={'Статус'}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button color="secondary" onClick={() => this.props.onClose()}>
              {'Затвори'}
            </Button>
            <Button
              type="submit"
              startIcon={
                this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
              }
              disabled={this.state.isSubmitting}
              onClick={this.handleCreateClientSuccess}
              variant="contained"
            >
              {'Креирај'}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  
}

export default withSnackbar(AddClientModal);
