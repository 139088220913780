import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Slide,
  Grid,
  Text,
  Typography,
  Box,
  Divider,
  Button,
  CircularProgress,
} from '@mui/material';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


class PreviewContactsClients extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            open: false,
        }
    }

    returnBank = (e) => {
        let bankLabel = '';
        const banks = [
          { label: 'Стопанска Банка АД Скопје', value: 'stopanska_ad_skopje' },
          { label: 'НЛБ Тутунска Банка АД Скопје', value: 'nlb_tutunska_skopje' },
          { label: 'Комерцијална банка АД Скопје', value: 'komercijalna_skopje' },
          { label: 'Халк Банка АД Скопје', value: 'halk_skopje' },
          { label: 'Шпаркасе Банка Македонија АД Скопје', value: 'sparkase' },
          { label: 'Силк Роуд Банка АД Скопје', value: 'silk_road' },
          { label: 'ТТК Банка АД Скопје', value: 'ttk_skopje' },
          { label: 'Капитал Банка АД Скопје', value: 'kapital_skopje' },
          { label: 'Прокредит Банка АД Скопје', value: 'prokredit' },
          { label: 'Стопанска Банка АД Битола', value: 'stopanska_ad_bitola' },
          { label: 'Народната Банка на Република Северна Македонија', value: 'narodna' },
          { label: 'Универзална Инвестициона Банка АД Скопје', value: 'univerzalna_investiciona' },
          { label: 'Македонска Банка за подршка на развојот АД Скопје', value: 'makedonska_banka_za_poddrska_na_razvojot' },
        ];
        banks.forEach((bank) => {
          if(bank.value === e) {
            bankLabel = bank.label;
          }
        })
        return bankLabel;
    }


    render() {

        return(<>
          {this.props.client &&
        <Dialog
          open={this.props.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.props.onClose}
        >
          <DialogTitle variant='h4'>{"Преглед на клиент"}</DialogTitle>
          <Divider />
          <DialogContent >
            <Typography variant="subtitle2" >
              <Grid container  spacing={1} pt={2}>
                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Назив'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.client.Naziv}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}  md={4}  textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'ЕМБГ/ЕМБС'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.client.embg_embs}</b>
                  </Typography>
                </Grid>

                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Адреса'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.client.Adresa}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Телефон'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.client.tel_broj}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Сметка'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.client.broj_smetka}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Банка'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.returnBank(this.props.client.banka)}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Е-маил'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.client.email}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
            <DialogActions>
              <Button onClick={this.props.onClose}>Затвори</Button>
            </DialogActions>
          </DialogContent>

        </Dialog>}
          {this.props.contact &&
        <Dialog
          open={this.props.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.props.onClose}
        >
          <DialogTitle variant='h4'>{"Преглед на спротивна странка"}</DialogTitle>
          <Divider />
          <DialogContent >
            <Typography variant="subtitle2" >
              <Grid container  spacing={1} pt={2}>
                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Назив'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.contact.naziv_sprotivna_stranka}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}  md={4}  textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'ЕМБГ/ЕМБС'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.contact.embg_embs}</b>
                  </Typography>
                </Grid>

                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Адреса'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.contact.adresa}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Телефон'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.contact.telefon}</b>
                  </Typography>
                </Grid>
                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Полномошник'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.contact.polnomosnikID}</b>
                  </Typography>
                </Grid>

                <Grid item xs={12}  md={4} textAlign={{ sm: 'right' }}>
                  <Box pr={3} pb={2}>
                    {'Е-маил'}:
                  </Box>
                </Grid>
                <Grid item xs={12}  md={8}>
                  <Typography color="black">
                    <b>{this.props.contact.email}</b>
                  </Typography>
                </Grid>
              </Grid>
            </Typography>
            <DialogActions>
              <Button onClick={this.props.onClose}>Затвори</Button>
            </DialogActions>
          </DialogContent>

        </Dialog>}
          </>
        );

    }
}

export default PreviewContactsClients;



