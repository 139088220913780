import { useState} from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Tooltip,
  IconButton,
  AvatarGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import numeral from "numeral";
const applyFilters = (projects) => {
  return projects.filter((project) => {
    return true;
  });
};

const applyPagination = (projects, page, limit) => {
  return projects.slice(page * limit, page * limit + limit);
};

const Results = ({ projects,reload,type, ...rest }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };


  const filteredProjects = applyFilters(projects);
  const paginatedProjects = applyPagination(filteredProjects, page, limit);



  function stringAvatar(name) {
    if(name.length !== 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}`,
      };
    } else {
      return name;
    }

  }
  function stringToColor(string) {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  return (
    <>
      {projects.length > 0 && (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Назив на странка')}</TableCell>
                      <TableCell>{t('Спротивна странка')}</TableCell>
                      <TableCell>{t('Институција')}</TableCell>
                      <TableCell>{t('Број Предмет')}</TableCell>
                      <TableCell>{t('Основ')}</TableCell>
                      <TableCell>{t('Вредност')}</TableCell>
                      <TableCell>{t('Фаза на предмет')}</TableCell>
                      <TableCell>{t('Статус')}</TableCell>
                      <TableCell>{t('Вработени')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedProjects.map((project,index) => {
                      return (
                        <TableRow
                          hover
                          key={project.ID+'d'+index}
                        >
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.clients.length > 0 && (
                                <AvatarGroup max={4}>
                                  {project.clients.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.Naziv}
                                    >
                                      {project.clients.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.Naziv}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.Naziv)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.contacts.length > 0 && (
                                <AvatarGroup max={4}>
                                  {project.contacts.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.naziv_sprotivna_stranka}
                                    >
                                      {project.contacts.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.naziv_sprotivna_stranka}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.naziv_sprotivna_stranka)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.sud}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.broj_predmet}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.osnov}
                            </Typography>
                          </TableCell>
                          {type !== 'client' &&<TableCell>
                            <Typography noWrap variant="h5">
                              {numeral(project.vrednost).format(`0,0`)}
                            </Typography>
                          </TableCell>}
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.faza_predmet}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.status}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.members.length > 0 && (
                                <AvatarGroup variant={'circular'} max={4}>
                                  {project.members.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.first_name}
                                    >
                                      {project.members.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.first_name}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.first_name)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={t('Преглед')} component={RouterLink}
                                       to={'/predmet/' + project.ID} arrow>
                                <IconButton color="primary">
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>

                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={0}>
                <TablePagination
                  component="div"
                  count={filteredProjects.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
      )}
      {projects.length === 0 && (
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{ my: 5 }}
            gutterBottom
          >
            {t(
              'Нема прикажани предмети'
            )}
          </Typography>
      )}

    </>
  );
};

Results.propTypes = {
  projects: PropTypes.array.isRequired,
};

Results.defaultProps = {
  projects: []
};

export default Results;
