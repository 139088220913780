import React from 'react';
import { styled } from '@mui/material/styles';
import 'react-quill/dist/quill.snow.css';

import {
  Grid,
  DialogTitle,
  Divider,
  DialogContent,
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Alert,
  FormHelperText,
  Button,
} from '@mui/material';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import request from "superagent";

const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(3)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      border-color: ${theme.colors.primary.main};
    }
`
);
class UploadSingleDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isSubmitting: false,
      open: false,
      files: [],
    }
  }

  createDocument = () => {
    let files = this.state.files;
    const data = new FormData();
    data.append('file', files);
    request.post('/api/upload')
      .accept('application/json')
      .send(data).then((callback) => {
      this.setState({message: ""}, this.uploadFileUpdate(callback.body));
    }, (err) => {
      console.log('err', err);

    });
  }

  handleCreateDocument = () => {
    let files = this.state.files;
    if(Object.getOwnPropertyNames(files).length === 0) {
      this.setState({isSubmitting: true});
      setTimeout(() => {
        this.createDocument()
      }, 1000)
    } else {
      this.setState({message: 'Прикачете документ!'})
        
    }
  }
  onChangeFile = (e) => {
    this.setState({files: e.target.files[0], message: ''});
  }

  uploadFileUpdate = (path) => {
    window.makeRequest("PUT", `/api/documentsPredmet?ID=${this.props.ID}`, {
      path: path,
      updated_at: new Date(),
    }, true, (callback) => {
      this.setState({open: false, files: '', isSubmitting: false,}, () => this.props.onClose(true));
    }, (err) => {
      console.log('err', err);
    });
}

  render(){
    return (
      <>
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Прикачување документ'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Изберете документ за да го прикачите'
              }
            </Typography>
          </DialogTitle>
        <DialogContent dividers sx={{ p: 3 }}>
          <Grid container spacing={0}>
            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
              <Box pr={3} sx={{ pb: { xs: 1, md: 0 } }}>
                <b>{'Прикачување'}:</b>
              </Box>

            </Grid>
            <Grid
              sx={{
                mb: 3
              }}
              item
              xs={12}
              sm={8}
              md={9}
            >
         
                  <>
                    <label >
                      <BoxUploadWrapper>
                        <input 
                                onChange={this.onChangeFile} hidden  type="file" />
                        <IconButton color="primary"  component="span">
                          <CloudUploadTwoToneIcon fontSize="large" />
                        </IconButton>
                      </BoxUploadWrapper>
                    </label>
                    <FormHelperText error={true} >{this.state.message}</FormHelperText>
                  </>
              {this.state.files.name && (
                <>
                  <Alert sx={{ py: 0, mt: 2 }} severity="success">
                    <b>{this.state.files.name}</b>
                  </Alert>
                  <Divider sx={{ mt: 2 }} />
                </>
              )}
            </Grid>
  
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              textAlign={{ sm: 'right' }}
            ></Grid>
            <Grid
              sx={{
                mb: 3
              }}
              item
              xs={12}
              sm={8}
              md={9}
            >
              <Button
                sx={{ mr: 2 }}
                onClick={() => this.handleCreateDocument()}
                type="submit"
                variant="contained"
                size="large"
                disabled={this.state.isSubmitting}
                startIcon={
                  this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
                }
              >
                {'Прикачи'}
              </Button>
              <Button
                color="secondary"
                size="large"
                variant="outlined"
                onClick={this.props.onClose}
              >
                {'Затвори'}
              </Button>

              
            </Grid>
            
          </Grid>
        </DialogContent>
      </>
    );
  }

}

export default UploadSingleDocument;
