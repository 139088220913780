import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Tooltip,
  Button,
  Switch,
  CircularProgress,
  IconButton
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import Preview from '@mui/icons-material/RemoveRedEye';

import Text from 'src/components/Text';
import moment from 'moment';



import BillingRender from './Billing/Billing';
import Comments from './Comments/Notifications';
import Documents from './Documents';
import Users from './Users';
import EditForm from './EditForm';
import ListingContactsClients from '../../components/Listing/ListingContactsClients';
import {Link as RouterLink} from "react-router-dom";



function EditPredmetTab(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(false);
  const [trosok, setTrosok] = useState(null);
  const [predmetID, setPredmetID] = useState(null);
  const [trosokOpen, setTrosokOpen] = useState(false);
  const [billing, setBilling] = useState(null);

  useEffect(() => {
    if(props.trosok) {
      setTrosok(props.trosok);
    }
      window.makeRequest("GET", `/api/billing?obvrskaID=${props.id}`, false, true, (callback) => {
        setBilling(callback.body.recordset[0]);
      }, (err) => {
        console.log('err', err);
      });

      if(props.predmet.predmetID) {
        window.makeRequest("GET", `/api/checkPredmet?ID=${props.predmet.predmetID}`, false, true, (callback) => {
          setPredmetID(callback.body.recordset[0].ID);
          setTrosok(callback.body.recordset[0].vrednost);
        }, (err) => {
          console.log('err', err);
        });
      }



  }, [props.predmet]);

  const handleEditPredmetOpen = () => {
    setOpen(true);
  };
  const handleOpenTrosok = () => {
    if(trosokOpen === true) {
      setTrosokOpen(false);
    } else {
      setTrosokOpen(true);
    }
  };


  
  const handleEditPredmetClose = () => {
    setOpen(false);
  };



  const handleCreateBilling = (type) => {
    if(type==='created'){
      enqueueSnackbar('Трошокот е успешно креиран', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        // TransitionComponent: Slide
      });
      props.updatedState()
    } else {
      enqueueSnackbar('Трошокот е успешно едитиран', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        // TransitionComponent: Slide
      });
      props.updatedState()
    }



  };

  const handleEditPredmetSuccess = () => {
    enqueueSnackbar('Обврската е успешно едитирана', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      // TransitionComponent: Slide
    });
    setOpen(false);
    props.updatedState()


  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  {t('Информации за обврска')}
                </Typography>
                <Typography variant="subtitle2">
                  {t('Преглед и ажурирање податоци на обврската')}
                </Typography>
              </Box>
              <Grid >
                {(props.predmet.hasOwnProperty('predmetID') && parseInt(props.predmet.predmetID)) ?
                <Tooltip arrow placement="top" title={'Преглед на предмет'}>
                  <IconButton
                    component={RouterLink}
                    to={`/predmet/${props.predmet.predmetID}`}
                    color="primary"
                  >
                    <Preview fontSize="small" />
                  </IconButton>
                </Tooltip> : false}

                {props.predmet.user_write === true &&
                <Button variant="text" onClick={handleEditPredmetOpen} startIcon={<EditTwoToneIcon />}>
                  {t('Измени')}
                </Button>}
              </Grid>

            </Box>
            <Divider />
            <Grid container spacing={0} display={'flex'} >
              <Grid item xs={12} md={6}>
                <Box pr={0.2} pb={2}>
                  <ListingContactsClients type={props.type} clients={props.clients} />
                </Box>
              </Grid>

              <Grid item xs={12}  md={6} >
                <Box pl={0.2} pb={2}>
                  <ListingContactsClients type={props.type} contacts={props.contacts} sprotivna={'Спротивни странки'} />
                </Box>
              </Grid>
            </Grid>
            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Grid item xs={12} md={3} textAlign={{ sm: 'right' }} >
                    <Box pr={3} pb={2}>
                      {t('Системски број')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={9}   >
                    <Text color="black">
                      <b>{props.predmet.system_number_obvrska}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} md={3} textAlign={{ sm: 'right' }} >
                    <Box pr={3} pb={2}>
                      {t('Опис')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={9} mt={-1.9}  >
                    <Text color="black"   >
                      <b dangerouslySetInnerHTML={{ __html: props.predmet.opis }}   >
                      </b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Статус')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={3}>
                    <Text color="black">
                      <b>{props.predmet.status_obvrska}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Датум на креирање')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={3}>
                    <Text color="black">
                      <b>{moment(props.predmet.created_at).format("DD.MM.YYYY")}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Датум на почеток')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={3}>
                    <Text color="black">
                      <b>{moment(props.predmet.start_date).format("DD.MM.YYYY  HH:mm")}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Краен рок')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={3}>
                    <Text color="black">
                      <b>{moment(props.predmet.kraen_rok).format("DD.MM.YYYY  HH:mm")}</b>
                    </Text>
                  </Grid>

                </Grid>
                <Divider />
                <Grid container spacing={0} mt={2}  >

                  <Grid item display={'flex'} xs={12} >

                    <Grid item ml={1} textAlign={{ sm: 'right' }}>
                      <Box pr={3}  pt={0.5}>
                        {t('Трошок')}:
                      </Box>
                    </Grid>
                    <Grid item >
                      <Box pr={3} >
                        <Switch
                          edge="end"
                          checked={trosokOpen}
                          onClick={handleOpenTrosok}
                          // defaultChecked
                          color="primary"
                        />
                      </Box>
                    </Grid>

                  </Grid>
                  <BillingRender handleCreateBilling={handleCreateBilling} open={trosokOpen} billing={billing} obvrskaID={props.predmet.ID ? props.predmet.ID : null }  trosok={trosok} predmetID={predmetID}/>
                </Grid>

              </Typography>
              
            </CardContent>

          </Card>

        </Grid>
      </Grid>
      
      {props.predmet.user_write === true &&<><Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleEditPredmetClose}
    >
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {t('Едитирај обврска')}
        </Typography>
        <Typography variant="subtitle2">
          {t(
            'Изменете ги полината за да ја едитирате обврската'
          )}
        </Typography>
      </DialogTitle>
          <EditForm
            obvrska={props.predmet}
            handleEditPredmetClose={handleEditPredmetSuccess}
            handleClose={handleEditPredmetClose}
          />
      </Dialog></>}

      <Grid
        sx={{ pt: 1 }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        <Grid item xs={12}>
        <Comments logs={props.logs} renderLogs={props.renderLogs} userID={props.userID} id={props.id} predmet={props.predmet} />
        </Grid>
         <Grid item md={6} lg={7} xs={12}>
         <Documents renderLogs={props.renderLogs} userID={props.userID} id={props.id} predmet={props.predmet} />
        </Grid>
        <Grid item md={6} lg={5} xs={12}>
          <Users  type={props.type} renderLogs={props.renderLogs} userID={props.userID} id={props.id} predmet={props.predmet} />
        </Grid>


      </Grid>
    </>
  );
}

export default EditPredmetTab;
