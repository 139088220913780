import React from  'react';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    Grid,
    Dialog,
    DialogTitle,
    Tooltip,
    Chip,
    DialogContent,
    DialogActions,
    Box,
    ButtonGroup,
    Typography,
    TextField,
    CircularProgress,
    Avatar,
    Autocomplete,
    Button,

  } from '@mui/material';
  import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
  import AddClientModal from './../components/AddClientModal';
  import AddContactModal from './../components/AddContactModal';
  import moment from 'moment';
  import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
  import DateTimePicker from '../components/controls/DateTimePicker/DateTimePicker'


const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);


const StatusObvrska = [
  { title: 'Отворена' },
  { title: 'Нереализирана' },
  { title: 'Реализирана' },
];

class AddObvrskaModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            isSubmitting: false,
            open: false,  
            start_date: moment(),
            kraen_rok: moment(),
            opis: '',
            status_obvrska: '',
            naznaceniPredmet: [], 
            clientsPredmet: [], 
            contactsPredmet: [],
            predmetID: '',
            contacts: [],
            clients: [],
            users: [],
            advokat_id: '',
            broj_predmet: '',
            faza_predmet: '',
            openAddContactsModal: false,
            openAddClientModal: false,
        }


    }
    componentDidMount() {
        window.makeRequest("GET", `/api/previewUsers?role=user`, null, true, (callback) => {
          let users = [];
          if(callback.body && callback.body.length > 0) {
           users = callback.body;
          }
            this.setState({users: users});
          }, (err) => {
            console.log('err', err);
          });
          this.renderClients();
          this.renderContacts();
         
    }

    renderClients = () => {
      window.makeRequest("GET", `/api/getClients`, null, true, (callback) => {
        this.setState({clients: callback.body.sort((a, b) => a.ID - b.ID).reverse()});
      }, (err) => {
        console.log('err', err);
      });
    }

    renderContacts = () => {
        window.makeRequest("GET", `/api/Contacts`, null, true, (callback) => {
          this.setState({contacts: callback.body.recordset.sort((a, b) =>  a.ID - b.ID).reverse()});
        }, (err) => {
          console.log('err', err);
        });
    }


    handleCreateProjectOpen = () => {
      this.setState({open: true})
  }
    handleCreateProjectClose = () => {
        this.setState({
        open: false,  
        start_date: moment(),
        kraen_rok: moment(),
        opis: '',
        status_obvrska: '',
        system_number: '',
        naznaceniPredmet: [], 
        clientsPredmet: [], 
        contactsPredmet: [],
        contacts: [],
        clients: [],
        users: [],
        advokat_id: '',
        predmetID: '',
        broj_predmet: '',
        faza_predmet: '',
        openAddContactsModal: false,
        openAddClientModal: false,
      })
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value})
    }
    newObvrska = () => {
      if(this.state.predmetID !== '' && this.state.system_numberPredmet !== '') {
        window.makeRequest("POST", `/api/Obvrski`, {
          opis: this.state.opis,
          start_date: this.state.start_date,
          kraen_rok: this.state.kraen_rok,
          status_obvrska: this.state.status_obvrska,
          predmetID: this.state.predmetID,
          broj_predmet: this.state.system_numberPredmet,
          created_at: new Date(),
        }, true, (callback) => {
          if(callback.body.recordset[0].id) {
            let id = callback.body.recordset[0].id;
            this.systemNumber(id);
            this.naznaceniPredmet(id);
            this.clientsPredmet(id);
            this.contactsPredmet(id);
            this.props.reload();
            this.setState({
              open: false,
              isSubmitting: false,
              start_date: moment(),
              kraen_rok: moment(),
              opis: '',
              status_obvrska: '',
              naznaceniPredmet: [],
              system_number: '',
              clientsPredmet: [],
              contactsPredmet: [],
              advokat_id: '',
              found: '',
              predmetID: '',
              broj_predmet: '',
              faza_predmet: '',
            },() => this.props.success())
          }
        }, (err) => {
          console.log('err', err);
        });
      } else {
        window.makeRequest("POST", `/api/Obvrski`, {
          opis: this.state.opis,
          start_date: this.state.start_date,
          kraen_rok: this.state.kraen_rok,
          status_obvrska: this.state.status_obvrska,
          created_at: new Date(),
        }, true, (callback) => {
          if(callback.body.recordset[0].id) {
            let id = callback.body.recordset[0].id;
            this.systemNumber(id);
            this.naznaceniPredmet(id);
            this.clientsPredmet(id);
            this.contactsPredmet(id);
            this.props.reload();
            this.setState({
              open: false,
              isSubmitting: false,
              start_date: moment(),
              kraen_rok: moment(),
              opis: '',
              status_obvrska: '',
              naznaceniPredmet: [],
              system_number: '',
              clientsPredmet: [],
              contactsPredmet: [],
              advokat_id: '',
              predmetID: '',
              found: '',
              broj_predmet: '',
              faza_predmet: '',
            },this.props.success())
          }
        }, (err) => {
          console.log('err', err);
        });
      }
    }
    createPredmet = () => {
      this.setState({isSubmitting: true});
      setTimeout(() => {
        this.newObvrska();
      }, 1000)

    }
    systemNumber = (id) => {
      window.makeRequest("PUT", `/api/Obvrski?ID=${id}`, {
        system_number_obvrska: id+1000,
        }, true, (callback) => {
            // this.setState({open: false, isSubmitting: false})
        }, (err) => {
          console.log('err', err);
      });
    }

    naznaceniPredmet = (id) => {
      this.state.naznaceniPredmet.forEach((user) => {
        window.makeRequest("POST", `/api/ObvrskaUsers`, {
          userID: user.ID,
          obvrskaID: id,
          user_read: true,
          user_write: true,
          created_at: new Date(),
          }, true, (callback) => {
              // this.setState({open: false, isSubmitting: false})
          }, (err) => {
            console.log('err', err);
        });
      })
     
    }
    clientsPredmet = (id) => {
      this.state.clientsPredmet.forEach((client) => {
        window.makeRequest("POST", `/api/pivotObvrskiClients`, {
          obvrskaID: id,
          clientID: client.ID,
          created_at: new Date(),
          }, true, (callback) => {
              // this.setState({open: false, isSubmitting: false})
          }, (err) => {
            console.log('err', err);
        });
      })
     
    }
    contactsPredmet = (id) => {
      this.state.contactsPredmet.forEach((contact) => {
        window.makeRequest("POST", `/api/pivotObvrskiContacts`, {
          obvrskaID: id,
          contactID: contact.ID,
          created_at: new Date(),
          }, true, (callback) => {
          }, (err) => {
            console.log('err', err);
        });
      })
     
    }
    onChangeStatusObvrska = (e, val) => {val?this.setState({status_obvrska: val.title}):this.setState({status_obvrska: ""})}

    onChangeNaznaceniPredmet = (e, v) => {
        if(v.length> 0) {
          let removed = this.state.users.filter(item1 => v.some(item2 => item2.ID !== item1.ID));
          this.setState({naznaceniPredmet: v, users: removed})
        }else {
          let users = this.state.naznaceniPredmet;
          this.state.users.push(users[0]);
          let sortedUsers = this.state.users.sort((a, b) => a.ID - b.ID);
          this.setState({naznaceniPredmet: "", users: sortedUsers })
        }
    }
    onChangeClients = (e,val) => {val?this.setState({clientsPredmet: val}):this.setState({clientsPredmet: []})}
    onChangeContacts = (e,val) => {val?this.setState({contactsPredmet: val}):this.setState({contactsPredmet: []})}
    CloseContactModal = (v) => {this.setState({openAddContactModal: false}, () => this.renderContacts())}
    CloseClientModal = () => {this.setState({openAddClientModal: false},() => this.renderClients())}
    onChangeOpis = (e, val) => {this.setState({opis: e})};
    handleChangeStartDate = (e) => {this.setState({start_date: e, kraen_rok: e})};
    handleChangeEndDate = (e) => { this.setState({kraen_rok: e})};
    handleSystemNumber = (e) => {
      this.setState({system_number: e.target.value, found: 'Не е пронајден', predmetID: '', system_numberPredmet: ''});
      if(parseInt(e.target.value)) {
        window.makeRequest("GET", `/api/Predmet?system_number=${e.target.value}`, false, true, (callback) => {
          if(callback.body && callback.body.recordset.length > 0) {
            this.setState({found: 'Пронајден е предмет', predmetID: callback.body.recordset[0].ID, system_numberPredmet: callback.body.recordset[0].system_number,})
          }
        }, (err) => {
          console.log('err', err);
        });
      }

      window.makeRequest("GET", `/api/Predmet?broj_predmet=${e.target.value}`, false, true, (callback) => {
        if(callback.body && callback.body.recordset.length > 0) {
          this.setState({found: 'Пронајден е предмет', predmetID: callback.body.recordset[0].ID, system_numberPredmet: callback.body.recordset[0].system_number,})
        }
      }, (err) => {
        console.log('err', err);
      });


    }
    render() {
        const { classes, theme } = this.props;
        return(
            <> <Grid item>
                  <Typography variant="h3" component="h3" gutterBottom>
                    {'Обврски'}
                  </Typography>
                  <Typography variant="subtitle2">
                    {'Преглед на вашите активни обврски'}
                  </Typography>
                </Grid>
                <Grid item>
                  <Button
                    sx={{ mt: { xs: 2, sm: 0 } }}
                    onClick={this.handleCreateProjectOpen}
                    variant="contained"
                    startIcon={<AddTwoToneIcon fontSize="small" />}
                  >
                    {'Креирај нова обврска'}
                  </Button>
                </Grid>
              <Dialog
                open={this.state.open}
                onClose={this.handleCreateProjectClose}
                fullWidth
                maxWidth="md">
                <DialogTitle sx={{p: 3}}>
                  <Typography variant="h4" gutterBottom>
                    {'Нова обврска'}
                  </Typography>
                  <Typography variant="subtitle2">
                    {'Потполнете ги празните полина за да креирате и да додадете нова обврска'}
                  </Typography>
                </DialogTitle>

                <DialogContent dividers sx={{pb: 3, pl: 3, pr: 3}}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={3}>
                        <Grid mt={-2} item xs={12}>
                          <Grid>
                            <p>{'Опис на обврска'}:</p>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                          >
                            <EditorWrapper>
                              <ReactQuill onChange={this.onChangeOpis} value={this.state.opis ? this.state.opis : ''}/>
                            </EditorWrapper>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            name="system_number"
                            helperText={this.state.found}
                            placeholder={'Внесете број на предмет или системски број...'}
                            label={'Внесете број на предмет или системски број'}
                            onChange={this.handleSystemNumber}
                            value={this.state.system_number}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DateTimePicker
                            autoOk
                            label={'Почеток'}
                            name='start_date'
                            inputFormat="dd-MM-yyyy  HH:mm"
                            value={this.state.start_date}
                            onChange={this.handleChangeStartDate}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <DateTimePicker
                            autoOk
                            label={'Краен рок'}
                            inputFormat="dd-MM-yyyy  HH:mm"
                            name='kraen_rok'
                            value={this.state.kraen_rok}
                            onChange={this.handleChangeEndDate}
                            renderInput={(params) => <TextField fullWidth {...params} />}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Autocomplete
                            sx={{m: 0}}
                            limitTags={2}
                            onChange={this.onChangeStatusObvrska}
                            options={StatusObvrska}
                            renderOption={(props, option) => (
                              <li {...props}>
                                {option.title}
                              </li>
                            )}
                            getOptionLabel={(option) => option.title}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                placeholder={'Статус на обврска...'}
                              />
                            )}
                          />
                        </Grid>

                        {this.props.type === 'admin' && <Grid
                          item
                          xs={12}
                        >
                          <Autocomplete
                            multiple
                            sx={{m: 0}}
                            limitTags={2}
                            options={this.state.users}
                            onChange={this.onChangeNaznaceniPredmet}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Avatar sx={{mr: 1}}/>
                                {option.first_name}
                              </li>
                            )}
                            getOptionLabel={(option) => option.first_name}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                placeholder={'Селектирај назначени за обврската...'}
                              />
                            )}
                            renderTags={(users, getTagProps) =>
                              users.map((ev, index) => (
                                <Chip
                                  key={ev.ID}
                                  label={ev.first_name}
                                  {...getTagProps({index})}
                                  avatar={<Avatar/>}
                                />
                              ))
                            }
                          />
                        </Grid>}
                        <Grid
                          item
                          xs={12}
                          md={6}
                        >
                          <Autocomplete
                            multiple
                            sx={{m: 0}}
                            limitTags={2}
                            onChange={this.onChangeClients}
                            options={this.state.clients}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Avatar sx={{mr: 1}}/>
                                {option.Naziv}
                              </li>
                            )}
                            getOptionLabel={(option) => option.Naziv}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                placeholder={'Селектирај Клиенти за обврската...'}
                              />
                            )}
                            renderTags={(clients, getTagProps) =>
                              clients.map((ev, index) => (
                                <Chip
                                  label={ev.Naziv}
                                  {...getTagProps({index})}
                                  avatar={<Avatar/>}
                                />
                              ))
                            }
                          />
                        </Grid>
                        <Grid
                          sx={{
                            mb: `${theme.spacing(3)}`
                          }}
                          item
                          xs={12}
                          md={6}
                        >
                          <Autocomplete
                            multiple
                            sx={{m: 0}}
                            limitTags={2}
                            onChange={this.onChangeContacts}
                            noOptionsText={'Странката не е пронајдена кликнете на копчето странка за да додадете'}
                            options={this.state.contacts}
                            renderOption={(props, option) => (
                              <li {...props}>
                                <Avatar sx={{mr: 1}}/>
                                {option.naziv_sprotivna_stranka}
                              </li>
                            )}
                            getOptionLabel={(option) => option.naziv_sprotivna_stranka}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                placeholder={'Селектирај Спротивни странки за обврската...'}
                              />
                            )}
                            renderTags={(users, getTagProps) =>
                              users.map((ev, index) => (
                                <Chip
                                  label={ev.naziv_sprotivna_stranka}
                                  {...getTagProps({index})}
                                  avatar={<Avatar/>}
                                />
                              ))
                            }
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <ButtonGroup disableElevation>
                          <Tooltip title={'Нов Клиент'} arrow>
                            <Button
                              onClick={() => this.setState({openAddClientModal: true})}
                              variant="outlined" size="small">
                              {'+ Клиент'}
                            </Button>
                          </Tooltip>
                          <Tooltip title={'Нова Странка'} arrow>
                            <Button
                              onClick={() => this.setState({openAddContactModal: true})}
                              variant="outlined" size="small">
                              {'+ Странка'}
                            </Button>
                          </Tooltip>
                        </ButtonGroup>

                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{p: 3}}>
                  <Button color="secondary" onClick={this.handleCreateProjectClose}>
                    {'Затвори'}
                  </Button>
                  <Button
                    type="submit"
                    onClick={this.createPredmet}
                    disabled={this.state.isSubmitting}
                    startIcon={
                      this.state.isSubmitting ? <CircularProgress size="1rem"/> : null
                    }
                    variant="contained"
                  >
                    {'Креирај'}
                  </Button>
                </DialogActions>
              </Dialog>
                <AddClientModal onClose={this.CloseClientModal}  open={this.state.openAddClientModal} />    
                <AddContactModal onClose={this.CloseContactModal} createdContact={this.createdContact} open={this.state.openAddContactModal} />
            </>
        );
    }
}

export default AddObvrskaModal;
