import React from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
} from '@mui/material';
import {
  withRouter,
} from "react-router-dom";
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';

import Text from 'src/components/Text';
import Label from 'src/components/Label';
import EditForm from './EditForm';
import SuspenseLoader from '../../../../../components/SuspenseLoader'


class EditProfileTab extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      open: false,
      user: [],
    }
  }

  componentDidMount() {
    this.renderUser();
  }

  renderUser = () => {
    let id = this.props.match.params.id;
    id && window.makeRequest("GET", `/api/Users?ID=${id}`, null, true, (callback) => {
      this.setState({user: callback.body.recordset[0], loading: false})
    }, (err) => {
      console.log('err', err);
    
    });
  }




  handleCreateUserOpen = () => {
    this.setState({open:true});
  };

  handleCreateUserClose = () => {
    this.setState({open:false});

  };

  returnUserRole = (role) => {
    let returnValue = '';
    const roles = [
      { label: 'Админ', value: 'admin' },
      { label: 'Корисник', value: 'user' },
      { label: 'Клиент', value: 'client' },
      { label: 'Сметководство', value: 'smetkovodstvo' },

    ];
    roles.map((list) => {
      if(list.value === role) {
        returnValue = list.label;
      }
    });
    return returnValue;

  }

  returnUserUloga = (role) => {
    let returnValue = '';
    const roles = [
      { label: 'Адвокат', value: 'advokat' },
      { label: 'Приправник', value: 'pripravnik' },
      { label: 'Стучен соработник', value: 'strucensorabotnik' },
      { label: 'Клиент', value: 'client' },
      { label: 'Сметководство', value: 'smetkovodstvo' },


    ];
    roles.map((list) => {
      if(list.value === role) {
        returnValue = list.label;
      }
    });
    return returnValue;

  }


  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return (
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <Box
                  p={3}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box>
                    <Typography variant="h4" gutterBottom>
                      {'Персонални информации'}
                    </Typography>
                    <Typography variant="subtitle2">
                      {'Преглед и ажурирање на податоците на корисникот'}
                    </Typography>
                  </Box>
                  <Button variant="text" onClick={this.handleCreateUserOpen} startIcon={<EditTwoToneIcon />}>
                    {'Измени'}
                  </Button>
                </Box>
                <Divider />
                <CardContent sx={{ p: 4 }}>
                  <Typography variant="subtitle2">
                    <Grid container spacing={0}>
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Име'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.props.user.first_name}</b>
                        </Text>
                      </Grid>

                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Е-маил'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.props.user.email}</b>
                        </Text>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Корисничко име'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.props.user.username}</b>
                        </Text>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Улога'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.returnUserUloga(this.props.user.uloga)}</b>
                        </Text>
                      </Grid>
                      {this.props.user.embs &&
                      <><Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'ЕМБС/ЕМБГ'}:
                        </Box>
                      </Grid>
                        <Grid item xs={12} sm={8} md={9}>
                          <Text color="black">
                            <b>{this.props.user.embs}</b>
                          </Text>
                        </Grid></> }
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Role'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Text color="black">
                          <b>{this.returnUserRole(this.props.user.role)}</b>
                        </Text>
                      </Grid>
                      <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                        <Box pr={3} pb={2}>
                          {'Статус'}:
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={8} md={9}>
                        <Label color="success">
                          <DoneTwoToneIcon fontSize="small" />
                          <b>{'Активен'}</b>
                        </Label>
                      </Grid>
                    </Grid>
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Dialog
            fullWidth
            maxWidth="sm"
            open={this.state.open}
            onClose={this.handleCreateUserClose}
          >
            <DialogTitle sx={{ p: 3 }}>
              <Typography variant="h4" gutterBottom>
                {'Едитирај корисник'}
              </Typography>
              <Typography variant="subtitle2">
                {'Изменете ги полината за да го едитирате корисникот'}
              </Typography>
            </DialogTitle>
            <EditForm
              update={this.props.updatedState}
              user={this.props.user}
              handleCreateUserClose={this.handleCreateUserClose}
            />
          </Dialog>
        </>
      );
    }

  }

}

export default withRouter(EditProfileTab);
