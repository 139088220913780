import React from 'react';

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import SuspenseLoader from '../../../components/SuspenseLoader'
import { Grid } from '@mui/material';
import Results from './Results';
import TableSortable from './TableSortable';

class ManagementProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }
  componentDidMount () {
    this.renderClients();
    this.getObvrski();
  }

  renderClients = () => {
    window.makeRequest("GET", `/api/getAllClients`, null, true, (callback) => {
      this.setState({clients: callback.body.sort((a, b) => a.ID - b.ID).reverse()});
    }, (err) => {
      console.log('err', err);
    });
  }

  getObvrski = () => {
    this.setState({loading: true})
    window.makeRequest("GET", `/api/all_obvrski_preview`, null, true, (callback) => {
      if(callback.body !== 'no results') {
        let obvrski = null;
        if(callback.body.length > 0) {
          obvrski = callback.body.sort((a, b) => a - b).reverse();
        }
        this.setState({
          projects: obvrski,
          loading: false,
        });
      } else {
        this.setState({
          projects: [],
          loading: false,
        });
      }
    }, (err) => {
      console.log('err', err);
    });

  }
 
 
  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return (
        <>
          <Helmet>
            <title>Обврски</title>
          </Helmet>
          <PageTitleWrapper>
            <PageHeader type={this.props.type} reload={this.getObvrski} />
          </PageTitleWrapper>

          <Grid
            sx={{ px: 4 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              {/*<Results reload={this.getObvrski}  clients={this.state.clients} projects={this.state.projects} />*/}
              <TableSortable reload={this.getObvrski}  clients={this.state.clients} projects={this.state.projects} />
            </Grid>
          </Grid>
          <Footer />
        </>
      );
    }

  }
}

export default ManagementProjects;
