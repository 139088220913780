import { useState, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



const applyFilters = (clients, query, filters) => {
  return clients.filter((client) => {
    let matches = true;

    if (query) {
      const properties = ['Naziv', 'embg_embs', 'banka','broj_smetka','status','tel_broj', 'Adresa'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (client[property] && client[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && client[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (clients, page, limit) => {
  return clients.slice(page * limit, page * limit + limit);
};

const Results = ({ clients,predmet,  ...props } ) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({});
 

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredClients = applyFilters(clients, query, filters);
  const paginatedClients = applyPagination(filteredClients, page, limit);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [clientDeleteID, setOpenClientDeleteID] = useState("");

  const handleConfirmDelete = (id) => {
    setOpenConfirmDelete(true);
    setOpenClientDeleteID(id);


  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);
    props.delete(clientDeleteID);
    enqueueSnackbar(t('Клиентот е успешно избришан'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  };

  const returnBanka = (e) => {
    let bankLabel = '';
    const banks = [
      { label: 'Стопанска Банка АД Скопје', value: 'stopanska_ad_skopje' },
      { label: 'НЛБ Тутунска Банка АД Скопје', value: 'nlb_tutunska_skopje' },
      { label: 'Комерцијална банка АД Скопје', value: 'komercijalna_skopje' },
      { label: 'Халк Банка АД Скопје', value: 'halk_skopje' },
      { label: 'Шпаркасе Банка Македонија АД Скопје', value: 'sparkase' },
      { label: 'Силк Роуд Банка АД Скопје', value: 'silk_road' },
      { label: 'ТТК Банка АД Скопје', value: 'ttk_skopje' },
      { label: 'Капитал Банка АД Скопје', value: 'kapital_skopje' },
      { label: 'Прокредит Банка АД Скопје', value: 'prokredit' },
      { label: 'Стопанска Банка АД Битола', value: 'stopanska_ad_bitola' },
      { label: 'Народната Банка на Република Северна Македонија', value: 'narodna' },
      { label: 'Универзална Инвестициона Банка АД Скопје', value: 'univerzalna_investiciona' },
      { label: 'Македонска Банка за подршка на развојот АД Скопје', value: 'makedonska_banka_za_poddrska_na_razvojot' },
    ];
    banks.forEach((bank) => {
      if(bank.value === e) {
        bankLabel = bank.label;
      }
    })
    return bankLabel;
  }


  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
      </Box>
      {clients.length > 0 && (
        <Card>
          <Box p={2}>
              <TextField
                sx={{ m: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Пребарувај по Назив, ЕМБГ/ЕМБС ,Банка или Адреса...')}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
          </Box>

          <Divider />

          {paginatedClients.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("Не се пронајдени резултати од вашето пребарување")}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Назив')}</TableCell>
                      <TableCell>{t('ЕМБГ/ЕМБС')}</TableCell>
                      <TableCell>{t('Адреса')}</TableCell>
                      <TableCell>{t('Телефон')}</TableCell>
                      <TableCell>{t('Број сметка')}</TableCell>
                      <TableCell>{t('Банка')}</TableCell>
                      <TableCell>{t('Е-маил')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedClients.map((client) => {
                      return (
                        <TableRow hover key={client.ID} >
                          <TableCell>
                            <Typography variant="h5">
                              {client.Naziv}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{client.embg_embs}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{client.Adresa}</Typography>
                          </TableCell> 
                          <TableCell>
                            <Typography>{client.tel_broj}</Typography>
                          </TableCell> 
                          <TableCell>
                            <Typography>{client.broj_smetka}</Typography>
                          </TableCell> 
                          <TableCell>
                            <Typography>{returnBanka(client.banka)}</Typography>
                          </TableCell> 
                          <TableCell>
                            <Typography>{client.email}</Typography>
                          </TableCell> 
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={t('Преглед')} arrow>
                                <IconButton
                                  component={RouterLink}
                                  to={'/clients/' + client.ID}
                                  color="primary"
                                >
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {predmet.user_write === true && <Tooltip title={t('Избриши')} arrow>
                                <IconButton
                                  onClick={() => handleConfirmDelete(client.ID)}
                                  color="primary"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredClients.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
    
      {clients.length === 0 && (
        <Card sx={{ textAlign: 'center', p: 3 }}>
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{ my: 5 }}
            gutterBottom
          >
            {t(
              'Нема прикажани клиенти'
            )}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {t('Дали сте сигурни да го избришете клиентот?')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {t('Затвори')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {t('Избриши')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  clients: PropTypes.array.isRequired,
  predmet: PropTypes.any,
  delete: PropTypes.func.isRequired,
};

Results.defaultProps = {
  clients: [],
  delete: [],

};

export default Results;
