import { useState, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  AvatarGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import numeral from "numeral";

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);
const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};
     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const applyFilters = (projects, query) => {
  return projects.filter((project) => {
    let matches = true;

    if (query) {
      const properties = ['broj_predmet','status_klient', 'sud', 'faza_predmet', 'tip_predmet', 'status' , 'osnov' , 'system_number' , 'reference_broj' , 'vrednost', 'interen_broj'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (project[property] && project[property].toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      project.members && project.members.forEach((member) => {
        if (member.first_name && member.first_name.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      project.clients && project.clients.forEach((member) => {
        if (member.Naziv && member.Naziv.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      project.contacts && project.contacts.forEach((member) => {
        if (member.naziv_sprotivna_stranka && member.naziv_sprotivna_stranka.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })



      if (!containsQuery) {
        matches = false;
      }

    }

    return matches;
  });
};

const applyPagination = (projects, page, limit) => {
  return projects.slice(page * limit, page * limit + limit);
};

const Results = ({ projects,reload,type, ...rest }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState('');

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredProjects = applyFilters(projects, query);
  const paginatedProjects = applyPagination(filteredProjects, page, limit);
  function stringAvatar(name) {
    if(name.length !== 1) {
      return {
        sx: {
          bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}`,
      };
    } else {
      return name;
    }

  }
  function stringToColor(string) {
    let hash = 0;
    let i;
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }

    return color;
  }

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteID, setDeleteID] = useState(null);

  const handleConfirmDelete = (id) => {
    setOpenConfirmDelete(true);
    setDeleteID(id);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setDeleteID(null);

  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);
    window.makeRequest("DEL", `/api/Predmet?ID=${deleteID}`, false, true, (callback) => {
    }, (err) => {
      console.log('err', err);
    });

    enqueueSnackbar(t('Предметот е успешно избришан'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
    reload();
    setDeleteID(null);

  };

  return (
    <>
      <Card sx={{ p: 1, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box p={1}>
              <TextField
                sx={{ m: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Пребарувај предмети...')}
                value={query}
                fullWidth
                variant="outlined"
              />
            </Box>
          </Grid>
        </Grid>
      </Card>

      {projects.length > 0 && (
        <Card>
            <Box
              p={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography component="span" variant="subtitle1">
                  {t('Прикажано')}:
                </Typography>{' '}
                <b>{paginatedProjects.length}</b> <b>{t('предмети')}</b>
              </Box>
              <TablePagination
                component="div"
                count={filteredProjects.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          <Divider />

          {paginatedProjects.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t(
                  "Нема пронајдено предмет со внесенето име"
                )}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Назив на странка')}</TableCell>
                      <TableCell>{t('Спротивна странка')}</TableCell>
                      <TableCell>{t('Институција')}</TableCell>
                      <TableCell>{t('Број Предмет')}</TableCell>
                      <TableCell>{t('Основ')}</TableCell>
                      <TableCell>{t('Вредност')}</TableCell>
                      <TableCell>{t('Фаза на предмет')}</TableCell>
                      <TableCell>{t('Статус')}</TableCell>
                      <TableCell>{t('Вработени')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedProjects.map((project) => {
                      return (
                        <TableRow
                          hover
                          key={project.ID}
                        >
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.clients.length > 0 && (
                                <AvatarGroup max={4}>
                                  {project.clients.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.Naziv}
                                    >
                                      {project.clients.length === 1 ?
                                       <Typography
                                        key={member.ID}>{member.Naziv}</Typography>
                                      : <Avatar
                                        {...stringAvatar(member.Naziv)}
                                        sx={{ width: 30, height: 30 }}
                                        key={member.ID}
                                      />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.contacts.length > 0 && (
                                <AvatarGroup max={4}>
                                  {project.contacts.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.naziv_sprotivna_stranka}
                                    >
                                      {project.contacts.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.naziv_sprotivna_stranka}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.naziv_sprotivna_stranka)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.sud}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.broj_predmet}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.osnov}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {numeral(project.vrednost).format(`0,0`)}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.faza_predmet}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.status}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.members.length > 0 && (
                                <AvatarGroup variant={'circular'} max={4}>
                                  {project.members.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.first_name}
                                    >
                                      {project.members.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.first_name}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.first_name)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={t('Преглед')} component={RouterLink}
                                  to={'/predmet/' + project.ID} arrow>
                                <IconButton color="primary">
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {project.user_write === true &&<Tooltip title={t('Delete')} arrow>
                                <IconButton
                                  onClick={() => handleConfirmDelete(project.ID)}
                                  color="primary"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredProjects.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
      {projects.length === 0 && (
        <Card sx={{ textAlign: 'center', p: 3 }}>
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{ my: 5 }}
            gutterBottom
          >
            {t(
              'Во моментов нема прикажани предмети'
            )}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ pt: 4, px: 6 }} variant="h1">
            {t('Дали сакате да го избришете предметот')}?
          </Typography>

          <Typography
            align="center"
            sx={{ pt: 2, pb: 4, px: 6 }}
            fontWeight="normal"
            color="text.secondary"
            variant="h3"
          >
            {t("Нема можност за повторно враќање")}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {t('Затвори')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {t('Избриши')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  projects: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
};

Results.defaultProps = {
  projects: []
};

export default Results;
