import { React,useState, useMemo, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Grid,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  AvatarGroup,
  Table,
  TableBody,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TableCell,
  Autocomplete,

  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import moment from 'moment';

function stringAvatar(name) {

  if(name.length !== 1) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
    };
  } else {
    return name;
  }

}
function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);



const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const applyFilters = (projects, query, filters) => {
  return projects.filter((project) => {
    let matches = true;

    if (query) {
      const properties = ['opis', 'system_number_obvrska', 'broj_predmet','status_obvrska','zavrsena_obvrska','trosok'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (project[property] && project[property].toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });
      project.members && project.members && project.members.forEach((member) => {
        if (member.first_name && member.first_name.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      project.clients && project.clients && project.clients.forEach((member) => {
        if (member.Naziv && member.Naziv.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      project.contacts && project.contacts && project.contacts.forEach((member) => {
        if (member.naziv_sprotivna_stranka && member.naziv_sprotivna_stranka.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })


      if (filters.status_obvrska && project.status_obvrska !== filters.status_obvrska) {
        matches = false;
      }

      if (!containsQuery) {
        matches = false;
      }
    }

    if(filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];

        if(key === 'status_obvrska' && filters.status_obvrska !== null) {
          if (value && project[key] !== value) {
            matches = false;
          }
        }
        if(key === 'client' && filters.client !== null){
          if(!project.clients.find((r) => r.ID === filters.client)){
            matches = false
          }

        }

        // if(value === null && project[key] === 'Реализирана') {
        //   matches = false;
        // }
        //


      });
    }

    return matches;
  });
};

const applyPagination = (projects, page, limit) => {
  return projects.slice(page * limit, page * limit + limit);
};

const Results = ({ projects,reload,clients, ...rest }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({
    status_obvrska: null,
    client: null,
  });

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };



  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredProjects = applyFilters(projects, query, filters);
  const paginatedProjects = applyPagination(filteredProjects, page, limit);

  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteID, setDeleteID] = useState(null);

  const handleConfirmDelete = (id) => {
    setOpenConfirmDelete(true);
    setDeleteID(id);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setDeleteID(null);
  };

  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);
    window.makeRequest("DEL", `/api/Obvrski?ID=${deleteID}`, null, true, (callback) => {
      projects = projects.filter(function(contact) {
        return contact.ID !== deleteID
      });
      reload();


    }, (err) => {
      console.log('err', err);
    });

    enqueueSnackbar(t('Обврската е успешно избришана'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
    setDeleteID(null);
  };

  function createMarkup(e) {
    return {__html: e};
  }

  function MyComponent(e) {
    return <div dangerouslySetInnerHTML={createMarkup(e)} />;
  }

  const handleStatusChange = (e) => {
    let value = null;
    if (e.target.value !== 'all') {
      value = e.target.value;
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      status_obvrska: value,
    }));
  };

  const handleClientChange = (client) => {
    if(client && client.hasOwnProperty('ID')){
      setFilters((prevFilters) => ({
        ...prevFilters,
        client: client.ID,
      }));
    }else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        client: null,
      }));
    }

  };

  const statusOptions = [
    {
      id: 'all',
      name: 'Сите'
    },
    {
      id: 'Отворена',
      name: t('Отворена')
    },
    {
      id: 'Нереализирана',
      name: t('Нереализирана')
    },
    {
      id: 'Реализирана',
      name: t('Реализирана')
    }
  ];

  //sort

  const { items, requestSort, sortConfig } = useSortableData(paginatedProjects);
  const getClassNamesFor = (name) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === name ? sortConfig.direction : undefined;
  };


  return (
    <>
      <Card sx={{ p: 1, mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} display={'flex'}>
            <Grid item p={1} xs={6}>
              <TextField
                sx={{ m: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Пребарувај по име на обврска...')}
                value={query}
                fullWidth
                variant="outlined"
              />
            </Grid>
            <Grid item p={1} xs={3}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(e,v) => handleClientChange(v)}
                options={clients}
                getOptionLabel={option => option.Naziv}
                renderInput={(params) => <TextField {...params} fullWidth label="Клиенти" />}
              />
            </Grid>

            <Grid item p={1} xs={3}>
              <FormControl fullWidth variant="outlined">
                <InputLabel>{t('Прикажи')}</InputLabel>
                <Select
                  value={filters.status_obvrska || 'all'}
                  onChange={handleStatusChange}
                  label={t('Прикажи')}
                >
                  {statusOptions.map((statusOption) => (
                    <MenuItem key={statusOption.id} value={statusOption.id}>
                      {statusOption.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Card>

      {projects.length > 0 && (
        <Card>
            <Box
              px={2}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography component="span" variant="subtitle1">
                  {t('Прикажани')}:
                </Typography>{' '}
                <b>{paginatedProjects.length}</b> <b>{t('обврски')}</b>
              </Box>
              <TablePagination
                component="div"
                count={filteredProjects.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 15]}
              />
            </Box>
          <Divider />

          {paginatedProjects.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t(
                  "Нема пронајдено обврска"
                )}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Клиенти')}</TableCell>
                      <TableCell>{t('Спротивни странки')}</TableCell>
                      <TableCell className={getClassNamesFor('broj_predmet')}  onClick={() => requestSort('broj_predmet')}>{t('Број на предмет')}</TableCell>
                      <TableCell>{t('Вработени')}</TableCell>
                      <TableCell className={getClassNamesFor('opis')}  onClick={() => requestSort('opis')}>{t('Опис')}</TableCell>
                      <TableCell className={getClassNamesFor('start_date')}  onClick={() => requestSort('start_date')}>{t('Почеток')}</TableCell>
                      <TableCell className={getClassNamesFor('kraen_rok')}  onClick={() => requestSort('kraen_rok')}>{t('Краен рок')}</TableCell>
                      <TableCell className={getClassNamesFor('status_obvrska')}  onClick={() => requestSort('status_obvrska')}>{t('Статус')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((project) => {
                      let duration = moment.duration(moment().diff(project.kraen_rok));
                      let days = duration.asDays();
                      let color = "";
                      // datum denesen pomal od kraen rok 5 dena
                      if(project.status_obvrska !== 'Реализирана') {
                        // if(days >= -5 && days < 1){
                          color = '#ffe6e6';
                        // }
                      }
                      return (
                        <TableRow
                          style={{backgroundColor: color,}}
                          hover
                          key={project.ID}
                        >
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.clients.length > 0 && (
                                <AvatarGroup max={4}>
                                  {project.clients.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.Naziv}
                                    >
                                      {project.clients.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.Naziv}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.Naziv)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.contacts.length > 0 && (
                                <AvatarGroup max={4}>
                                  {project.contacts.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.naziv_sprotivna_stranka}
                                    >
                                      {project.contacts.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.naziv_sprotivna_stranka}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.naziv_sprotivna_stranka)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.broj_predmet}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="flex-start">
                              {project.members.length > 0 && (
                                <AvatarGroup variant={'circular'} max={4}>
                                  {project.members.map((member) => (
                                    <Tooltip
                                      arrow
                                      placement="top"
                                      key={member.ID}
                                      title={member.first_name}
                                    >
                                      {project.members.length === 1 ?
                                        <Typography
                                          key={member.ID}>{member.first_name}</Typography>
                                        : <Avatar
                                          {...stringAvatar(member.first_name)}
                                          sx={{ width: 30, height: 30 }}
                                          key={member.ID}
                                        />}
                                    </Tooltip>
                                  ))}
                                </AvatarGroup>
                              )}
                            </Box>
                          </TableCell>
                          <TableCell>
                            <Tooltip  title={MyComponent(project.opis)}>
                              <Typography dangerouslySetInnerHTML={{ __html: project.opis }} noWrap variant="h5">
                              </Typography>
                            </Tooltip>
                          </TableCell>

                          <TableCell>
                            <Typography noWrap variant="h5">
                              {moment(project.start_date).format("DD.MM.YYYY  HH:mm")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {moment(project.kraen_rok).format("DD.MM.YYYY  HH:mm")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography noWrap variant="h5">
                              {project.status_obvrska}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={t('Преглед')} component={RouterLink}
                                  to={'/obvrska/' + project.ID} arrow>
                                <IconButton color="primary">
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              {project.user_write === true && <Tooltip title={t('Избриши')} arrow>
                                <IconButton
                                  onClick={() => handleConfirmDelete(project.ID)}
                                  color="primary"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box px={2}>
                <TablePagination
                  component="div"
                  count={filteredProjects.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
      {projects.length === 0 && (
        <Card sx={{ textAlign: 'center', p: 3 }}>
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{ my: 5 }}
            gutterBottom
          >
            {t(
              'Во моментов нема прикажани обврски'
            )}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ pt: 4, px: 6 }} variant="h1">
            {t('Дали сакате да ја избришете обврската')}?
          </Typography>

          <Typography
            align="center"
            sx={{ pt: 2, pb: 4, px: 6 }}
            fontWeight="normal"
            color="text.secondary"
            variant="h3"
          >
            {t("Нема можност за повторно враќање")}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {t('Затвори')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {t('Избриши')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

Results.propTypes = {
  projects: PropTypes.array.isRequired,
  reload: PropTypes.func.isRequired,
};

Results.defaultProps = {
  projects: []
};

export default Results;

const useSortableData = (items, config = null) => {
  const [sortConfig, setSortConfig] = useState(config);

  const sortedItems = useMemo(() => {
    let sortableItems = [...items];
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableItems;
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
