import React from 'react';
import { styled } from '@mui/material/styles';

import {
  Grid,
  Dialog,
  DialogTitle,
  Zoom,
  Typography,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { withSnackbar } from 'notistack';
import AddUser from './AddUser';


class PageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: false,
        clients: [],
    }

  }

  componentDidMount() {
    window.makeRequest("GET", `/api/previewUsers`, null, true, (callback) => {
      let users = [];
      let filteredusers = callback.body.recordset.filter((single) => {
          users.push(single);
      })

      this.setState({clients: users});
    }, (err) => {
      console.log('err', err);
    });
  }

  handleCreateUserOpen = () => {
    this.setState({open :true});
  };

  handleCreateUserClose = () => {
    this.setState({open :false});
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value,})
  }

  handleInputChangeBank = (e, value, test) => {
    banks.map((bank) => {
      if(bank.label === value) {
        this.setState({banka: bank.value})
      }
    })
  }


  closeDialog = () => {
    this.setState({open: false});
  };
 

  addUsers = () => {
    this.setState({open: false}, this.props.renderUsers);
    this.props.enqueueSnackbar('Клиентот е успешно креиран', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });

  };


  render() {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {'Вработен'}
            </Typography>
          </Grid>
          {this.props.predmet.user_write === true && this.props.type === 'admin' ? <Grid item>
            <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleCreateUserOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {'Вработен'}
            </Button>
          </Grid> : ''}
        </Grid>
        {this.props.predmet.user_write === true &&<><Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleCreateUserClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Нов вработен'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Селектирајте вработен за да го додадете на предметот'
              }
            </Typography>
          </DialogTitle>
          {this.props.type === 'admin' &&<AddUser addUsers={this.addUsers} closeDialog={this.closeDialog} clients2={this.props.clients} clients={this.state.clients} userID={this.props.userID} id={this.props.id} predmet={this.props.predmet} />}
        </Dialog></>}
      </>
    );
  }
  
}

export default withSnackbar(PageHeader);
