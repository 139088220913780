import React from 'react';
import request from "superagent";

import {
  Button,
  TextField,
  Box,
  Typography,
  Card,
  CircularProgress
} from '@mui/material';

class LoginJWT extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
      isSubmitting: false,
      passwordError: '',
      usernameError: ''

    }
  }


  validate = () => {
    this.setState({isSubmitting: true});
    let usernameError = '';
    let passwordError = '';

    let username = this.state.username;
    let password = this.state.password;
    switch (true) {
      case username.length < 1:
        usernameError = 'Корисничкото име не може да биде празно поле';
        break;
      case username.length < 4:
        usernameError = 'Корисничкото име мора да има 4 или повеќе карактери';
        break;
      default:
    }
    switch (true) {
      case password.length < 1:
        passwordError = 'Лозинката не може да биде празно поле';
        break;
      case password.length < 4:
        passwordError = 'Корисничкото име мора да има 4 или повеќе карактери';
        break;
      default:

    }
    if(usernameError || passwordError) {
      this.setState({ usernameError, passwordError, isSubmitting:false,error: true });
      return false;
    }


    return true;
  }

  handleLogin = () => {
    this.setState({isSubmitting: true});

    const isValid = this.validate();
    if(isValid) {
      request.post('/api/auth')
        .set('Content-Type', 'application/json')
        .send({
          username:   this.state.username,
          password:   this.state.password,
        }).then((callback) => {
        let body = callback.body;
        switch(body) {
          case "invalid credentials":
            this.setState({username: '', password: '', passwordError: "Погрешна лозинка или корисничко име",isSubmitting:false})
          break;
          case "Failed Logging":
            this.setState({username: '', password: '', passwordError: "Погрешна лозинка или корисничко име следниот пат ќе бидете блокирани!",isSubmitting:false})
            break;
          case "Blocked User":
            this.setState({username: '', password: '', passwordError: "Корисникот е блокиран!",isSubmitting:false})
            break;
          case "code generated":
            window.login(body[0]);
            // this.props.change(this.state.username);
            // this.setState({username: '', password: '',isSubmitting:false, passwordError: '', usernameError: ''});
            break;
          default:
            try{
              Object.keys(callback.body);
              window.login(callback.body);
            }catch(e){
              window.logout();
            }
        }

      }, (err) => {
        if(err) {
          window.logout();
        }
      });
    }
    this.setState({isSubmitting: false});

  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value, passwordError: '', usernameError: '', isSubmitting: false})
  }


  handleKeyPress = (event) => {
    if(event.key === 'Enter'){
      this.handleLogin();
    }
  }

  render() {
    return (

      <>

        <Card sx={{mt: 3, px: 4, pt: 5, pb: 3}}>
          <Box>
            <Typography variant="h2" sx={{mb: 1}}>
              {'CRM Medarski'}
            </Typography>
            <Typography
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
              sx={{mb: 3}}
            >
              {'Потполнете ги полината за да се најавите'}
            </Typography>
          </Box>
          <TextField
            error={!!this.state.usernameError}
            fullWidth
            margin="normal"
            autoFocus
            helperText={this.state.usernameError}
            label={'Корисничко име'}
            name="username"
            onChange={this.handleChange}
            type="username"
            value={this.state.username}
            variant="outlined"
          />
          <TextField
            error={!!this.state.passwordError}
            fullWidth
            onKeyPress={this.handleKeyPress}
            margin="normal"
            helperText={this.state.passwordError}
            label={'Лозинка'}
            name="password"
            onChange={this.handleChange}
            type="password"
            value={this.state.password}
            variant="outlined"
          />
          <Button
            sx={{mt: 3}}
            color="primary"
            startIcon={this.state.isSubmitting ? <CircularProgress size="1rem"/> : null}
            disabled={this.state.isSubmitting}
            type="submit"
            fullWidth
            size="large"
            onClick={this.handleLogin}
            variant="contained"
          >
            {'Најава'}
          </Button>
        </Card>

      </>
    );
  }

}

export default LoginJWT;
