import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Zoom,
  Typography,
  TextField,
  CircularProgress,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { withSnackbar } from 'notistack';
import AllClientsAdd from './single/AllClientsAdd';


class PageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: false,
        naziv_sprotivna_stranka: "",
        embg_embs: '',
        adresa: '',
        telefon: '',
        broj_smetka: '',
        banka: null,
        email: '',
        createNewClient: false,
        addClient: true,
        isSubmitting: false,
        users: this.props.users,

    }

  }

  componentDidMount() {
    this.setState({users: this.props.users})
  }

  handleCreateUserOpen = () => {
    this.setState({open :true, createNewClient: false, addClient:true});
  };

  handleCreateUserClose = () => {
    this.setState({open :false,
      naziv_sprotivna_stranka: "",
      embg_embs: '',
      adresa: '',
      telefon: '',
      broj_smetka: '',
      isSubmitting: false,
      banka: null,
      email: '',
    });
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value,})
  }

   createContact = () => {
     window.makeRequest("POST", `/api/Contacts`, {
       naziv_sprotivna_stranka: this.state.naziv_sprotivna_stranka,
       embg_embs: this.state.embg_embs,
       polnomosnikID: this.state.polnomosnikID,
       adresa: this.state.adresa,
       telefon: this.state.telefon,
       email: this.state.email,
       created_at: new Date(),
     }, true, (callback) => {
       window.makeRequest("POST", `/api/pivotPredmetContacts`, {
         predmetID:   this.props.predmetID,
         contactID:   callback.body.recordset[0].id,
         created_at: new Date(),
       }, true, (callback) => {
         this.setState({open: false,
           naziv_sprotivna_stranka: "",
           embg_embs: '',
           adresa: '',
           telefon: '',
           broj_smetka: '',
           banka: null,
           email: '',
           isSubmitting: false,
         }, this.props.reloadClients)
       }, (err) => {
         console.log('err', err);
       });
     }, (err) => {
       console.log('err', err);
     });

     this.props.enqueueSnackbar('Странката е успешно креирана', {
       variant: 'success',
       anchorOrigin: {
         vertical: 'top',
         horizontal: 'center'
       },
       TransitionComponent: Zoom
     });
   }

  handleCreateUserSuccess = () => {
    this.setState({isSubmitting: true});
    setTimeout(() => {
      this.createContact();
    }, 1000)
  };

  handleQueryChange = (event) => {
    this.setState({query: event.target.value})
  };


  render() {
 

    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center" mt={-2}>
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {'Спротивни странки'}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleCreateUserOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {'Додај странка'}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleCreateUserClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Нова странка'}
            </Typography>
              <Box component="div" sx={{ display: 'space-between' }}>
                  <Box component="div" sx={{ flexGrow: 1, pt: 0.5 }}  display='flex' justifyContent='flex-start' >
                  <Typography variant="subtitle2">
                    {'Потполнете ги празните полина за да креирате и да додадете нова странка'}
                  </Typography>
                  </Box>
                  <Box component="div" display='flex' justifyContent='flex-end' >
                    {this.state.addClient && 
                    <Button color="primary" variant='outlined'  onClick={() => this.setState({createNewClient: true, addClient:false})}>
                      {'Креирај'}
                    </Button> }
                  </Box>

              </Box>
          </DialogTitle>
            <DialogContent dividers sx={{ p: 3 }}>
            {this.state.addClient && <AllClientsAdd reloadClients={this.props.reloadClients} predmetID={this.props.predmetID} clients={this.props.users} />}
              {this.state.createNewClient &&
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Grid container spacing={3}>
                  <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={'Назив'}
                        name="naziv_sprotivna_stranka"
                        onChange={this.handleChange}
                        value={this.state.naziv_sprotivna_stranka}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'ЕМБГ/ЕМБС'}
                        name="embg_embs"
                        onChange={this.handleChange}
                        value={this.state.embg_embs}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Адреса'}
                        name="adresa"
                        onChange={this.handleChange}
                        value={this.state.adresa}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Телефонски број'}
                        name="telefon"
                        onChange={this.handleChange}
                        value={this.state.telefon}
                        type='text'
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Е-маил адреса'}
                        name="email"
                        onChange={this.handleChange}
                        type="email"
                        value={this.state.email}
                        variant="outlined"
                      />
                    </Grid>
                  
                    <Grid item xs={12} >
                      <TextField
                        fullWidth
                        label={'Полномошник'}
                        name="polnomosnikID"
                        onChange={this.handleChange}
                        type="email"
                        value={this.state.polnomosnikID}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>}
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
           
              <Button color="secondary" onClick={this.handleCreateUserClose}>
                {'Затвори'}
              </Button>
              {this.state.createNewClient &&
              <Button
                type="submit"
                startIcon={
                  this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={this.state.isSubmitting}
                onClick={this.handleCreateUserSuccess}
                variant="contained"
              >
                {'Додај'}
              </Button>}
            </DialogActions>
        </Dialog>
      </>
    );
  }
  
}

export default withSnackbar(PageHeader);
