import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// import ThemeSettings from 'src/components/ThemeSettings';
import Sidebar from './Sidebar';
import Header from './Header';

const MainWrapper = styled(Box)(
  () => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
`
);


const AccentHeaderLayout = (props) => {
  return (
    <>
      <MainWrapper>
        <Header logout={props.logout} type={props.type}/>
        <Sidebar type={props.type} />
        <MainContent>
          {props.component}
          {/*<ThemeSettings />*/}
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default AccentHeaderLayout;
