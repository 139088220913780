import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom,
  Typography,
  TextField,
  Fab,
  Tooltip,
  Box,
  Autocomplete,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { withSnackbar } from 'notistack';
import moment from "moment/moment";
import { CSVLink, CSVDownload } from "react-csv";
import DownloadIcon from '@mui/icons-material/Download';

const banks = [
  { label: 'Стопанска Банка АД Скопје', value: 'stopanska_ad_skopje' },
  { label: 'НЛБ Тутунска Банка АД Скопје', value: 'nlb_tutunska_skopje' },
  { label: 'Комерцијална банка АД Скопје', value: 'komercijalna_skopje' },
  { label: 'Халк Банка АД Скопје', value: 'halk_skopje' },
  { label: 'Шпаркасе Банка Македонија АД Скопје', value: 'sparkase' },
  { label: 'Силк Роуд Банка АД Скопје', value: 'silk_road' },
  { label: 'ТТК Банка АД Скопје', value: 'ttk_skopje' },
  { label: 'Капитал Банка АД Скопје', value: 'kapital_skopje' },
  { label: 'Прокредит Банка АД Скопје', value: 'prokredit' },
  { label: 'Стопанска Банка АД Битола', value: 'stopanska_ad_bitola' },
  { label: 'Народната Банка на Република Северна Македонија', value: 'narodna' },
  { label: 'Универзална Инвестициона Банка АД Скопје', value: 'univerzalna_investiciona' },
  { label: 'Македонска Банка за подршка на развојот АД Скопје', value: 'makedonska_banka_za_poddrska_na_razvojot' },
];

const status = [
  { label: 'Активен', value: 'aktiven' },
  { label: 'Неактивен', value: 'neaktiven' },
  { label: 'Блокиран', value: 'blokiran' },

];


class PageHeader extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
        open: false,
        naziv: '',
        embg_embs: '',
        adresa: '',
        tel_broj: '',
        broj_smetka: '',
        banka: '',
        email: '',
        status: '',
        headers: [
          { label: "Реден бр.", key: "index" },
          { label: "Име/назив на странка", key: "Naziv" },
          { label: "ЕМБГ/ЕМБС", key: "embg_embs" },
          { label: "Адреса", key: "adresa" },
          { label: "Телефон", key: "tel_broj" },
          { label: "Број на сметка", key: "broj_smetka" },
          { label: "Банка", key: "banka_label" },
          { label: "Емаил", key: "email" },
        ],
    }

    if(props.clients){
      props.clients.forEach(client => {
        client.banka_label = '';
        const bank_name = banks.find(s => s.value === client.banka);
        if(bank_name){
          client.banka_label = bank_name.label;
        }

      })
    }

  }

  handleCreateUserOpen = () => {
    this.setState({open :true});
  };

  handleCreateUserClose = () => {
    this.setState({
      open :false,
      naziv: '',
      embg_embs: '',
      adresa: '',
      tel_broj: '',
      broj_smetka: '',
      banka: '',
      email: '',
      status: '',
    });
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value,})
  }

  handleInputChangeBank = (e, value, test) => {
    banks.map((bank) => {
      if(bank.label === value) {
        this.setState({banka: bank.value})
      }
    })
  }
  handleInputChangeStatus = (e, value, test) => {
    status.map((stat) => {
      if(stat.label === value) {
        this.setState({status: stat.value})
      }
    })
  }

 

  handleCreateUserSuccess = () => {
    window.makeRequest("POST", `/api/Clients`, {
      naziv: this.state.naziv,
      embg_embs: this.state.embg_embs,
      adresa: this.state.adresa,
      tel_broj: this.state.tel_broj,
      broj_smetka: this.state.broj_smetka,
      banka: this.state.banka,
      email: this.state.email,
      status: this.state.status,
      IsPrivate: false,
      created_at: new Date(),
      }, true, (callback) => {
          this.setState({
            open: false,
            naziv: '',
            embg_embs: '',
            adresa: '',
            tel_broj: '',
            broj_smetka: '',
            banka: '',
            email: '',
            status: '',
          }, () => this.props.renderClients())
      }, (err) => {
        console.log('err', err);
    });

    this.props.enqueueSnackbar('Клиентот е успешно креиран', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });

  };


  render() {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {'Клиенти'}
            </Typography>
          </Grid>
          <Grid item>
              <Tooltip title="Превземи" arrow>
                <Fab  size="small"   >
                  <CSVLink headers={this.state.headers}  filename={"Клиенти-"+moment(new Date()).format('DD.MM.yyyy')+'.csv'}
                           data={this.props.clients}
                  >
                    <DownloadIcon color={'primary'}   />
                  </CSVLink>
                </Fab>
              </Tooltip>
            <Button
              sx={{ mt: { xs: 2, sm: 0 }, ml: { xs: 2, sm: 4 }}}
              onClick={this.handleCreateUserOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {'Креирај клиент'}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleCreateUserClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Нов клиент'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Потполнете ги празните полина за да креирате и да додадете нов клиент'
              }
            </Typography>
          </DialogTitle>
                <DialogContent dividers sx={{ p: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={12}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label={'Назив'}
                            name="naziv"
                            onChange={this.handleChange}
                            value={this.state.naziv}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label={'ЕМБГ/ЕМБС'}
                            name="embg_embs"
                            onChange={this.handleChange}
                            value={this.state.embg_embs}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label={'Адреса'}
                            name="adresa"
                            onChange={this.handleChange}
                            value={this.state.adresa}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label={'Телефонски број'}
                            name="tel_broj"
                            onChange={this.handleChange}
                            value={this.state.tel_broj}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            label={'Е-маил адреса'}
                            name="email"
                            onChange={this.handleChange}
                            type="email"
                            value={this.state.email}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} >
                          <TextField
                            fullWidth
                            label={'Број на сметка'}
                            name="broj_smetka"
                            onChange={this.handleChange}
                            value={this.state.broj_smetka}
                            variant="outlined"
                          />
                        </Grid>  
                        <Grid item xs={12} md={6} >

                          <Autocomplete
                            options={banks}
                            getOptionLabel={(option) => option.label}
                            onInputChange={this.handleInputChangeBank}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={'Банка'}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} >
                          <Autocomplete
                            disablePortal
                            options={status}
                            defaultValue={status[0]}
                            getOptionLabel={(option) => option.label}
                            onInputChange={this.handleInputChangeStatus}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={'Статус'}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                  <Button color="secondary" onClick={this.handleCreateUserClose}>
                    {'Затвори'}
                  </Button>
                  <Button
                    type="submit"
                    // startIcon={
                    //   isSubmitting ? <CircularProgress size="1rem" /> : null
                    // }
                    onClick={this.handleCreateUserSuccess}
                    variant="contained"
                  >
                    {'Креирај'}
                  </Button>
                </DialogActions>
        </Dialog>
      </>
    );
  }
  
}

export default withSnackbar(PageHeader);
