import React from 'react';
import { styled } from '@mui/material/styles';
import 'react-quill/dist/quill.snow.css';

import {
  Grid,
  Divider,
  Alert,
  DialogContent,
  Box,
  List,
  FormHelperText,
  CircularProgress,
  TextField,
  IconButton,
  Button,
} from '@mui/material';
import CloudUploadTwoToneIcon from '@mui/icons-material/CloudUploadTwoTone';
import request from "superagent";

const BoxUploadWrapper = styled(Box)(
  ({ theme }) => `
    border-radius: ${theme.general.borderRadius};
    padding: ${theme.spacing(3)};
    background: ${theme.colors.alpha.black[5]};
    border: 1px dashed ${theme.colors.alpha.black[30]};
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: ${theme.transitions.create(['border', 'background'])};

    &:hover {
      background: ${theme.colors.alpha.white[100]};
      border-color: ${theme.colors.primary.main};
    }
`
);

class CreateDocument extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      open: false,
      isSubmitting: false,
      files: [],
      message: null,
      title: '',
    }
  }

  handleTitleChange = (e) => {
    this.setState({title: e.target.value, message: ''});
  };


  createNewDocument = () => {
    let files = this.state.files;

    if(Object.getOwnPropertyNames(files).length !== 0) {
      this.createDocument();
    } else {
      const data = new FormData();
      data.append('file', this.state.files);
      request.post('/api/upload')
        .accept('application/json')
        .send(data).then((callback) => {
        this.setState({documentUploaded: true},this.createDocument(callback.body));
      }, (err) => {
        console.log('err', err);

      });
    }
  }

  handleCreateDocument = () => {
    let title = this.state.title;
    if(title && title.trim() !== '') {
      this.setState({isSubmitting: true});
      setTimeout(() => {
        this.createNewDocument()
      }, 1000)

    }else {
      this.setState({message: 'Внесете Наслов'});
      
    }
    
   
  }


  createDocument = (path) => {
      let filePath = '';
      if(path && path.trim() !== ""){
        filePath = path;
      }

      window.makeRequest("POST", `/api/documentsObvrski`, {
        obvrskaID:   parseInt(this.props.id),
        userID:   this.props.userID,
        title:  this.state.title,
        path: filePath,
        created_at: new Date(),
      }, true, (callback) => {
        this.setState({open: false, isSubmitting: false,}, this.props.handleCreateUserClose(true));
      }, (err) => {
          console.log('err', err);
      });
      
   
  }

  render(){
    return (
      <>
        <DialogContent dividers sx={{ p: 3 }}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              justifyContent="flex-end"
              textAlign={{ sm: 'right' }}
            >
              <Box
                pr={3}
                sx={{ pt: 3, pb: { xs: 1, md: 0 } }}
                alignSelf="center"
              >
                <b>{'Наслов'}:</b>
              </Box>
            </Grid>
            <Grid
              sx={{
                mb: 3
              }}
              item
              xs={12}
              sm={8}
              md={9}
            >
              <TextField
                fullWidth
                name="title"
                placeholder={'Наслов...'}
                onChange={this.handleTitleChange}
                value={this.state.title}
                variant="outlined"
              />
              <FormHelperText error={true} >{this.state.message}</FormHelperText>

            </Grid>
           
            <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
              <Box pr={3} sx={{ pb: { xs: 1, md: 0 } }}>
                <b>{'Прикачување'}:</b>
              </Box>

            </Grid>
            <Grid
              sx={{
                mb: 3
              }}
              item
              xs={12}
              sm={8}
              md={9}
            >
         
                  <>
                    <label >
                      <BoxUploadWrapper>
                        <input 
                                onChange={(e) => this.setState({files: e.target.files[0]})} hidden  type="file" />
                        <IconButton color="primary"  component="span">
                          <CloudUploadTwoToneIcon fontSize="large" />
                        </IconButton>
                      </BoxUploadWrapper>
                    </label>
                  </>
              {this.state.files.name && (
                <>
                  <Alert sx={{ py: 0, mt: 2 }} severity="success">
                    <b>{this.state.files.name}</b>
                  </Alert>
                  <Divider sx={{ mt: 2 }} />
                  {/*<List disablePadding component="div">*/}
                  {/*{this.state.files.name}*/}
                  {/*</List>*/}
                </>
              )}
            </Grid>
  
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              textAlign={{ sm: 'right' }}
            ></Grid>
            <Grid
              sx={{
                mb: 3
              }}
              item
              xs={12}
              sm={8}
              md={9}
            >
              <Button
                sx={{ mr: 2 }}
                onClick={this.handleCreateDocument}
                type="submit"
                variant="contained"
                disabled={this.state.isSubmitting}
                startIcon={
                  this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                size="large"
              >
                {'Креирај документ'}
              </Button>
              <Button
                color="secondary"
                size="large"
                variant="outlined"
                onClick={this.props.handleCreateUserClose}
              >
                {'Затвори'}
              </Button>

              
            </Grid>
            
          </Grid>
        </DialogContent>
      </>
    );
  }

}

export default CreateDocument;
