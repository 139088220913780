import React from 'react';
import {
  Box,
  Typography,
  Card,
  Divider,
  Tooltip,
  Fab,
} from '@mui/material';
import Results from "./Results";
import DownloadIcon from '@mui/icons-material/Download';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment/moment";
import SuspenseLoader from '../../../../components/SuspenseLoader'

class PredmetsTab extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      predmets: [],
      headers: [
        { label: "Реден бр.", key: "index" },
        { label: "Име/назив на странка", key: "clientsCSV" },
        { label: "Име/назив на спротивна странка", key: "contactsCSV" },
        { label: "Институција", key: "sud" },
        { label: "Судски број", key: "broj_predmet" },
        { label: "Основ", key: "osnov" },
        { label: "Вредност на спорот", key: "vrednost" },
        { label: "Фаза на предмет", key: "faza_predmet" },
        { label: "Статус на предмет", key: "status" },
      ],
    }
  }

  componentDidMount() {
    this.props.clientID &&
    this.reloadPredmets();


  }

  reloadPredmets = () => {
    window.makeRequest("GET", `/api/all_predmeti_preview`, null, true, (callback) => {
      let filteredArr = [];
      if(callback.body && callback.body.length > 0) {
        callback.body.filter((record, index) => {
          record.clientsCSV = [];
          record.contactsCSV = [];
          record.clients.map((client) => {
            if(client.ID === parseInt(this.props.clientID)){
              record.index = record.index + 1;
              record.clientsCSV = record.clients.map(cli => cli.Naziv);
              record.contactsCSV = record.contacts.map(cli => cli.naziv_sprotivna_stranka);
              filteredArr.push(record);
            }
          })
        })
        filteredArr.sort((a, b) =>  a.ID - b.ID).reverse();
        filteredArr.map((fil, index) => {
          fil['index'] = index + 1;
        })
        this.setState({predmets: filteredArr, loading: false})
      }
    }, (err) => {
      console.log('err', err);
    });
  }
  
  


  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return (
        <Card>
          <Box pt={3} pl={3} pr={3} pb={1} display={'flex'} justifyContent={'space-between'} >
            <Typography variant="h3" >
              Прикажување на предметите поврзани со клиентот
            </Typography>
            <Box>
              <Tooltip title="Превземи" arrow>
                <Fab  size="small"   >
                  <CSVLink headers={this.state.headers}  filename={"Клиент-"+moment(new Date()).format('DD.MM.yyyy')+'.csv'}
                           data={this.state.predmets}
                  >
                    <DownloadIcon color={'primary'}   />
                  </CSVLink>
                </Fab>
              </Tooltip>
            </Box>
          </Box>
          <Results projects={this.state.predmets} reload={this.reloadPredmets}/>

          <Divider />

        </Card>
      );
    }

  }

}

export default PredmetsTab;
