import React from  'react';
import 'react-quill/dist/quill.snow.css';
import {
    Grid,
    Dialog,
    DialogTitle,
    Tooltip,
    Chip,
    DialogContent,
    DialogActions,
    ButtonGroup,
    Typography,
    TextField,
    CircularProgress,
    FormControl,
    MenuItem,
    InputLabel,
    Select,
    Avatar,
    Autocomplete,
    Button,
  } from '@mui/material';
  import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
  import AddClientModal from './../components/AddClientModal';
  import AddContactModal from './../components/AddContactModal';
  import NumberFormat from 'react-number-format';


const StatusClient = [
  { title: 'Тужител' },
  { title: 'Тужен' },
  { title: 'Оштетен' },
  { title: 'Обвинет' },
  { title: 'Предлагач' },
  { title: 'Противник' },
  { title: 'Доверител' },
  { title: 'Должник' },
];
const StatusPredmet = [
  { title: 'Активен' },
  { title: 'Незапочнат' },
  { title: 'Завршен' },
];
const FazaPredmet = [
  { title: 'Tужба' },
  { title: 'Предлог за времена мерка' },
  { title: 'Одговор на тужба' },
  { title: 'Рочиште' },
  { title: 'Супервештачење' },
  { title: 'Прекин' },
  { title: 'Првостепена пресуда' },
  { title: 'Жалба' },
  { title: 'Второстепена пресуда' },
  { title: 'Ревизија' },
  { title: 'Решение за утврдени побарувања (стечај)' },
  { title: 'Решение за запирање и заклучување (стечај)' },
  { title: 'План за конечна распределба (стечај)' },
  { title: 'План за реорганизација (стечај)' },
  { title: 'Апликација ЕСЧП' },
  { title: 'Арбитража' },
  { title: 'Опомена пред тужба'},
];


class AddPredmetModal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            isSubmitting: false,
            open:false,
            files: '',
            users: [],
            contacts: [],
            clients: [],
            status_klient: '',
            broj_predmet: '',
            osnov: '',
            vrednost: null,
            sud: '',
            faza_predmet: '',
            status: '',
            advokat_id: '',
            naznaceniPredmet: [],
            openAddClientModal: false,
            openAddContactModal: false,
            clientsPredmet: [],
            contactsPredmet: [],
        }


    }
    componentDidMount() {
          this.renderUsers();
          this.renderClients();
          this.renderContacts();
         
    }

    renderUsers = () => {
      window.makeRequest("GET", `/api/previewUsers?role=user`, null, true, (callback) => {
        if(callback.body && callback.body.length > 0) {
          let users = callback.body;
          window.makeRequest("GET", `/api/previewUsers?role=client`, null, true, (callback) => {
            let newData = users.concat(callback.body.recordset);
            this.setState({users: newData});
          }, (err) => {
            console.log('err', err);
          });

        }
      }, (err) => {
        console.log('err', err);
      });
    }
    renderClients = () => {
      window.makeRequest("GET", `/api/getClients`, null, true, (callback) => {
        this.setState({clients: callback.body.sort((a, b) => a.ID - b.ID).reverse()});
      }, (err) => {
        console.log('err', err);
      });
    }
    renderContacts = () => {
        window.makeRequest("GET", `/api/Contacts`, null, true, (callback) => {
          this.setState({contacts: callback.body.recordset.sort((a, b) =>  a.ID - b.ID).reverse()});
        }, (err) => {
          console.log('err', err);
        });
    }


    handleCreateProjectClose = () => {
        this.setState({
        open: false,  
        status_klient: '',
        broj_predmet: '',
        osnov: '',
        vrednost: null,
        sud: '',
        faza_predmet: '',
        status: '',
        advokat_id: '',
        vrednostErr: '',
        error: false,
        naznaceniPredmet: [],
        clientsPredmet: [], 
        contactsPredmet: []
      })
    }
    handleCreateProjectOpen = () => {
      this.setState({open: true});
    }


    handleChange = (e) => {
      if(e.target.name === 'vrednost') {
        let removed = e.target.value.replace(/\,/g,'');
        this.setState({[e.target.name]: removed})
      } else {
        this.setState({[e.target.name]: e.target.value})
      }
    }
    validate = () => {
      let vrednostErr = '';
      let vrednost = this.state.vrednost;

      switch (true) {
        case isNaN(vrednost):
          vrednostErr = 'Невалиден број';
          break;
        default:
      }
      if(vrednostErr) {
        this.setState({ vrednostErr,error: true });
        return false;
      }

      return true;
    }
    createNewPredmet = () => {
      const isValid = this.validate();
      isValid&&window.makeRequest("POST", `/api/Predmet`, {
        status_klient: this.state.status_klient,
        broj_predmet: this.state.broj_predmet,
        osnov: this.state.osnov,
        vrednost: this.state.vrednost !== null ? this.state.vrednost : 0,
        sud: this.state.sud,
        faza_predmet: this.state.faza_predmet,
        status: this.state.status,
        created_at: new Date(),
      }, true, (callback) => {
        if(callback.body && callback.body.recordset[0].id) {
          let createKlauzuli = false;
          if(this.state.status === 'Завршен'){
            createKlauzuli = true;
          }
          let id = callback.body.recordset[0].id;

          this.systemNumber(id);
          this.naznaceniPredmet(id);
          this.clientsPredmet(id);
          this.contactsPredmet(id);
          this.clientsUsersInsertView(id);
          this.handleCreateProjectClose();
          if(createKlauzuli){
            this.setState({isSubmitting: false} )
            this.props.success();
            this.props.reload(true, id);
          } else {
            this.setState({isSubmitting: false})
            this.props.success();
            this.props.reload();
          }
        }
      }, (err) => {
        console.log('err', err);
      });


    }



    createPredmet = () => {
      this.setState({isSubmitting: true});
      setTimeout(() => {
        this.createNewPredmet()
      }, 1000)

    }
    systemNumber = (id) => {
      window.makeRequest("PUT", `/api/Predmet?ID=${id}`, {
        system_number: id+1000,
        }, true, (callback) => {
            // this.setState({open: false, isSubmitting: false})
        }, (err) => {
          console.log('err', err);
      });
    }
    naznaceniPredmet = (id) => {
      this.state.naznaceniPredmet.forEach((user) => {
        window.makeRequest("GET", `/api/PredmetUsers?predmetID=${id}&userID=${user.ID}`, null, true, (callback) => {
          if(callback.body && callback.body.recordset.length > 0){
            return null;
          }else {
            window.makeRequest("POST", `/api/PredmetUsers`, {
              userID: user.ID,
              predmetID: id,
              user_read: true,
              user_write: true,
              created_at: new Date(),
            }, true, (callback) => {
            }, (err) => {
              console.log('err', err);
            });
          }
        }, (err) => {
          console.log('err', err);
        });

      })

    }
    clientsPredmet = (id) => {
      this.state.clientsPredmet.forEach((client) => {
        window.makeRequest("POST", `/api/pivotPredmetClients`, {
          predmetID: id,
          clientID: client.ID,
          created_at: new Date(),
          }, true, (callback) => {
              // this.setState({open: false, isSubmitting: false})
          }, (err) => {
            console.log('err', err);
        });
      })
    }
    clientsUsersInsertView = (id) => {
      this.state.clientsPredmet.forEach((client) => {
        client.embg_embs !== '' && window.makeRequest("GET", `/api/previewUsers?embs=${client.embg_embs}`, false, true, (callback) => {
          if(callback.body.recordset.length > 0) {
            callback.body.recordset.map((record) => {
              window.makeRequest("GET", `/api/PredmetUsers?predmetID=${id}&userID=${record.ID}`, null, true, (callback) => {
                if(callback.body && callback.body.length > 0){
                  return null;
                } else {
                  window.makeRequest("POST", `/api/PredmetUsers`, {
                    predmetID: id,
                    userID: record.ID,
                    user_read: true,
                    user_write: false,
                    created_at: new Date(),
                  }, true, (callback) => {
                    // this.setState({open: false, isSubmitting: false})
                  }, (err) => {
                    console.log('err', err);
                  });
                }
              }, (err) => {
                console.log('err', err);
              });

            })
          }
          // this.setState({open: false, isSubmitting: false})
        }, (err) => {
          console.log('err', err);
        });

      })
    }
    contactsPredmet = (id) => {
      this.state.contactsPredmet.forEach((contact) => {
        window.makeRequest("POST", `/api/pivotPredmetContacts`, {
          predmetID: id,
          contactID: contact.ID,
          created_at: new Date(),
          }, true, (callback) => {
          }, (err) => {
            console.log('err', err);
        });
      })
     
    }
    onChangeStatusClient = (e) => {e.target.value ?this.setState({status_klient: e.target.value}):this.setState({status_klient: ""})}
    onChangeStatusPredmet = (e) => {e.target.value ?this.setState({status: e.target.value}):this.setState({status: ""})}
    onChangeFazaPredmet = (e) => {e.target.value ?this.setState({faza_predmet: e.target.value}):this.setState({faza_predmet: ""})}
    onChangeNaznaceniPredmet = (e, val) => {val?this.setState({naznaceniPredmet: val}):this.setState({naznaceniPredmet: []})}
    onChangeClients = (e,val) => {val?this.setState({clientsPredmet: val}):this.setState({clientsPredmet: []})}
    onChangeContacts = (e,val) => {val?this.setState({contactsPredmet: val}):this.setState({contactsPredmet: []})}
    CloseContactModal = (v) => {this.setState({openAddContactModal: false}, () => this.renderContacts())}
    CloseClientModal = () => {this.setState({openAddClientModal: false},() => this.renderClients())}
   


    render() {
        const { classes, theme } = this.props;
      return(
            <>
              {this.props.type !== 'client' &&
              <>
                {this.props.header}
                <Grid   >
                  <Button
                    sx={{ mt: { xs: 2, sm: 0 } }}
                    onClick={this.handleCreateProjectOpen}
                    variant="contained"
                    startIcon={<AddTwoToneIcon fontSize="small" />}
                  >
                    {'Креирај нов предмет'}
                  </Button>
                </Grid>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleCreateProjectClose}
                    maxWidth={'md'}>
                        <DialogTitle sx={{ p: 3 }}>
                        <Typography variant="h4" gutterBottom>
                        {'Нов предмет'}
                        </Typography>
                        <Typography variant="subtitle2">
                        {'Потполнете ги празните полина за да креирате и да додадете нов предмет'}
                        </Typography>
                    </DialogTitle>
                    <DialogContent dividers sx={{ p: 3 }}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} lg={12}>
                        <Grid container spacing={3}>
                          <Grid item xs={12}  >
                            <ButtonGroup disableElevation >
                              <Tooltip title={'Нов Клиент'}  arrow>
                                <Button
                                  onClick={() => this.setState({openAddClientModal: true})}
                                  variant="outlined" size="small">
                                  {'+ Клиент'}
                                </Button>
                              </Tooltip>
                            </ButtonGroup>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            md={6}
                          >
                            <Autocomplete
                              multiple
                              sx={{ m: 0 }}
                              limitTags={2}
                              onChange={this.onChangeClients}
                              options={this.state.clients}
                              renderOption={(props, option) => (
                                <li {...props}>
                                  <Avatar sx={{ mr: 1 }}   />
                                  {option.Naziv}
                                </li>
                              )}
                              getOptionLabel={(option) => option.Naziv}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  InputLabelProps={{
                                    shrink: true
                                  }}
                                  placeholder={'Селектирај Клиенти за предметот...'}
                                />
                              )}
                              renderTags={(clients, getTagProps) =>
                                clients.map((ev, index) => (
                                  <Chip
                                    label={ev.Naziv}
                                    {...getTagProps({ index })}
                                    avatar={<Avatar />}
                                  />
                                ))
                              }
                            />
                          </Grid>
                            <Grid item xs={12}  md={6}>
                              <FormControl fullWidth>
                                <InputLabel>Статус клиент</InputLabel>
                                <Select
                                  value={this.state.status_klient}
                                  label="Статус клиент"
                                  onChange={this.onChangeStatusClient}
                                >
                                  {StatusClient.map((status) => {
                                    return <MenuItem key={status.title} value={status.title}>{status.title}</MenuItem>
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                          <Grid item xs={12} md={6}>
                            <TextField
                              fullWidth
                              label={'Институција'}
                              name="sud"
                              onChange={this.handleChange}
                              type="text"
                              value={this.state.sud}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item xs={12}  md={6}>
                            <NumberFormat
                              fullWidth
                              label={'Вредност'}
                              name="vrednost"
                              error={!!this.state.vrednostErr}
                              helperText={this.state.vrednostErr}
                              onChange={this.handleChange}
                              type="text"
                              customInput={TextField}
                              // placeholder={numeral(this.state.vrednost).format(`0,0.00`)}
                              // defaultValue={numeral(this.state.vrednost).format(`0,0.00`)}
                              value={this.state.vrednost}
                              variant="outlined"
                              thousandSeparator={true}
                            />
                          </Grid>
                            <Grid item xs={12}  md={6}>

                            <TextField
                                fullWidth
                                label={'Број предмет'}
                                name="broj_predmet"
                                onChange={this.handleChange}
                                value={this.state.broj_predmet}
                                variant="outlined"
                            />
                            </Grid>
                            <Grid item xs={12}  md={6}>
                            <TextField
                                fullWidth
                                label={'Основ'}
                                name="osnov"
                                onChange={this.handleChange}
                                type="text"
                                value={this.state.osnov}
                                variant="outlined"
                            />
                            </Grid>

                            <Grid item xs={12}  md={6}>

                              <FormControl fullWidth>
                                <InputLabel>Статус предмет</InputLabel>
                                <Select
                                  value={this.state.status}
                                  label="Статус предмет"
                                  onChange={this.onChangeStatusPredmet}
                                >
                                  {StatusPredmet.map((status) => {
                                    return <MenuItem key={status.title} value={status.title}>{status.title}</MenuItem>
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12}  md={6}>

                              <FormControl fullWidth>
                                <InputLabel>Фаза на предмет</InputLabel>
                                <Select
                                  value={this.state.faza_predmet}
                                  label="Фаза на предмет"
                                  onChange={this.onChangeFazaPredmet}
                                >
                                  {FazaPredmet.map((faza) => {
                                    return <MenuItem key={faza.title} value={faza.title}>{faza.title}</MenuItem>
                                  })}
                                </Select>
                              </FormControl>
                            </Grid>

                          {this.props.type === 'admin' && <Grid
                                item
                                xs={12}
                            >
                                <Autocomplete
                                multiple
                                sx={{ m: 0 }}
                                limitTags={2}
                                options={this.state.users}
                                onChange={this.onChangeNaznaceniPredmet}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                    <Avatar sx={{ mr: 1 }}   />
                                    {option.first_name}
                                    </li>
                                )}
                                getOptionLabel={(option) => option.first_name}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    placeholder={'Селектирај назначени за предметот...'}
                                    />
                                )}
                                renderTags={(users, getTagProps) =>
                                    users.map((ev, index) => (
                                    <Chip
                                        key={ev.ID}
                                        label={ev.first_name}
                                        {...getTagProps({ index })}
                                        avatar={<Avatar />}
                                    />
                                    ))
                                }
                                />
                            </Grid>}
                          <Grid item xs={12} >
                            <ButtonGroup disableElevation >
                              <Tooltip title={'Нова Странка'}  arrow>
                                <Button
                                  onClick={() => this.setState({openAddContactModal: true})}
                                  variant="outlined" size="small">
                                  {'+ Странка'}
                                </Button>
                              </Tooltip>
                            </ButtonGroup>

                          </Grid>
                            <Grid
                                sx={{
                                mb: `${theme.spacing(3)}`
                                }}
                                item
                                xs={12}
                                // md={6}
                            >
                                <Autocomplete
                                multiple
                                sx={{ m: 0 }}
                                limitTags={2}
                                onChange={this.onChangeContacts}
                                noOptionsText={'Странката не е пронајдена кликнете на копчето странка за да додадете'}
                                options={this.state.contacts}
                                renderOption={(props, option) => (
                                    <li {...props}>
                                    <Avatar sx={{ mr: 1 }}   />
                                    {option.naziv_sprotivna_stranka}
                                    </li>
                                )}
                                getOptionLabel={(option) => option.naziv_sprotivna_stranka}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    placeholder={'Селектирај Спротивни странки за предметот...'}
                                    />
                                )}
                                renderTags={(users, getTagProps) =>
                                    users.map((ev, index) => (
                                    <Chip
                                        label={ev.naziv_sprotivna_stranka}
                                        {...getTagProps({ index })}
                                        avatar={<Avatar />}
                                    />
                                    ))
                                }
                                />
                            </Grid>
                        </Grid>

                        </Grid>
                    </Grid>
                    </DialogContent>
                    <DialogActions sx={{ p: 3 }}>
                    <Button color="secondary" onClick={this.handleCreateProjectClose}>
                        {'Затвори'}
                    </Button>
                    <Button
                        type="submit"
                        disabled={this.state.isSubmitting}
                        onClick={this.createPredmet}
                        startIcon={
                            this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
                        }
                        variant="contained"
                    >
                        {'Креирај'}
                    </Button>
                    </DialogActions>
                </Dialog>
                <AddClientModal onClose={this.CloseClientModal}  open={this.state.openAddClientModal} />
                <AddContactModal onClose={this.CloseContactModal} createdContact={this.createdContact} open={this.state.openAddContactModal} />
              </>}
            </>
        );
    }
}

export default AddPredmetModal;
