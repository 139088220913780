import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import SuspenseLoader from '../../../components/SuspenseLoader'

import { Grid } from '@mui/material';

import Results from './Results';
import TableSortable from './TableSortable';
class ManagementProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      projects: [],
      clients: [],
      cotacts: [],

    }
  }
  componentDidMount () {
    this.renderClients();
    this.getPredmeti();
  }

  renderClients = () => {
    window.makeRequest("GET", `/api/getAllClients`, null, true, (callback) => {
      this.setState({clients: callback.body.sort((a, b) => a.ID - b.ID).reverse()});
    }, (err) => {
      console.log('err', err);
    });
  }

  getPredmeti = (createObvrska, id) => {
    this.setState({loading: true})
    window.makeRequest("GET", `/api/all_predmeti_preview`, null, true, (callback) => {
      if(callback.body !== 'no results') {
        let predmeti = null;
        if(callback.body.length > 0) {
          predmeti = callback.body.sort((a, b) => a - b).reverse();
        }
        if(createObvrska === true && id) {
          this.setState({
            projects: predmeti,

          }, () => this.createObvrskaKlauzuli(id));
        } else {
          this.setState({
            projects: predmeti,
            loading: false,
          });
        }

      } else {
        this.setState({
          projects: [],
          loading: false,
        });
      }

    }, (err) => {
      console.log('err', err);
    });
  }
  createObvrskaKlauzuli = (id) => {

    id && window.makeRequest("GET", `/api/all_predmeti_preview?ID=${id}`,false
      , true, (callback) => {
        const Members = [];
        const Contacts = [];
        const Clients = [];
        const broj_predmet = callback.body[0].broj_predmet;
        callback.body[0].members.map((member) => {
          Members.push(member.userID, member.user_write);
        })
        callback.body[0].clients.map((client) => {
          Clients.push(client.ID);
        })
        callback.body[0].contacts.map((contact) => {
          Contacts.push(contact.ID);
        })

        window.makeRequest("PUT", `/api/obvrskaKlauzuli?predmetID=${id}`,
          {
            broj_predmet: broj_predmet,
            members: Members,
            contacts: Contacts,
            clients: Clients,
          }
          , true, (callback) => {
            this.setState({loading: false})
          }, (err) => {
            console.log('err', err);
          });


      }, (err) => {
        console.log('err', err);
      });


  }


  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return (
        <>
          <Helmet>
            <title>Предмети</title>
          </Helmet>
          <PageTitleWrapper>
            <PageHeader type={this.props.type} reload={this.getPredmeti}  predmet={this.props.predmet}  />
          </PageTitleWrapper>

          <Grid
            sx={{ px: 4 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              {/*<Results clients={this.state.clients} type={this.props.type} reload={this.getPredmeti} projects={this.state.projects} />*/}
              <TableSortable clients={this.state.clients} type={this.props.type} reload={this.getPredmeti} projects={this.state.projects} />

            </Grid>
          </Grid>
          <Footer />
        </>
      );
    }

  }
}

export default ManagementProjects;
