import React from 'react';

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';

import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import SuspenseLoader from '../../../components/SuspenseLoader'
import Results from './Results';

class ManagementUsers extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      clients: [],
    }
  }

  componentDidMount() {
    this.getClients();
  }

  getClients = () => {
    this.setState({loading: true})
    window.makeRequest("GET", `/api/getAllClients`, null, true, (callback) => {
      this.setState({clients: callback.body && callback.body.sort((a, b) =>  a.ID - b.ID).reverse(), loading: false});
    }, (err) => {
      console.log('err', err);
    });
  }

  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return (
        <>
          <Helmet>
            <title>Клиенти</title>
          </Helmet>
          <PageTitleWrapper>
            <PageHeader clients={this.state.clients} renderClients={this.getClients} />
          </PageTitleWrapper>

          <Grid
            sx={{ px: 4 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Results reload={this.getClients} clients={this.state.clients} />
            </Grid>
          </Grid>
          <Footer />
        </>
      );
    }

  }
  
}

export default ManagementUsers;
