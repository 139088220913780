import React, { useState, forwardRef } from 'react';

import PropTypes from 'prop-types';
import TableSortLabel from '@mui/material/TableSortLabel';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import {Link as RouterLink} from "react-router-dom";
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


import numeral from "numeral";
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

import {
  Avatar,
  Box,
  Card,
  Grid,
  Slide,
  Tooltip,
  IconButton,
  InputAdornment,
  AvatarGroup,
  Table,
  FormControl,
  InputLabel,
  Select,
  TableBody,
  MenuItem,
  TableCell,
  TableHead,
  Autocomplete,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  useTheme,
  useMediaQuery,
  Typography,
} from '@mui/material';
import {Button, Dialog, Zoom} from "@mui/material/index";

import { useSnackbar } from 'notistack';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const {  order, orderBy, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.filters.status === 'Завршен' && <TableCell >{'Клаузули'}</TableCell>}
        <TableCell >{'Назив на странка'}</TableCell>
        <TableCell >{'Спротивна странка'}</TableCell>
        <TableCell
          key={'sud'}
          align={'center'}
          padding={'none'}
          sortDirection={orderBy === 'sud' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'sud'}
            direction={orderBy === 'sud' ? order : 'asc'}
            onClick={createSortHandler('sud')}
          >
            {'Институција'}
            {orderBy === 'sud' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>

        </TableCell>
        <TableCell
          key={'broj_predmet'}
          align={'center'}
          padding={'none'}
          sortDirection={orderBy === 'broj_predmet' ? order : false}

        >
          <TableSortLabel
            active={orderBy === 'broj_predmet'}
            direction={orderBy === 'broj_predmet' ? order : 'asc'}
            onClick={createSortHandler('broj_predmet')}
          >
            {'Број Предмет'}
            {orderBy === 'broj_predmet' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>

        </TableCell>
        <TableCell
          key={'osnov'}
          align={'center'}
          padding={'none'}
          sortDirection={orderBy === 'osnov' ? order : false}


        >
          <TableSortLabel
            active={orderBy === 'osnov'}
            direction={orderBy === 'osnov' ? order : 'asc'}
            onClick={createSortHandler('osnov')}
          >
            {'Основ'}
            {orderBy === 'osnov' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>

        </TableCell>
        {props.type !== 'client' &&<TableCell
          key={'vrednost'}
          align={'center'}
          padding={'none'}
          sortDirection={orderBy === 'vrednost' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'vrednost'}
            direction={orderBy === 'vrednost' ? order : 'asc'}
            onClick={createSortHandler('vrednost')}
          >
            {'Вредност'}
            {orderBy === 'vrednost' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>}
        <TableCell
          key={'faza_predmet'}
          align={'center'}
          padding={'none'}
          sortDirection={orderBy === 'faza_predmet' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'faza_predmet'}
            direction={orderBy === 'faza_predmet' ? order : 'asc'}
            onClick={createSortHandler('faza_predmet')}
          >
            {'Фаза на предмет'}
            {orderBy === 'faza_predmet' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell >{'Статус'}</TableCell>
        <TableCell>{'Вработени'}</TableCell>
        <TableCell align="center">{'Actions'}</TableCell>

      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const applyFilters = (projects, query, filters) => {
  return projects.filter((project) => {
    let matches = true;

    if (query) {
      const properties = ['broj_predmet','status_klient', 'sud', 'faza_predmet', 'tip_predmet', 'status' , 'osnov' , 'system_number' , 'reference_broj' , 'vrednost', 'interen_broj'];
      let containsQuery = false;
      properties.forEach((property) => {
        if (project[property] && project[property].toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      project.members && project.members.forEach((member) => {
        if (member.first_name && member.first_name.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      project.clients && project.clients.forEach((member) => {
        if (member.Naziv && member.Naziv.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      project.contacts && project.contacts.forEach((member) => {
        if (member.naziv_sprotivna_stranka && member.naziv_sprotivna_stranka.toString().toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      })
      if (filters.status && project.status !== filters.status) {
        matches = false;
      }
      if (!containsQuery) {
        matches = false;
      }
    }


    if (filters) {
      Object.keys(filters).forEach((key) => {
        const value = filters[key];
        if(key === 'status' && filters.status !== null) {
          if (value && project[key] !== value) {
            matches = false;
          }
        }

        if(key === 'client' && filters.client !== null){
          if(!project.clients.find((r) => r.ID === filters.client)){
            matches = false
          }


        }


        // if(value === null && project[key] === 'Завршен') {
        //   matches = false;
        // }
      });
    }

    return matches;
  });
};

export default function TaboeSortable(props) {
  const theme = useTheme();
  const {enqueueSnackbar} = useSnackbar();
  const { t } = useTranslation();

  const [filters, setFilters] = useState({
    status: null,
    client: null,
  });
  const [query, setQuery] = useState('');
  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };
  const statusOptions = [
    {
      id: 'all',
      name: 'Сите'
    },
    {
      id: 'Активен',
      name: 'Активен'
    },
    {
      id: 'Незапочнат',
      name: 'Незапочнат'
    },
    {
      id: 'Завршен',
      name: 'Завршен'
    }
  ];
  const handleClientChange = (client) => {
    if(client && client.hasOwnProperty('ID')){
      setFilters((prevFilters) => ({
        ...prevFilters,
        client: client.ID,
      }));
    }else {
      setFilters((prevFilters) => ({
        ...prevFilters,
        client: null,
      }));
    }

  };
  const handleStatusChange = (e) => {
    let value = null;
    if (e.target.value !== 'all') {
      value = e.target.value;
    }
    setFilters((prevFilters) => ({
      ...prevFilters,
      status: value,
    }));
  };
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const minimizeWindow = useMediaQuery(theme.breakpoints.down('lg'));
  const filteredProjects = applyFilters(props.projects, query, filters);

  // const paginatedProjects = applyPagination(filteredProjects, page, limit);
  //
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [deleteID, setDeleteID] = useState(null);
  const handleConfirmDelete = (id) => {
    setOpenConfirmDelete(true);
    setDeleteID(id);
  };
  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setDeleteID(null);
  };
  const handleDeleteCompleted = () => {
    setOpenConfirmDelete(false);
    window.makeRequest("DEL", `/api/Predmet?ID=${deleteID}`, false, true, (callback) => {
      props.reload();
      enqueueSnackbar('Предметот е успешно избришан', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
      setDeleteID(null);
    }, (err) => {
      console.log('err', err);
    });


  };
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - props.projects.length) : 0;

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <Card sx={{ p: 1, mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} display={'flex'}>
              <Grid item p={1} xs={6}>
                <TextField
                  sx={{ m: 0 }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchTwoToneIcon />
                      </InputAdornment>
                    )
                  }}
                  onChange={handleQueryChange}
                  placeholder={'Пребарувај по име на предмет...'}
                  value={query}
                  fullWidth
                  variant="outlined"
                />
              </Grid>
              {props.type !=='client' && <Grid item p={1} xs={3}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={(e,v) => handleClientChange(v)}
                  options={props.clients}
                  getOptionLabel={option => option.Naziv}
                  renderInput={(params) => <TextField {...params} fullWidth label="Клиенти" />}
                />
              </Grid>}
              <Grid item p={1} xs={3}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel>Статус</InputLabel>
                  <Select
                    value={filters.status || 'all'}
                    onChange={handleStatusChange}
                    label={'Прикажи'}
                  >
                    {statusOptions.map((statusOption) => (
                      <MenuItem key={statusOption.id} value={statusOption.id}>
                        {statusOption.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={'medium'}
          >
            <EnhancedTableHead

              order={order}
              type={props.type}
              filters={filters}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={props.projects.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(filteredProjects, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((project, index) => {
                  return (
                    <TableRow
                      hover
                      tabIndex={-1}
                      key={project.ID}
                    >
                      {filters.status === 'Завршен' && <TableCell align="center">
                        <Typography noWrap>
                          <IconButton color="success">
                            {project.klauzuli && <CheckCircleIcon fontSize="small" />}
                          </IconButton>
                        </Typography>
                      </TableCell>}
                      <TableCell>
                        <Box display="flex" justifyContent="flex-start">
                          {project.clients.length > 0 && (
                            <AvatarGroup max={4}>
                              {project.clients.map((member) => (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  key={member.ID}
                                  title={member.Naziv}
                                >
                                  {project.clients.length === 1 ?
                                    <Typography
                                      key={member.ID}>{member.Naziv}</Typography>
                                    : <Avatar
                                      {...stringAvatar(member.Naziv)}
                                      sx={{ width: 30, height: 30 }}
                                      key={member.ID}
                                    />}
                                </Tooltip>
                              ))}
                            </AvatarGroup>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="flex-start">
                          {project.contacts.length > 0 && (
                            <AvatarGroup max={4}>
                              {project.contacts.map((member) => (
                                <Tooltip
                                  arrow
                                  placement="top"
                                  key={member.ID}
                                  title={member.naziv_sprotivna_stranka}
                                >
                                  {project.contacts.length === 1 ?
                                    <Typography
                                      key={member.ID}>{member.naziv_sprotivna_stranka}</Typography>
                                    : <Avatar
                                      {...stringAvatar(member.naziv_sprotivna_stranka)}
                                      sx={{ width: 30, height: 30 }}
                                      key={member.ID}
                                    />}
                                </Tooltip>
                              ))}
                            </AvatarGroup>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                        {minimizeWindow ? <Tooltip arrow placement="top" key={project.sud} title={project.sud} ><Typography noWrap variant="h5">
                            {project.sud}
                          </Typography></Tooltip> :
                          <Typography noWrap variant="h5">
                            {project.sud}
                          </Typography>}
                      </TableCell>
                      <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                        {minimizeWindow ? <Tooltip arrow placement="top" key={project.broj_predmet} title={project.broj_predmet} ><Typography noWrap variant="h5">
                            {project.broj_predmet}
                          </Typography></Tooltip> :
                          <Typography noWrap variant="h5">
                            {project.broj_predmet}
                          </Typography>}
                      </TableCell>
                      <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                        {minimizeWindow ? <Tooltip arrow placement="top" key={project.osnov} title={project.osnov} ><Typography noWrap variant="h5">
                            {project.osnov}
                          </Typography></Tooltip> :
                          <Typography noWrap variant="h5">
                            {project.osnov}
                          </Typography>}
                      </TableCell>
                      {props.type !== 'client' &&<TableCell>
                        <Typography noWrap variant="h5">
                          {numeral(project.vrednost).format(`0,0`)}
                        </Typography>
                      </TableCell>}
                      <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                        {minimizeWindow ? <Tooltip arrow placement="top" key={project.faza_predmet} title={project.faza_predmet} ><Typography noWrap variant="h5">
                            {project.faza_predmet}
                          </Typography></Tooltip> :
                          <Typography noWrap variant="h5">
                            {project.faza_predmet}
                          </Typography>}
                      </TableCell>
                      <TableCell style={{maxWidth: minimizeWindow ? '20px' : ''}}>
                        {minimizeWindow ? <Tooltip arrow placement="top" key={project.status} title={project.status} ><Typography noWrap variant="h5">
                            {project.status}
                          </Typography></Tooltip> :
                          <Typography noWrap variant="h5">
                            {project.status}
                          </Typography>}
                      </TableCell>
                      <TableCell>
                        <Box display="flex" justifyContent="flex-start">
                          {project.members.length > 0 && (
                            <AvatarGroup variant={'circular'} max={4}>
                              {project.members.map((member) => (
                                member.uloga !== 'client' &&<Tooltip
                                  arrow
                                  placement="top"
                                  key={member.ID}
                                  title={member.first_name}
                                >
                                  {project.members.length === 1 ?
                                    <Typography
                                      key={member.ID}>{member.first_name}</Typography>
                                    : <Avatar
                                      {...stringAvatar(member.first_name)}
                                      sx={{ width: 30, height: 30 }}
                                      key={member.ID}
                                    />}
                                </Tooltip>
                              ))}
                            </AvatarGroup>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell align="center">
                        <Typography noWrap>
                          <Tooltip title={'Преглед'} component={RouterLink}
                                   to={'/predmet/' + project.ID} arrow>
                            <IconButton color="primary">
                              <LaunchTwoToneIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          {(project.user_write === true && props.type !=='client' ) && <Tooltip title={'Избриши'} arrow>
                            <IconButton
                              onClick={() => handleConfirmDelete(project.ID)}
                              color="primary"
                            >
                              <DeleteTwoToneIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={props.projects.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ pt: 4, px: 6 }} variant="h1">
            {'Дали сакате да го избришете предметот'}?
          </Typography>

          <Typography
            align="center"
            sx={{ pt: 2, pb: 4, px: 6 }}
            fontWeight="normal"
            color="text.secondary"
            variant="h3"
          >
            {"Нема можност за повторно враќање"}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {'Затвори'}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {'Избриши'}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
    </Box>
  );
}
const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);
const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};
      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);
const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};
     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

function stringAvatar(name) {
  if(name && name.length !== 1) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}`,
    };
  } else {
    return name;
  }

}
function stringToColor(string) {
  let hash = 0;
  let i;
  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
function MyComponent(e) {
  return <div dangerouslySetInnerHTML={createMarkup(e)} />;
}

function createMarkup(e) {
  return {__html: e};
}
