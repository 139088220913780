import React from 'react';

import { GreyGooseTheme } from './schemes/GreyGooseTheme';


export function themeCreator(theme) {
  return themeMap[theme];
}

const themeMap = {
  GreyGooseTheme,
};
