import React from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  Zoom,
  Typography,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { withSnackbar } from 'notistack';
import CreateDocument from './CreateDocument';

class PageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: false,
    }

  }

  handleCreateUserOpen = () => {
    this.setState({open :true});
  };

  handleCreateUserClose = (success) => {
    this.setState({open :false}, this.props.renderDocuments());
    if(success === true) {
      this.props.enqueueSnackbar('Документот е успешно креиран', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
    }
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value,})
  }


  handleCreateUserSuccess = () => {
    this.props.enqueueSnackbar('Документот е успешно креиран', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });

  };


  render() {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
          </Grid>
          <Grid item>
          <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleCreateUserOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {'Документ'}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleCreateUserClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Нов документ'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Потполнете ги празнито поле додадете нов документ'
              }
            </Typography>
          </DialogTitle>
                <CreateDocument   handleCreateUserClose={this.handleCreateUserClose} userID={this.props.userID} clientID={this.props.clientID}  />
        </Dialog>
      </>
    );
  }
  
}

export default withSnackbar(PageHeader);
