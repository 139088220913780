import React from 'react';
import {
  Grid,
  TextField,
  Typography,
  Alert,
} from '@mui/material';
import numeral from "numeral";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import Label from "../../../../../components/Label";
import DateTimePicker from '../../../components/controls/DateTimePicker/DateTimePicker'

import BillingButtonPercentage from '../../../components/billing/BillingButtonPercentage'

class Billing extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      dejstvie_datum: new Date(),
      vreme_dejstvie: new Date(),
      osnoven_nadomestok: 0,
      prevzemeno_dejstvie: '',
      zgolemuvanje_ucestvo: 0,
      zgolemuvanje_otsustvo: 0,
      zgolemuvanje_praven_lek: 0,
      zgolemuvanje_patni_trosoci: 0,
      zgolemuvanje_pausal: 0,
      zgolemuvanje_saatnina: 0,
      zgolemuvanje_ucesto_display: 0,
      osnoven_nadomestok_display: 0,
      datum_prevezemanje: new Date(),
      total: null,
    }

  }

  componentDidMount() {
    if(!this.props.billing) {
      if(this.props.trosok) {
        this.returnAdvokatskaTarfia(this.props.trosok);
      }else {
        this.setState({zgolemuvanje_pausal: parseInt(this.state.zgolemuvanje_saatnina)+parseInt(this.state.zgolemuvanje_pausal)+parseInt(this.state.zgolemuvanje_ucestvo)})
      }
    }
    if(this.props.billing) {
      let billing = this.props.billing;
      this.setState({
        osnoven_nadomestok: billing.osnoven_nadomestok,
        prevzemeno_dejstvie: billing.prevzemeno_dejstvie,
        zgolemuvanje_ucestvo: billing.zgolemuvanje_ucestvo,
        zgolemuvanje_praven_lek: billing.zgolemuvanje_praven_lek,
        zgolemuvanje_otsustvo: billing.zgolemuvanje_otsustvo,
        zgolemuvanje_patni_trosoci: billing.zgolemuvanje_patni_trosoci,
        zgolemuvanje_pausal: billing.zgolemuvanje_pausal,
        zgolemuvanje_saatnina: billing.zgolemuvanje_saatnina,
        datum_prevezemanje: billing.datum_prevezemanje,
        vkupno: billing.vkupno,
      }, () => this.returnTotal())
    }
  }
  returnAdvokatskaTarfia = (value) => {
    let val = parseInt(value);
    let returnValue = null;
    switch(true) {
      case val > 0 && val <= 10000:
        returnValue = 1000;
        break;
      case val > 10001 && val <= 50000:
        returnValue = 3000;
        break;
      case val > 50001 && val <= 100000:
        returnValue = 4000;
        break;
      case val > 100001 && val <= 300000:
        returnValue = 5000;
        break;
      case val > 300001 && val <= 600000:
        returnValue = 6000;
        break;
      case val > 600001 && val <= 1000000:
        returnValue = 7000;
        break;
      case val > 1000001 && val <= 2500000:
        returnValue = 9000;
        break;
      case val > 2500001 && val <= 5000000:
        returnValue = 12000;
        break;
      case val > 5000001:
        returnValue = 18000;
        break;
    }
    let saatnina = (20 / 100) * returnValue;
    let pausalCalculate = returnValue + saatnina;
    let pausal = (30 / 100) * pausalCalculate;
    this.setState({osnoven_nadomestok: returnValue, zgolemuvanje_saatnina:  saatnina, zgolemuvanje_pausal: pausal}, () => this.returnTotal())
  }
  handleChangeDejstive = (e) => {
    this.setState({prevzemeno_dejstvie: e.target.value}, () => this.returnTotal());
  }
  handleChangeOsnovenNadomestok = (e) => {
    if(e.target.value) {
      let removed = e.target.value.replace(/\,/g,'');
      let total = parseInt(removed) + parseInt(this.state.zgolemuvanje_ucestvo);
      let saatnina = (20 / 100) * total;
      let pausalCalculate = total + saatnina;
      let pausal = (30 / 100) * pausalCalculate;
      this.setState({[e.target.name]: parseInt(removed), zgolemuvanje_saatnina: parseInt(saatnina), zgolemuvanje_pausal: parseInt(pausal)}, () => this.returnTotal())

    } else {
      this.setState({
        [e.target.name]: null,
        zgolemuvanje_saatnina: 0,
        zgolemuvanje_pausal: 0,
      }, () => this.returnTotal())
    }
  }
  returnTotal = (val) => {
    let nadomestok = this.state.osnoven_nadomestok && parseInt(this.state.osnoven_nadomestok);
    let saatnina = null;
    let pausal = null;
    let praven_lek = null;
    let ucestvo = null;
    let otsustvo = null;
    ucestvo = this.state.zgolemuvanje_ucestvo && parseInt(this.state.zgolemuvanje_ucestvo);
    saatnina = this.state.zgolemuvanje_saatnina && parseInt(this.state.zgolemuvanje_saatnina);
    pausal = this.state.zgolemuvanje_pausal && parseInt(this.state.zgolemuvanje_pausal);
    praven_lek = this.state.zgolemuvanje_praven_lek && parseInt(this.state.zgolemuvanje_praven_lek);
    otsustvo = this.state.zgolemuvanje_otsustvo && parseInt(this.state.zgolemuvanje_otsustvo);

    let total = null;
    total = nadomestok +
      (saatnina ? saatnina : 0) +
      (pausal ? pausal : 0) +
      ( praven_lek ? praven_lek : 0) +
      (ucestvo ? ucestvo : 0) +
      (otsustvo ? otsustvo : 0);

    this.setState({total: total});

    if(val) {
      return total;
    }

  }
  handleChangeZgolemuvanjeUcesto = (e) => {
    if(e.target.value) {
      let removed = e.target.value.replace(/\,/g,'');
      let total = parseInt(this.state.osnoven_nadomestok) + parseInt(removed);
      let saatnina = (20 / 100) * total;
      let totalPausal = total + parseInt(saatnina);
      let pausal = (30 / 100) * totalPausal;
      this.setState({[e.target.name]: parseInt(removed), zgolemuvanje_saatnina:saatnina, zgolemuvanje_pausal: pausal}, () => this.returnTotal())
    }else {
      let total = parseInt(this.state.osnoven_nadomestok);
      let saatnina = (20 / 100) * total;
      let totalPausal = total + parseInt(saatnina);
      let pausal = (30 / 100) * totalPausal;
      this.setState({[e.target.name]: 0, zgolemuvanje_saatnina:saatnina, zgolemuvanje_pausal: pausal}, () => this.returnTotal())
    }
  }
  handleChange = (e,val) => {
    if(e.target.value) {
      let removed = e.target.value.replace(/\,/g,'');
      this.setState({[e.target.name]: parseInt(removed)}, () => this.returnTotal())
    } else {
      this.setState({
        [e.target.name]: null
      }, () => this.returnTotal())
    }
  }
  handleSaveBilling = () => {
    if(this.props.billing){
      window.makeRequest("PUT", `/api/billing?ID=${this.props.billing.ID}`, {
        osnoven_nadomestok: this.state.osnoven_nadomestok,
        prevzemeno_dejstvie: this.state.prevzemeno_dejstvie,
        zgolemuvanje_ucestvo: this.state.zgolemuvanje_ucestvo ? this.state.zgolemuvanje_ucestvo : 0,
        zgolemuvanje_saatnina: this.state.zgolemuvanje_saatnina ? this.state.zgolemuvanje_saatnina : 0,
        zgolemuvanje_praven_lek: this.state.zgolemuvanje_praven_lek ? this.state.zgolemuvanje_praven_lek : 0,
        zgolemuvanje_otsustvo: this.state.zgolemuvanje_otsustvo ? this.state.zgolemuvanje_otsustvo : 0,
        zgolemuvanje_patni_trosoci: this.state.zgolemuvanje_patni_trosoci ? this.state.zgolemuvanje_patni_trosoci : 0,
        zgolemuvanje_pausal: this.state.zgolemuvanje_pausal ? this.state.zgolemuvanje_pausal : 0,
        datum_prevezemanje: this.state.datum_prevezemanje,
        vkupno: this.state.total,
        updated_at: new Date(),
      }, true, (callback) => {
        this.props.handleCreateBilling('updated');
      }, (err) => {
        console.log('err', err);
      });
    } else {
      window.makeRequest("POST", `/api/billing`, {
        // predmetID: this.props.predmetID !== 'null'&&this.props.predmetID,
        obvrskaID: this.props.obvrskaID !== 'null'&&this.props.obvrskaID,
        osnoven_nadomestok: this.state.osnoven_nadomestok,
        prevzemeno_dejstvie: this.state.prevzemeno_dejstvie,
        zgolemuvanje_ucestvo: this.state.zgolemuvanje_ucestvo ? this.state.zgolemuvanje_ucestvo : 0,
        zgolemuvanje_saatnina: this.state.zgolemuvanje_saatnina ? this.state.zgolemuvanje_saatnina : 0,
        zgolemuvanje_praven_lek: this.state.zgolemuvanje_praven_lek ? this.state.zgolemuvanje_praven_lek : 0,
        zgolemuvanje_otsustvo: this.state.zgolemuvanje_otsustvo ? this.state.zgolemuvanje_otsustvo : 0,
        zgolemuvanje_patni_trosoci: this.state.zgolemuvanje_patni_trosoci ? this.state.zgolemuvanje_patni_trosoci : 0,
        zgolemuvanje_pausal: this.state.zgolemuvanje_pausal ? this.state.zgolemuvanje_pausal : 0,
        datum_prevezemanje: new Date(new Date(this.state.datum_prevezemanje).setHours(new Date(this.state.datum_prevezemanje).getHours() + 1)),
        vkupno: this.state.total,
        created_at: new Date(new Date(new Date()).setHours(new Date().getHours() + 1)),
      }, true, (callback) => {
        if(this.props.predmetID !== null) {
          window.makeRequest("PUT", `/api/billing?ID=${callback.body.recordset[0].id}`, {
            predmetID: this.props.predmetID,
            updated_at: new Date(),
          }, true, (callback) => {
            // this.props.handleCreateBilling('updated');
          }, (err) => {
            console.log('err', err);
          });
        }
        this.props.handleCreateBilling('created');
      }, (err) => {
        console.log('err', err);
      });
    }

  }
  handleChangeDatePrevzemanje = (e) => {this.setState({datum_prevezemanje: e})};
  handleChangeSaatnina = (e) => {
    if(e.target.value) {
      let removed = e.target.value.replace(/\,/g,'');
      let total = parseInt(removed) + parseInt(this.state.zgolemuvanje_ucestvo);
      let pausalCalculate = parseInt(this.state.osnoven_nadomestok) + parseInt(total);
      let pausal = (30 / 100) * pausalCalculate;
      this.setState({[e.target.name]: parseInt(removed), zgolemuvanje_pausal: parseInt(pausal)}, () => this.returnTotal())

    } else {
      this.setState({
        [e.target.name]: null,
        zgolemuvanje_pausal: this.state.osnoven_nadomestok ? (30 / 100) * (parseInt(this.state.osnoven_nadomestok) + parseInt(this.state.zgolemuvanje_ucestvo)) : null,
      }, () => this.returnTotal())
    }
  }
  handleChangePausal = (e) => {
    if(e.target.value) {
      let removed = e.target.value.replace(/\,/g,'');
      this.setState({[e.target.name]: parseInt(removed)}, () => this.returnTotal())
    } else {
      this.setState({
        [e.target.name]: null,
      }, () => this.returnTotal())
    }
  }
  render() {
      return(
        <>
          <Grid item xs={12}  pr={1} textAlign={{ sm: 'right' }}>
            <Box display={'flex'} justifyContent={'space-between'}>
              <Grid item xs={6} display={'flex'} justifyContent={'start'} ml={1}  >
                {this.props.billing && <Label color={'success'}>Креирана наплата за обврска!</Label>}
              </Grid>
              <Grid  item xs={6} >
                <Button
                  onClick={this.handleSaveBilling}
                  variant="outlined" size="small">
                  {'Зачувај'}
                </Button>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} pb={2} p={1} >
            <DateTimePicker
              autoOk
              label={'Датум на превземање...'}
              name='datum_prevezemanje'
              inputFormat = "dd-MM-yyyy"
              value={this.state.datum_prevezemanje}
              onChange={this.handleChangeDatePrevzemanje}
              renderInput={(params) => <TextField fullWidth {...params} />}
            />
          </Grid>
          <Grid item xs={12} md={6} p={1} >
            <BillingButtonPercentage
              label={'Основен надоместок'}
              name="osnoven_nadomestok"
              onChange={this.handleChangeOsnovenNadomestok}
              value={this.state.osnoven_nadomestok !== 0 ? this.state.osnoven_nadomestok : ''}
            />
          </Grid>
          <Grid item xs={12} md={6} p={1} >
            <TextField
              fullWidth
              label={'Превземено дејствие'}
              name="prevzemeno_dejstvie"
              onChange={this.handleChangeDejstive}
              value={this.state.prevzemeno_dejstvie}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6} p={1} >
            <BillingButtonPercentage
              label={'Зголемување на учество'}
              name="zgolemuvanje_ucestvo"
              onChange={this.handleChangeZgolemuvanjeUcesto}
              value={this.state.zgolemuvanje_ucestvo !== 0 ? this.state.zgolemuvanje_ucestvo : ''}
            />
          </Grid>
          <Grid item xs={12} md={6} p={1} >
            <BillingButtonPercentage
              label={'Зголемување саатнина'}
              name="zgolemuvanje_saatnina"
              onChange={this.handleChangeSaatnina}
              value={this.state.zgolemuvanje_saatnina !== 0 ? this.state.zgolemuvanje_saatnina : ''}
            />
          </Grid>
          <Grid item xs={12} md={6} p={1} >
            <BillingButtonPercentage
              label={'Зголемување на паушал'}
              name="zgolemuvanje_pausal"
              onChange={this.handleChangePausal}
              value={this.state.zgolemuvanje_pausal !== 0 ? this.state.zgolemuvanje_pausal : ''}
            />
          </Grid>
          <Grid item xs={12} md={6} p={1} >
            <BillingButtonPercentage
              label={'Зголемување на правен лек'}
              name={'zgolemuvanje_praven_lek'}
              onChange={this.handleChange}
              value={this.state.zgolemuvanje_praven_lek !== 0 ? this.state.zgolemuvanje_praven_lek : ''}
              // onClick={() => this.changePercentage('zgolemuvanje_praven_lek')}
            />
          </Grid>
          <Grid item xs={12} md={6} p={1} >
            <BillingButtonPercentage
              label={'Отсуство од канцеларија'}
              name="zgolemuvanje_otsustvo"
              onChange={this.handleChange}
              value={this.state.zgolemuvanje_otsustvo !== 0 ? this.state.zgolemuvanje_otsustvo : ''}
            />
          </Grid>
          <Grid item xs={12} md={6} p={1} >
            <BillingButtonPercentage
              label={'Патни трошоци'}
              name="zgolemuvanje_patni_trosoci"
              onChange={this.handleChange}
              value={this.state.zgolemuvanje_patni_trosoci !== 0 ? this.state.zgolemuvanje_patni_trosoci : ''}
            />
          </Grid>
          <Grid item xs={12} p={2} >
            <Typography
              gutterBottom
              variant="caption"
              color="text.secondary"
              fontWeight="bold"
            >
              {'Вкупно'}:
            </Typography>
            <Typography variant="h3" fontWeight="bold">
              {this.state.total ? numeral(this.state.total).format(`0,0`)+' ден.' : () => this.returnTotal(true)}
            </Typography>
          </Grid>
        </>
      );
  }
}



function BillingRender (props)  {
  let open = props.open;
  if(open) {
    return <Billing handleCreateBilling={props.handleCreateBilling} billing={props.billing} obvrskaID={props.obvrskaID}  trosok={props.trosok} predmetID={props.predmetID} />
  } else {
    return null;
  }
}

export default BillingRender;
