import { useState, useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';


import {
  Grid,
  useTheme
} from '@mui/material';

import ReturnAddObvrska from './ObvrskaModal';

function AddObvrska(props) {
  const theme = useTheme();
  const [allClients, setAllClients] = useState([]);
  const [allContacts, setAllContacts] = useState([]);


  useEffect(() => {
      renderClients();
      renderContacts();

  }, [props.predmet]);


  const renderClients = () => {
    window.makeRequest("GET", `/api/getClients`, null, true, (callback) => {
      setAllClients(callback.body.sort((a, b) => a.ID - b.ID).reverse());
    }, (err) => {
      console.log('err', err);
    });
  }
  const renderContacts = () => {
    window.makeRequest("GET", `/api/Contacts`, null, true, (callback) => {
      setAllContacts(callback.body.recordset.sort((a, b) =>  a.ID - b.ID).reverse())
    }, (err) => {
      console.log('err', err);
    });
  }

  const handleCreateProjectSuccess = () => {
    props.createObvrska();
  };

  return (
    <>
      <Grid container justifyContent="end" alignItems="center">
        <ReturnAddObvrska reload={props.reload} reloadContacts={renderContacts} reloadPredmet={props.reloadPredmet}  predmet={props.predmet}  allClients={allClients} allContacts={allContacts} success={handleCreateProjectSuccess} theme={theme} />
      </Grid>
    </>
  );
}

export default AddObvrska;
