import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Zoom,
  Typography,
  FormControl,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { withSnackbar } from 'notistack';
import AllClientsAdd from './single/AllClientsAdd';


const banks = [
  { label: 'Стопанска Банка АД Скопје', value: 'stopanska_ad_skopje' },
  { label: 'НЛБ Тутунска Банка АД Скопје', value: 'nlb_tutunska_skopje' },
  { label: 'Комерцијална банка АД Скопје', value: 'komercijalna_skopje' },
  { label: 'Халк Банка АД Скопје', value: 'halk_skopje' },
  { label: 'Шпаркасе Банка Македонија АД Скопје', value: 'sparkase' },
  { label: 'Силк Роуд Банка АД Скопје', value: 'silk_road' },
  { label: 'ТТК Банка АД Скопје', value: 'ttk_skopje' },
  { label: 'Капитал Банка АД Скопје', value: 'kapital_skopje' },
  { label: 'Прокредит Банка АД Скопје', value: 'prokredit' },
  { label: 'Стопанска Банка АД Битола', value: 'stopanska_ad_bitola' },
  { label: 'Народната Банка на Република Северна Македонија', value: 'narodna' },
  { label: 'Универзална Инвестициона Банка АД Скопје', value: 'univerzalna_investiciona' },
  { label: 'Македонска Банка за подршка на развојот АД Скопје', value: 'makedonska_banka_za_poddrska_na_razvojot' },
];

class PageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: false,
        Naziv: "",
        isSubmitting: false,
        embg_embs: '',
        Adresa: '',
        tel_broj: '',
        broj_smetka: '',
        banka: '',
        email: '',
        createNewClient: false,
        addClient: true,
        users: this.props.users,

    }

  }

  componentDidMount() {
    this.setState({users: this.props.users})
  }

  handleCreateUserOpen = () => {
    this.setState({open :true, createNewClient: false, addClient:true});
  };

  handleCreateUserClose = () => {
    this.setState({open :false});
  };

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value,})
  }

  handleInputChangeBank = (e, value) => {
    this.setState({banka: value.props.value})
   
  }


  newClient = () => {
    window.makeRequest("POST", `/api/Clients`, {
      naziv: this.state.Naziv,
      embg_embs: this.state.embg_embs,
      adresa: this.state.Adresa,
      tel_broj: this.state.tel_broj,
      broj_smetka: this.state.broj_smetka,
      banka: this.state.banka,
      email: this.state.email,
      IsPrivate: false,
      status: 'aktiven',
      created_at: new Date(),
    }, true, (callback) => {
      window.makeRequest("POST", `/api/pivotObvrskiClients`, {
        obvrskaID:   this.props.predmetID,
        clientID:   callback.body.recordset[0].id,
        created_at: new Date(),
      }, true, (callback) => {
        this.props.reloadClients();
        this.setState({open: false, isSubmitting: false})
      }, (err) => {
        console.log('err', err);
      });
    }, (err) => {
      console.log('err', err);
    });

    this.props.enqueueSnackbar('Клиентот е успешно креиран', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  }

  handleCreateUserSuccess = () => {
    this.setState({isSubmitting: true});
    setTimeout(() => {
      this.newClient();
    }, 1000)
  };

  handleQueryChange = (event) => {
    this.setState({query: event.target.value})
  };


  render() {
 

    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center" mt={-2}>
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {'Клиенти'}
            </Typography>
          </Grid>
          <Grid item>
            {this.props.predmet.user_write === true && <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleCreateUserOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {'Додај клиент'}
            </Button>}
          </Grid>
        </Grid>
        {this.props.predmet.user_write === true &&<Dialog
          fullWidth
          maxWidth="md"
          open={this.state.open}
          onClose={this.handleCreateUserClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Нов клиент'}
            </Typography>
              <Box component="div" sx={{ display: 'space-between' }}>
                  <Box component="div" sx={{ flexGrow: 1, pt: 0.5 }}  display='flex' justifyContent='flex-start' >
                  <Typography variant="subtitle2">
                    {'Потполнете ги празните полина за да креирате и да додадете нов клиент'}
                  </Typography>
                  </Box>
                  <Box component="div" display='flex' justifyContent='flex-end' >
                    {this.state.addClient && 
                    <Button color="primary" variant='outlined'  onClick={() => this.setState({createNewClient: true, addClient:false})}>
                      {'Креирај'}
                    </Button> }
                  </Box>

              </Box>
          </DialogTitle>
            <DialogContent dividers sx={{ p: 3 }}>
            {this.state.addClient && <AllClientsAdd reloadClients={this.props.reloadClients} predmetID={this.props.predmetID} clients={this.props.users} />}
              {this.state.createNewClient &&
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Grid container spacing={3}>
                  <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={'Назив'}
                        name="Naziv"
                        onChange={this.handleChange}
                        value={this.state.Naziv}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'ЕМБГ/ЕМБС'}
                        name="embg_embs"
                        onChange={this.handleChange}
                        value={this.state.embg_embs}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Адреса'}
                        name="adresa"
                        onChange={this.handleChange}
                        value={this.state.Adresa}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Телефонски број'}
                        name="tel_broj"
                        onChange={this.handleChange}
                        value={this.state.tel_broj}
                        type='text'
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Е-маил адреса'}
                        name="email"
                        onChange={this.handleChange}
                        type="email"
                        value={this.state.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextField
                        fullWidth
                        label={'Број на сметка'}
                        name="broj_smetka"
                        onChange={this.handleChange}
                        value={this.state.broj_smetka}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} >
                    <FormControl  fullWidth >
                    <InputLabel  >Банка</InputLabel>
                      <Select
                        fullWidth
                        label='Банка'
                        onChange={this.handleInputChangeBank}
                        value={this.state.banka && this.state.banka}
                      >
                        {banks.map((bank) => {
                          return<MenuItem key={bank.value}  value={bank.value}>{bank.label}</MenuItem>
                        })}
                        </Select>
                    </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>}
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
           
              <Button color="secondary" onClick={this.handleCreateUserClose}>
                {'Затвори'}
              </Button>
              {this.state.createNewClient &&
              <Button
                type="submit"
                startIcon={
                  this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
                }
                disabled={this.state.isSubmitting}
                onClick={this.handleCreateUserSuccess}
                variant="contained"
              >
                {'Додај'}
              </Button>}
            </DialogActions>
        </Dialog>}
      </>
    );
  }
  
}

export default withSnackbar(PageHeader);
