import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';

const menuItems = [
    //crm medarski
    {
      heading: 'CRM Medarski',
      items: [
        {
          name: 'Предмети',
          icon: AccountTreeTwoToneIcon,
          link: '/predmeti',
        },

      ]
    },
 
];

export default menuItems;
