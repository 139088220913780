import { Box, } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import image from '../../../../components/Logo/icon-256x256.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const LogoSignWrapper = styled(Box)(
  ({ theme }) => `
        width: 52px;
        height: 72px;
        transform: scale(.8);
        transition: ${theme.transitions.create(['transform'])};

        &:hover {
        transform: scale(1);
        }
`
);


function Logo() {
  return (

    <LogoWrapper to="/">
        <LogoSignWrapper>
          <img style={{width: 120, height: 120,marginTop: -20, marginBottom: -20, }} src={image} />
        </LogoSignWrapper>
    </LogoWrapper>

  );
}

export default Logo;
