import React from 'react';
import Footer from 'src/components/Footer';
import { Box, Tabs, Tab, Grid, Tooltip, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { Suspense, lazy } from 'react';
import EditPredmetTab from './EditPredmetTab';
import Contacts from './Contacts';
import Clients from './Clients';
import Obvrski from '../Obvrski';



const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);



const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

class ManagementPredmetView extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'predmet',
      loading: true,
      client: '',
      id: '',
      userID: null,
      members: [],
      openAddObvrska: false,
      predmet: [],
      tabs : [
        { value: 'predmet', label: 'Предмет' },
        this.props.type !== 'client' &&
        { value: 'clients', label: 'Клиенти' },
        this.props.type !== 'client' &&
        { value: 'contacts', label: 'Спротивни странки' },
        this.props.type !== 'client' &&
        { value: 'obvrski', label: 'Обврски' },
      ]
    }
  }

  componentDidMount() {
    this.LoadPredmet();


  }
  LoadPredmet = () => {
    this.getUser();
  }

  getUser = () => {
    window.makeRequest("GET", `/api/Users?ID=${true}`, null, true, (callback) => {
      this.setState({userID: callback.body.recordset[0].ID, role: callback.body.recordset[0].role})
      this.getClients();
      this.getContacts();
      this.getObvrski();
      this.renderPredmet();
    }, (err) => {
      console.log('err', err);
    });
  }

  getObvrski = () => {
    let id = this.props.match.params.id;
    window.makeRequest("GET", `/api/all_obvrski_preview?predmetID=${id}`, null, true, (callback) => {
      if(callback.body !== 'no results') {
        let obvrski = null;
        if(callback.body.length > 0) {
          obvrski = callback.body.sort((a, b) => a - b).reverse();
        }
        this.setState({
          obvrski: obvrski,
        });
      }else {
        this.setState({
          obvrski: [],
        })
      }

    }, (err) => {
      console.log('err', err);
    });
  }


  getLogs = () => {
    let id = this.props.match.params.id;
    window.makeRequest("GET", `/api/logs?predmetID=${id}`, null, true, (callback) => {
      let logs = callback.body.recordset.sort((a, b) => a.ID - b.ID).reverse();
      this.setState({
        logs: logs,
        loading: false
      });
    }, (err) => {
      console.log('err', err);
    });
  }


  renderPredmet = (reloadObvrski) => {
    let id = this.props.match.params.id;
    id && window.makeRequest("GET", `/api/all_predmeti_preview?ID=${id}`, null, true, (callback) => {
      if(callback.body !== 'no results') {
        this.setState({predmet: callback.body[0], members: callback.body[0].members, }, this.getLogs)
      }else {
          this.setState({
            predmet: [],
          })
      }
      if(reloadObvrski === true) {
        this.getObvrski();
      }
    }, (err) => {
      console.log('err', err);
    
    });

  }
  getClients = () => {
    window.makeRequest("GET", `/api/ClientsPredmetView?predmetID=${this.props.match.params.id}`, null, true, (callback) => {
      this.setState({clients: callback.body.recordset}, this.getLogs);
    }, (err) => {
      console.log('err', err);
    });
  }
  getContacts = () => {
    window.makeRequest("GET", `/api/ContactsPredmetView?predmetID=${this.props.match.params.id}`, null, true, (callback) => {
      this.setState({contacts: callback.body.recordset});
    }, (err) => {
      console.log('err', err);
    });
  }


  deleteClient = (delID) => {
    window.makeRequest("DEL", `/api/pivotPredmetClients?predmetID=${this.props.match.params.id}&clientID=${parseInt(delID)}`, null, true, (callback) => {
        this.setState({clients: this.state.clients.filter(function(client) {
          return client.ID !== delID
      })}, this.getLogs);
    }, (err) => {
      console.log('err', err);
    });
  }
  deleteContact = (id) => {
    window.makeRequest("DEL", `/api/pivotPredmetContacts?predmetID=${this.props.match.params.id}&contactID=${parseInt(id)}`, null, true, (callback) => {
      this.setState({contacts: this.state.contacts.filter(function(contact) {
          return contact.ID !== id
        })}, this.getLogs);
    }, (err) => {
      console.log('err', err);
    });
  }
  deleteObvrska = (id) => {
    window.makeRequest("DEL", `/api/Obvrski?ID=${id}`, null, true, (callback) => {
      this.setState({updated: true}, this.getObvrski)
    }, (err) => {
      console.log('err', err);
    });
  }

  updatedState =  () => {
    this.setState({updated: true},() => this.renderPredmet(true));

  }
  handleTabsChange = (event, value) => {
    this.setState({currentTab: value});
  };
  onClose = () => {
    this.setState({openAddObvrska: false})
  }

  
  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return (
        <>
          <Box sx={{ mt: 3 }}>
            <Grid
              sx={{ px: 2 }}
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12} md={11} display={'flex'}>
                <TabsWrapper
                  onChange={this.handleTabsChange}
                  value={this.state.currentTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {this.state.tabs.map((tab, index) => (
                    <Tab key={tab.value+'ind'+index} label={tab.label} value={tab.value} />
                  ))}

                </TabsWrapper>


              </Grid>


              <Grid item xs={12} md={1}>
                <Box sx={{ display: 'flex' }}>
                  <Tooltip arrow placement="top" title={'Назад'}>
                    <IconButton
                      component={RouterLink}
                      to={this.state.role === 'client' ? '/' : '/predmeti'}
                      // onClick={handleBack}
                      color="primary"
                      // sx={{ p: 0, mr: 2 }}
                    >
                      <ArrowBackTwoToneIcon />
                    </IconButton>
                  </Tooltip>

                </Box>


              </Grid>

              <Grid item xs={12}>


                {this.state.currentTab === 'predmet' && <EditPredmetTab reloadPredmet={this.renderPredmet}  reloadObvrski={this.getObvrski} members={this.state.members} renderLogs={this.getLogs} logs={this.state.logs} type={this.props.type} clients={this.state.clients}  contacts={this.state.contacts} userID={this.state.userID} id={this.props.match.params.id} predmet={this.state.predmet} updatedState={this.updatedState} client={this.state.client} />}
                {this.state.currentTab === 'clients' && <Clients  clients={this.state.clients}  deleteClient={this.deleteClient} renderClients={this.getClients} predmet={this.state.predmet} user={this.state.client} />}
                {this.state.currentTab === 'contacts' && <Contacts  contacts={this.state.contacts} deleteContact={this.deleteContact} renderContacts={this.getContacts} predmet={this.state.predmet} user={this.state.client} />}
                {this.state.currentTab === 'obvrski' && <Obvrski deleteObvrska={this.deleteObvrska} reload={() => this.getObvrski()}  obvrski={this.state.obvrski} user={this.state.client} />}

              </Grid>
            </Grid>
          </Box>
          <Footer />
        </>
      );
    }

  }
  
}

export default  withRouter(ManagementPredmetView);
