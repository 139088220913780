import React from 'react';
import {
  Route,
  withRouter,
  Switch,
} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import CRMLogin from './content/pages/Auth/Login/CRMLogin';
import AccentHeaderLayout from './layouts/AccentHeaderLayout';
import Status404 from './content/pages/Status/Status404';
import StatusComingSoon from './content/pages/Status/ComingSoon';
import request from "superagent";
import AutoLogout from './AutoLogout'


import AdminUsers from './content/pages/Admin/Users';
import SingleObvrska from './content/pages/Obvrski/single';
import Predmeti from './content/pages/Predmeti';
import Obvrski from './content/pages/Obvrski';
import SingleUser from './content/pages/Admin/Users/single';
import Clients from './content/pages/Clients';
import SingleClient from './content/pages/Clients/single';
import SinglePredmet from './content/pages/Predmeti/single';
import Naplata from './content/pages/Naplata';



class App extends React.Component {

  constructor(props) {
    super(props);
    window.makeRequest = this.makeRequest;
    window.login = this.login;
    window.logout = this.logout;
    this.state = {
        loading: false,
        authenticated: !!window.localStorage.getItem("token"),
        userType: null,
    };
    if(this.state.authenticated){
       //check here
        this.state.userType = window.localStorage.getItem("userType");
    }
  }

  componentDidMount() {
      this.setState({loading: false});
  }
  makeRequest = (type, url, data, includeCredentials, success, error) => {
    let req = null;
    switch (type) {
        case "GET":
            req = request.get(url);
            break;
        case "POST":
            req = request.post(url);
            break;
        case "PUT":
            req = request.put(url);
            break;
        case "DEL":
            req = request.del(url);
            break;
        default:
    }
    if (includeCredentials) {
        req = req.withCredentials();
    }
    if (data) {
        req = req.send(data);
    }
    req = req.set('Access-Control-Allow-Origin', window.location.protocol + '//' + window.location.host);
    if (includeCredentials) {
        req = req.set('authorization', 'Bearer ' + window.localStorage.getItem('token'));
    }
    req.type('application/json').then(success, error);
  };


  logout = () => {
    if(this.state.authenticated === true) {
      window.makeRequest("POST", `/api/logs_users`, {logout: true}, true, (callback) => {
        window.localStorage.removeItem('userType');
        window.localStorage.removeItem('token');
        this.setState({authenticated: false,  userType: null,}, () => this.pushToDefaultUrl());
      }, (err) => {
        console.log('err', err);
      });
    } else {
      window.localStorage.removeItem('userType');
      window.localStorage.removeItem('token');
      this.setState({authenticated: false,  userType: null,}, () => this.pushToDefaultUrl());
    }
  };

  pushToDefaultUrl = () => {
    window.location.href = '/';
    // window.location.href''
      this.props.history.push('/')
  }
  login = (loginObj) => {
    if(typeof loginObj === 'object' && loginObj !== null) {
      localStorage.setItem('userType', loginObj.type);
      localStorage.setItem('token', loginObj.access_token);
      this.setState({authenticated: true, userType: loginObj.type, id: loginObj.ID});
      switch(loginObj.type) {
        case 'admin':
          this.props.history.push('/obvrski');
        break;
        case 'user':
          this.props.history.push('/obvrski');
          break;
        case 'client':
          this.props.history.push('/predmeti');
          break;
        case 'smetkovodstvo':
          this.props.history.push('/naplata');
          break;
        default:
          this.props.history.push('/');
      }
    }
  };
  renderAdmin = () => {
    return (
          <Switch>
                <Route exact path="/">
                  <AccentHeaderLayout type={this.state.userType}  component={<AdminUsers />}/>
                </Route>
                <Route exact path="/users">
                  <AccentHeaderLayout type={this.state.userType}  component={<AdminUsers />}/>
                </Route>
                <Route exact path="/clients">
                  <AccentHeaderLayout type={this.state.userType}  component={<Clients />}  />
                </Route>
                <Route exact path="/clients/:id">
                  <AccentHeaderLayout type={this.state.userType}  component={<SingleClient type={this.state.userType} />}  />
                </Route>
                <Route exact  path="/user/:id">
                  <AccentHeaderLayout type={this.state.userType}  component={<SingleUser />}  />
                </Route>
                <Route  path="/predmeti">
                  <AccentHeaderLayout type={this.state.userType}  component={<Predmeti type={this.state.userType} />}  />
                </Route>
                <Route  path="/predmet/:id">
                  <AccentHeaderLayout type={this.state.userType}  component={<SinglePredmet type={this.state.userType} />}  />
                </Route>
                <Route  path="/obvrski">
                  <AccentHeaderLayout type={this.state.userType} component={<Obvrski type={this.state.userType} />}  />
                </Route>
                <Route  path="/obvrska/:id">
                  <AccentHeaderLayout type={this.state.userType}  component={<SingleObvrska type={this.state.userType} />}  />
                </Route>
                <Route  path="/naplata">
                  <AccentHeaderLayout type={this.state.userType}  component={<Naplata type={this.state.userType} />}  />
                </Route>
          </Switch>
      );
  };
  renderUser = () => {
      return (
          <Switch>
                <Route exact  path="/">
                  <AccentHeaderLayout type={this.state.userType}  component={<Predmeti />}  />
                </Route>
                  <Route exact path="/clients">
                <AccentHeaderLayout type={this.state.userType}  component={<Clients />}  />
                </Route>
                <Route exact path="/clients/:id">
                  <AccentHeaderLayout type={this.state.userType}  component={<SingleClient />}  />
                </Route>
                <Route  path="/predmeti">
                  <AccentHeaderLayout type={this.state.userType}  component={<Predmeti type={this.state.userType} />}  />
                </Route>
                <Route  path="/predmet/:id">
                  <AccentHeaderLayout type={this.state.userType}  component={<SinglePredmet type={this.state.userType} />}  />
                </Route>
                <Route  path="/obvrski">
                  <AccentHeaderLayout type={this.state.userType} component={<Obvrski type={this.state.userType} />}  />
                </Route>
                <Route  path="/obvrska/:id">
                  <AccentHeaderLayout type={this.state.userType}  component={<SingleObvrska type={this.state.userType}  />}  />
                </Route>
          </Switch>
      );
  };
  renderClient = () => {
    return (
        <Switch>
                <Route exact path="/">
                  <AccentHeaderLayout type={this.state.userType}  component={<Predmeti type={this.state.userType} />}  />
                </Route>
                <Route exact path="/predmeti">
                  <AccentHeaderLayout type={this.state.userType}  component={<Predmeti type={this.state.userType} />}  />
                </Route>
                <Route  path="/predmet/:id">
                  <AccentHeaderLayout type={this.state.userType}  component={<SinglePredmet type={this.state.userType} />}  />
                </Route>
        </Switch>
    );
  };
  renderSmetkovodstvo = () => {
    return (
      <Switch>
        <Route  path="/">
          <AccentHeaderLayout type={this.state.userType}  component={<Naplata type={this.state.userType}  />}  />
        </Route>
        <Route  path="/naplata">
          <AccentHeaderLayout type={this.state.userType}  component={<Naplata  type={this.state.userType} />}  />
        </Route>
      </Switch>
    );
  };


  renderAuthenticated = () => {
    switch(this.state.userType){
          case 'admin':
              return this.renderAdmin();
          case 'user':
              return this.renderUser();
          case 'client':
              return this.renderClient();
          case 'smetkovodstvo':
            return this.renderSmetkovodstvo();
          default:

      }

      return <div>Something went wrong</div>;
  }
  renderNotAuth = () => {
      return (
          <Switch>
                  <Route exact path="/">
                    <CRMLogin />
                  </Route>
                  <Route exact path="/blocked">
                      <StatusComingSoon />
                  </Route>
                  <Route  path="*">
                      <Status404 />
                  </Route>
          </Switch>
      );
  }
  render() {
    return (
      <ThemeProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
          > 
            <CssBaseline />
            <AutoLogout logged={this.state.authenticated} logout={this.logout} />
            {this.state.authenticated ?
            this.renderAuthenticated()
            : this.renderNotAuth()
            }
           </SnackbarProvider>
        </LocalizationProvider>
      </ThemeProvider>
    );
  }

}
export default withRouter(App);
