import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import { Box, Tabs, Tab, Grid, Tooltip, IconButton } from '@mui/material';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import SuspenseLoader from 'src/components/SuspenseLoader';


import EditClientTab from './EditClientTab';
import DocumentsTab from './DocumentsTab';
import PredmetsTab from './PredmetsTab';


import { styled } from '@mui/material/styles';
import {Link as RouterLink,
  withRouter,
} from "react-router-dom";


const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);


class ManagementUsersView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'client',
      loading: true,
      client: '',
      tabs : [
        { value: 'client', label: 'Клиент' },
        { value: 'documents', label: 'Документи' },
        { value: 'predmet', label: 'Предмети' },
      ]
    }
  }

  componentDidMount() {
    this.renderClient();
  }

  renderClient = () => {
    let id = this.props.match.params.id;
    id && window.makeRequest("GET", `/api/Clients?ID=${id}`, null, true, (callback) => {
      this.setState({client: callback.body.recordset[0]})
    }, (err) => {
      console.log('err', err);
    });

    window.makeRequest("GET", `/api/Users?ID=${true}`, null, true, (callback) => {
      this.setState({user: callback.body.recordset[0], loading: false})
    }, (err) => {
      console.log('err', err);
    });

  }

  updatedState =  () => {
    this.setState({updated: true,loading: true, client: ''},this.renderClient);
  }

  handleTabsChange = (event, value) => {
    this.setState({currentTab: value});
  };

  
  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return (
        <>
          <Helmet>
            <title>Клиент</title>
          </Helmet>
          <Box sx={{ mt: 3 }}>
            <Grid
              sx={{ px: 4 }}
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12} md={12}>
              </Grid>
              <Grid item xs={12} md={11}>
                <TabsWrapper
                  onChange={this.handleTabsChange}
                  value={this.state.currentTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {this.state.tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </TabsWrapper>
              </Grid>

              <Grid item xs={12} md={1}>
                <Box sx={{ display: 'flex' }}>
                  <Tooltip arrow placement="top" title={'Назад'}>
                    <IconButton
                      component={RouterLink}
                      to={'/clients'}
                      color="primary"
                    >
                      <ArrowBackTwoToneIcon />
                    </IconButton>
                  </Tooltip>

                </Box>


              </Grid>

              <Grid item xs={12}>

                {this.state.currentTab === 'client' && <EditClientTab type={this.props.type} renderClient={this.renderClient} updatedState={this.updatedState} client={this.state.client} />}
                {this.state.currentTab === 'documents' && <DocumentsTab clientID={this.props.match.params.id} user={this.state.user} />}
                {this.state.currentTab === 'predmet' && <PredmetsTab clientID={this.props.match.params.id} user={this.state.user} />}



              </Grid>
            </Grid>
          </Box>
          <Footer />
        </>
      );
    }

  }
  
}

export default  withRouter(ManagementUsersView);
