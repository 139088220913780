import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';

import {
  Avatar,
  Box,
  Card,
  Slide,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Button,
  Typography,
  Dialog,
  Zoom
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { styled } from '@mui/material/styles';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useSnackbar } from 'notistack';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarSuccess = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.success.lighter};
      color: ${theme.colors.success.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const applyFilters = (clients, query, filters) => {
  return clients.filter((client) => {
    let matches = true;

    if (query) {
      const properties = ['Naziv', 'embg_embs', 'banka'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (client[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && client[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (clients, page, limit) => {
  return clients.slice(page * limit, page * limit + limit);
};

const AllClientsAdd = ({ clients,predmetID, ...props }) => {
  const [selectedItems, setSelectedClients] = useState([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({});
 

  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredClients = applyFilters(clients, query, filters);
  const paginatedClients = applyPagination(filteredClients, page, limit);
  const selectedBulkActions = selectedItems.length > 0;
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [addClient, setAddClient] = useState("");

  const handleConfirmDelete = (cli) => {
    setAddClient(cli);
    setOpenConfirmDelete(true);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = () => {
    window.makeRequest("GET", `/api/pivotObvrskiClients?obvrskaID=${predmetID}&&clientID=${addClient.ID}`, null, true, (callback) => {
      if(callback.body.recordset.length === 0) {
        window.makeRequest("POST", `/api/pivotObvrskiClients`, {
          obvrskaID:   predmetID,
          clientID:   addClient.ID,
          created_at: new Date(),
        }, true, (callback) => {
        }, (err) => {
          console.log('err', err);
        });

        setOpenConfirmDelete(false);
        props.reloadClients();
        enqueueSnackbar(t('Клиентот е успешно додаден во вашата листа'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          TransitionComponent: Zoom
        });
      } else {
        setOpenConfirmDelete(false);
        enqueueSnackbar(t('Клиентот е веќе додаден во вашата листа'), {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          TransitionComponent: Zoom
        });
      }
    }, (err) => {
      console.log('err', err);
    });

  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
        pb={3}
      >
      </Box>
      {clients.length > 0 && (
        <Card>
          <Box p={2}>
            {!selectedBulkActions && (
              <TextField
                sx={{ m: 0 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchTwoToneIcon />
                    </InputAdornment>
                  )
                }}
                onChange={handleQueryChange}
                placeholder={t('Пребарувај по Назив, ЕМБГ/ЕМБС ,Банка или Адреса...')}
                value={query}
                size="small"
                fullWidth
                margin="normal"
                variant="outlined"
              />
            )}
          </Box>

          <Divider />

          {paginatedClients.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("Не се пронајдени резултати од вашето пребарување")}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('Назив')}</TableCell>
                      <TableCell>{t('ЕМБГ/ЕМБС')}</TableCell>
                      <TableCell>{t('Адреса')}</TableCell>
                      <TableCell>{t('Телефон')}</TableCell>
                      <TableCell>{t('Е-маил')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedClients.map((client) => {
                      return (
                        <TableRow hover key={client.ID} >
                          <TableCell>
                            <Typography variant="h5">
                              {client.Naziv}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{client.embg_embs}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{client.Adresa}</Typography>
                          </TableCell> 
                          <TableCell>
                            <Typography>{client.tel_broj}</Typography>
                          </TableCell> 
                          <TableCell>
                            <Typography>{client.email}</Typography>
                          </TableCell> 
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={t('Додај')} arrow>
                                <IconButton
                                  onClick={()=>handleConfirmDelete(client)}
                                  color="primary"
                                >
                                  <AddCircleOutlineIcon color='success' fontSize="medium" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredClients.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
    
      {clients.length === 0 && (
        <Card sx={{ textAlign: 'center', p: 3 }}>
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{ my: 5 }}
            gutterBottom
          >
            {t(
              'Нема прикажани клиенти'
            )}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarSuccess>
            <AddIcon color='success' />
          </AvatarSuccess>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {t('Дали сакате клиентот да биде додаден во вашата листа?')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {t('Затвори')}
            </Button>
            <Button
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {t('Додај')}
            </Button>
          </Box>
        </Box>
      </DialogWrapper>
    </>
  );
};

AllClientsAdd.propTypes = {
  clients: PropTypes.array.isRequired
};

AllClientsAdd.defaultProps = {
  clients: []
};

export default AllClientsAdd;
