import React from 'react';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Results from './Results';

class Clients extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.renderContactsPredmet();
  }

  renderContactsPredmet = () => {
    window.makeRequest("GET", `/api/Contacts`, null, true, (callback) => {
      this.setState({clients2: callback.body.recordset});
    }, (err) => {
      console.log('err', err);
    });

  }

  DeleteClientsPredmet = (id) => {
    this.props.deleteContact(id);
  }




  render() {
    
    return (
      <>
        <PageTitleWrapper >
          <PageHeader reloadContacts={this.props.renderContacts}  predmet={this.props.predmet}   predmetID={this.props.predmet.ID} clients={this.props.contacts} users={this.state.clients2}  />
        </PageTitleWrapper>
        <Grid
          sx={{ px: 0 }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12} mt={-2}>
            <Results reloadContacts={this.props.renderContacts}  predmet={this.props.predmet}  delete={(e)=> this.DeleteClientsPredmet(e)} clients={this.props.contacts} />
          </Grid>
        </Grid>
      </>
    );
  }
  
}

export default Clients;
