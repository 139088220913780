import {
  alpha,
  Avatar,
  Badge,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  Popover,
  Tooltip,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import CommentTwoToneIcon from '@mui/icons-material/CommentTwoTone';
import { useTranslation } from 'react-i18next';


const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.error.contrastText};
        min-width: 18px; 
        height: 18px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    margin-left: ${theme.spacing(1)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

function HeaderNotifications(props) {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const readNotification = (id) => {
    window.makeRequest("PUT", `/api/notifications?ID=${id}`,{
      seen: true,
      updated_at: new Date(),
    }, true, (callback) => {
      handleClose();
      props.getNotifications();
    }, (err) => {
      console.log('err', err);
    });

  }
  const notificationsAllSeen = () => {
    props.notifications.forEach((notify) => {
      window.makeRequest("PUT", `/api/notifications?ID=${notify.ID}`,{
        seen: true,
        updated_at: new Date(),
      }, true, (callback) => {
        handleClose();
        props.getNotifications();
      }, (err) => {
        console.log('err', err);
      });
    })
  }

  const predmetPreview = (id, type, idnotify) => {
    props.predmetPreview(id, type);
    readNotification(idnotify);

  }

  const { t } = useTranslation();

  return (
    <>
      <Tooltip arrow title={t('Известувања')}>
        <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={props.countNew}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon />
          </NotificationsBadge>
        </IconButtonPrimary>
      </Tooltip>


      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ p: 2 }}  display="flex" justifyContent="space-between">
          <Typography variant="h5">{t('Известувања')}</Typography>
          <Link  href="#" ml={4}   style={{textDecoration: 'none'}} onClick={notificationsAllSeen} variant="caption" sx={{ textTransform: 'none' }}>
            {t('Избриши ги сите прегледани')}
          </Link>
        </Box>
        <Divider  />
        <List sx={{ p: 2 }}
              style={{maxHeight: 350}}
        >
          {props.countNew === 0 ? <p style={{textAlign: 'center'}}>Нема известувања!</p> : props.notifications.map((notify, index) => {
            return<div key={notify.ID}>
              <ListItem
                sx={{ display: { xs: 'block', sm: 'flex' } }}
                button
                selected={notify.seen === false}
              >
                <ListItemAvatar sx={{ mb: { xs: 1, sm: 0 } }} >
                  <Avatar
                    sx={{
                      background: (theme) => theme.palette.success.main
                    }}
                  >
                    <CommentTwoToneIcon />
                  </Avatar>
                </ListItemAvatar>
                <Box flex="1" >
                  <Box
                    display={{ xs: 'block', sm: 'flex' }}
                    justifyContent="space-between"
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>Имате коментар на {notify.predmetID ? 'Предмет' : 'Обврска' }</Typography>

                    {notify.predmetID ?
                      <Link href="#" onClick={() => predmetPreview(notify.predmetID, 'predmet', notify.ID)}
                            variant="caption" sx={{textTransform: 'none', pl: 2}}>
                        {t('Прегледај')}
                      </Link>
                      :
                      <Link href="#" onClick={() => predmetPreview(notify.obvrskaID, 'obvrska', notify.ID)}
                            variant="caption" sx={{textTransform: 'none', pl: 2}}>
                        {t('Прегледај')}
                      </Link>
                    }
                  </Box>

                </Box>
              </ListItem>
              <Divider variant="inset" sx={{ my: 1 }} component="li" />
            </div>
          })}
        </List>
      </Popover>

    </>
  );
}

export default HeaderNotifications;
