import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Divider,
  Tooltip,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone';
import { useSnackbar } from 'notistack';

import SuspenseLoader from 'src/components/SuspenseLoader';
import { Suspense, lazy } from 'react';
import {Zoom} from "@mui/material/index";

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

const PreviewObvrskiClient = Loader(lazy(() => import('./PreviewObvrskiClient')));

const applyFilters = (clients, query, filters) => {
  return clients.filter((client) => {
    let matches = true;
    if (query) {
      const properties = ['Naziv', 'embg_embs','Adresa', 'banka', 'email','broj_smetka','tel_broj'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (client[property] && client[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }
    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && client[key] !== value) {
        matches = false;
      }
    });
    return matches;
  });
};

const applyPagination = (clients, page, limit) => {
  return clients.slice(page * limit, page * limit + limit);
};

const Results = ({ clients,reload,loading,stopLoading, ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({});


  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };


  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredClients = applyFilters(clients, query, filters);
  const paginatedClients = applyPagination(filteredClients, page, limit);

  const [openClientObvrski, setOpenClientObvrski] = useState(false);
  const [clientID, setClientID] = useState(null);
  const [obvrski, setObvrski] = useState([]);


  const closeObvrskiModal = (open, success) => {
    setOpenClientObvrski(false);
    setClientID(null);
    setObvrski([]);
    if(success === true) {
      reload();
    }
  };

  const returnBanka = (e) => {
    let bankLabel = '';
    const banks = [
      { label: 'Стопанска Банка АД Скопје', value: 'stopanska_ad_skopje' },
      { label: 'НЛБ Тутунска Банка АД Скопје', value: 'nlb_tutunska_skopje' },
      { label: 'Комерцијална банка АД Скопје', value: 'komercijalna_skopje' },
      { label: 'Халк Банка АД Скопје', value: 'halk_skopje' },
      { label: 'Шпаркасе Банка Македонија АД Скопје', value: 'sparkase' },
      { label: 'Силк Роуд Банка АД Скопје', value: 'silk_road' },
      { label: 'ТТК Банка АД Скопје', value: 'ttk_skopje' },
      { label: 'Капитал Банка АД Скопје', value: 'kapital_skopje' },
      { label: 'Прокредит Банка АД Скопје', value: 'prokredit' },
      { label: 'Стопанска Банка АД Битола', value: 'stopanska_ad_bitola' },
      { label: 'Народната Банка на Република Северна Македонија', value: 'narodna' },
      { label: 'Универзална Инвестициона Банка АД Скопје', value: 'univerzalna_investiciona' },
      { label: 'Македонска Банка за подршка на развојот АД Скопје', value: 'makedonska_banka_za_poddrska_na_razvojot' },
    ];
    banks.forEach((bank) => {
      if(bank.value === e) {
        bankLabel = bank.label;
      }
    })
    return bankLabel;
  };
  const loadObvrskiClient = (id) => {
    loading();

    window.makeRequest("GET", `/api/obvrskiWithBillingByID?ID=${id}`, null, true, (callback) => {
      if(callback.body && callback.body[0].hasOwnProperty('ID')) {
        let obvrskiLoaded = callback.body.sort((a, b) => a.ID - b.ID).reverse();
        setObvrski(obvrskiLoaded);
        setClientID(id);
        setOpenClientObvrski(true);
      } else {

        enqueueSnackbar('Клиентот нема прикажани наплати', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center'
          },
          TransitionComponent: Zoom
        });
      }

    }, (err) => {
      console.log('err', err);
    });
    stopLoading();


  };

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        flexDirection={{ xs: 'column', sm: 'row' }}
        justifyContent={{ xs: 'center', sm: 'space-between' }}
      >
      </Box>
      {clients.length > 0 && (
        <Card>
          <Box p={2}>
            <TextField
              sx={{ m: 0 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchTwoToneIcon />
                  </InputAdornment>
                )
              }}
              onChange={handleQueryChange}
              placeholder={'Пребарувај по Назив, ЕМБГ/ЕМБС ,Банка или Адреса...'}
              value={query}
              size="small"
              fullWidth
              margin="normal"
              variant="outlined"
            />
          </Box>

          <Divider />

          {paginatedClients.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {"Не се пронајдени резултати од вашето пребарување"}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>{'Назив'}</TableCell>
                      <TableCell>{'ЕМБГ/ЕМБС'}</TableCell>
                      <TableCell>{'Адреса'}</TableCell>
                      <TableCell>{'Телефон'}</TableCell>
                      <TableCell>{'Број сметка'}</TableCell>
                      <TableCell>{'Банка'}</TableCell>
                      <TableCell>{'Е-маил'}</TableCell>
                      <TableCell align="center">{'Actions'}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedClients.map((client) => {
                      return (
                        <TableRow hover key={client.ID} >
                          <TableCell>
                            <Typography variant="h5">
                              {client.Naziv}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{client.embg_embs}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{client.Adresa}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{client.tel_broj}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{client.broj_smetka}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{returnBanka(client.banka)}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>{client.email}</Typography>
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                              <Tooltip title={'Преглед'} arrow>
                                <IconButton
                                  onClick={() => loadObvrskiClient(client.ID)}
                                  // component={RouterLink}
                                  // to={'/clients/' + client.ID}
                                  color="primary"
                                >
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredClients.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
          

        </Card>
      )}

      {clients.length === 0 && (
        <Card sx={{ textAlign: 'center', p: 3 }}>
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{ my: 5 }}
            gutterBottom
          >
            {t(
              'Нема прикажани клиенти'
            )}
          </Typography>
        </Card>
      )}
      {clientID &&
      <PreviewObvrskiClient obvrski={obvrski} props={rest.type} clientID={clientID} open={openClientObvrski} onClose={closeObvrskiModal} />}
    </>
  );
};

Results.propTypes = {
  clients: PropTypes.array.isRequired,
  reload: PropTypes.func,
  loading: PropTypes.func,
  stopLoading: PropTypes.func,
};

Results.defaultProps = {
  clients: []
};

export default Results;
