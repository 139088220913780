import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';

const menuItems = [
    //crm medarski
    {
      heading: 'CRM Medarski',
      items: [
        {
          name: 'Предмети',
          icon: AccountTreeTwoToneIcon,
          link: '/predmeti',
        },
        {
          name: 'Обврски',
          icon: AccountTreeTwoToneIcon,
          link: '/obvrski',
        },
        {
          name: 'Клиенти',
          icon: PeopleAltOutlinedIcon,
          link: '/clients'
        },
      ]
    },
 
];

export default menuItems;
