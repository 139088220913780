import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
  CircularProgress,
  FormControlLabel,
  useTheme,
  Checkbox,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import Text from 'src/components/Text';
import Label from 'src/components/Label';
import numeral from "numeral";
import {Zoom} from "@mui/material/index";


import AddObvrska from '../../components/AddObvrska';
import Comments from './Comments/Notifications';
import Documents from './Documents';
import Users from './Users';
import ListingContactsClients from '../../components/Listing/ListingContactsClients';
import EditForm from './EditForm';


function EditPredmetTab(props) {


  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();

  

  const handleEditPredmetOpen = () => {
    setOpen(true);
  };
  const handleEditPredmetClose = () => {
    setOpen(false);
  };



  const handleEditPredmetSuccess = (val,a) => {
    setOpen(false);
    props.updatedState(a);
    if(val === true){
      enqueueSnackbar('Предметот е успешно едитиран', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
    }
  };

  const createObvrska = () => {
    enqueueSnackbar(t('Успешно креирање на нова обрска'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
    props.reloadObvrski();
  };

  const handleKlauzuli = () => {
    if(props.predmet.klauzuli === true) {
      window.makeRequest("PUT", `/api/Predmet?ID=${props.predmet.ID}`,
        {
          klauzuli: false,
          updated_at: new Date(),
        }
        , true, (callback) => {

        }, (err) => {
          console.log('err', err);
        }, handleEditPredmetSuccess());

    } else {
      window.makeRequest("PUT", `/api/Predmet?ID=${props.predmet.ID}`,
        {
          klauzuli: true,
          updated_at: new Date(),
        }
        , true, (callback) => {
            changeObvrskaKlauzuliStatus(props.predmet.ID);

        }, (err) => {
          console.log('err', err);
        }, handleEditPredmetSuccess());
    }

  }

  const changeObvrskaKlauzuliStatus = (id) => {
    window.makeRequest("GET", `/api/Obvrski?predmetID=${id}&opis=<p>Клаузули</p>`,null,true, (callback) => {
          if(callback.body && callback.body.recordset.length > 0) {
            let obvrski = callback.body.recordset;
            obvrski.forEach((obvrska) => {
              window.makeRequest("PUT", `/api/Obvrski?ID=${obvrska.ID}`,
                {
                  status_obvrska: "Реализирана",
                  updated_at: new Date(),
                }
                , true, (callback) => {
                  props.reloadObvrski();
                }, (err) => {
                  console.log('err', err);
                });
            });
          }
      }, (err) => {
        console.log('err', err);
      });
  }




  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
                <Box>
                  <Typography variant="h4" gutterBottom>
                    {t('Информации за предметот')}
                  </Typography>
                  <Typography variant="subtitle2">
                    {t('Преглед и ажурирање на податоците на предметот')}
                  </Typography>
                </Box>
              {props.type !== 'client' &&
                <Box>
                {props.predmet.user_write === true &&
                <Box display={'flex'}>
                  <AddObvrska createObvrska={createObvrska} reloadPredmet={props.reloadPredmet} reload={props.reloadObvrski} members={props.members}   predmet={props.predmet} theme={theme}  />
                    <Button variant="text" onClick={handleEditPredmetOpen} startIcon={<EditTwoToneIcon />}>
                      {t('Измени')}
                    </Button>
                </Box>}

                </Box>}
             </Box>
            <Divider  />
            <Grid container spacing={0} display={'flex'} >
              <Grid item xs={12} md={6}>
                <Box pr={0.2} pb={2}>
                  <ListingContactsClients type={props.type} clients={props.clients} />
                </Box>
              </Grid>

              <Grid item xs={12}  md={6} >
                <Box pl={0.2} pb={2}>
                  <ListingContactsClients type={props.type} contacts={props.contacts} sprotivna={'Спротивни странки'} />
                </Box>
              </Grid>
            </Grid>

            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Divider  />
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Системски број')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                      <b>{props.predmet.system_number}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Статус клиент')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                      <b>{props.predmet.status_klient}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Број на предмет')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                      <b>{props.predmet.broj_predmet}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Интерен број')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                      <b>{props.predmet.interen_broj !== 'null' ? props.predmet.interen_broj : ''}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Референтен број')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                      <b>{props.predmet.reference_broj !== 'null' ? props.predmet.reference_broj : ''}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Основ')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                      <b>{props.predmet.osnov}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Вредност')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                      <b>{numeral(props.predmet.vrednost).format(`0,0.00`)}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Институција')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                      <b>{props.predmet.sud}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Фаза на предмет')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Text color="black">
                      <b>{props.predmet.faza_predmet}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={2} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Статус')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={2}>
                    <Label color="success">
                      <DoneTwoToneIcon fontSize="small" />
                      <b>{props.predmet.status}</b>
                    </Label>
                  </Grid>
                  </Grid>
                {(props.predmet.status === 'Завршен' && props.type !== 'client' ) &&
                <Grid textAlign={'left'}    >
                    <FormControlLabel control={<Checkbox onChange={handleKlauzuli} checked={props.predmet.klauzuli} />} label="Клаузули" />
                </Grid>}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      {props.predmet.user_write === true &&
      <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleEditPredmetClose}
    >
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {t('Едитирај Предмет')}
        </Typography>
        <Typography variant="subtitle2">
          {t(
            'Изменете ги полината за да го едитирате предметот'
          )}
        </Typography>
      </DialogTitle>

          <EditForm
            predmet={props.predmet}
            reloadObvrska={props.reloadObvrski}
            handleEditPredmetClose={handleEditPredmetSuccess}
          />
      </Dialog>
      }

      <Grid
        sx={{ pt: 1 }}
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={1}
      >
        {props.type !== 'client' &&
        <Grid item xs={12}>
         <Comments renderLogs={props.renderLogs} logs={props.logs} userID={props.userID} id={props.id} predmet={props.predmet} />
        </Grid>}
        <Grid item md={props.type === 'client' ? 12 : 6} lg={props.type === 'client' ? 12 : 7} xs={12}>
          <Documents renderLogs={props.renderLogs} type={props.type} userID={props.userID} id={props.id} predmet={props.predmet} />
        </Grid>
        {props.type !== 'client' &&
        <Grid item md={6} lg={5} xs={12}>
          <Users reloadPredmet={props.reloadPredmet} type={props.type}  renderLogs={props.renderLogs} userID={props.userID} id={props.id} predmet={props.predmet} />
        </Grid>}
          

      </Grid>
    </>
  );
}

export default EditPredmetTab;
