import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import PaymentIcon from '@mui/icons-material/Payment';
const menuItems = [
    //crm medarski
    {
      heading: 'CRM Medarski',
      items: [
        {
          name: 'Корисници',
          icon: AdminPanelSettingsOutlinedIcon,
          link: '/users',
        },
        {
          name: 'Предмети',
          icon: AccountTreeTwoToneIcon,
          link: '/predmeti',
        },
        {
          name: 'Обврски',
          icon: AccountTreeTwoToneIcon,
          link: '/obvrski',
        },
        {
          name: 'Клиенти',
          icon: PeopleAltOutlinedIcon,
          link: '/clients'
        },
        {
          name: 'Наплата',
          icon: PaymentIcon,
          link: '/naplata'
        },

      
      ]
    },

];

export default menuItems;
