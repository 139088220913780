import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';

import { Box, Tabs, Tab, Grid, IconButton, Tooltip } from '@mui/material';
import {Link as RouterLink, withRouter} from 'react-router-dom';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { Suspense, lazy } from 'react';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

import EditPredmetTab from './EditPredmetTab';
import Contacts from '././Contacts';
import Clients from './Clients';


import { styled } from '@mui/material/styles';

const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

class ManagementPredmetView extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'obvrska',
      loading: true,
      client: '',
      userID: null,
      predmet: [],
      tabs : [
        { value: 'obvrska', label: 'Обврска' },
        { value: 'clients', label: 'Клиенти' },
        { value: 'contacts', label: 'Спротивни странки' },
      ]
    }
  }

  componentDidMount() {
   this.renderObvrska();
  }

  renderObvrska = () => {
    this.getUser();

  }
  getUser = () => {
    window.makeRequest("GET", `/api/Users?ID=${true}`, null, true, (callback) => {
      this.setState({userID: callback.body.recordset[0].ID})
      this.getClients();
      this.getContacts()
      this.getBilling();
      this.renderPredmet();

    }, (err) => {
      console.log('err', err);
    });
  }

  getBilling = () => {
    let id = this.props.match.params.id;
    id&&window.makeRequest("GET", `/api/billing?obvrskaID=${id}`, false, true, (callback) => {
    }, (err) => {
      console.log('err', err);
    });

  }
  getClients = () => {
    window.makeRequest("GET", `/api/ClientsObvrskaView?obvrskaID=${this.props.match.params.id}`, null, true, (callback) => {
      this.setState({clients: callback.body.recordset});
    }, (err) => {
      console.log('err', err);
    });
  }
  getContacts = () => {
    window.makeRequest("GET", `/api/ContactsObvrskaView?obvrskaID=${this.props.match.params.id}`, null, true, (callback) => {
      this.setState({contacts: callback.body.recordset});
    }, (err) => {
      console.log('err', err);
    });
  }
  getLogs = () => {
    let id = this.props.match.params.id;
    window.makeRequest("GET", `/api/logs?obvrskaID=${id}`, null, true, (callback) => {
      let logs = callback.body.recordset.sort((a, b) => a.ID - b.ID).reverse();
      this.setState({
        logs: logs,
        loading: false,
      });
    }, (err) => {
      console.log('err', err);
    });
  }
  renderPredmet = () => {
    let id = this.props.match.params.id;
    id&&window.makeRequest("GET", `/api/all_obvrski_preview?ID=${id}`, false, true, (callback) => {
      if(callback.body !== 'no results') {
        this.setState({predmet: callback.body[0]}, () => this.getLogs());
      }else {
        this.setState({loading: false})
      }
      if(this.state.predmet.predmetID) {
        window.makeRequest("GET", `/api/checkPredmet?ID=${this.state.predmet.predmetID}`, false, true, (callback) => {
          if(callback.body.recordset[0].hasOwnProperty('ID')){
            this.setState({trosok: callback.body.recordset[0].vrednost});
          }
        }, (err) => {
          console.log('err', err);
        });
      }
    }, (err) => {
      console.log('err', err);
    
    });
  }
  updatedState =  () => {
    this.setState({updated: true, loading:true},this.renderPredmet)};
  handleTabsChange = (event, value) => {
    this.setState({currentTab: value});
  };

  deleteClient = (delID) => {
    window.makeRequest("DEL", `/api/pivotObvrskiClients?obvrskaID=${this.props.match.params.id}&clientID=${delID}`, null, true, (callback) => {
      this.setState({clients: this.state.clients.filter(function(client) {
          return client.ID !== delID
        })}, this.getLogs);
    }, (err) => {
      console.log('err', err);
    });
  }
  deleteContact = (id) => {
    window.makeRequest("DEL", `/api/pivotObvrskiContacts?obvrskaID=${this.props.match.params.id}&contactID=${id}`, null, true, (callback) => {
      this.setState({contacts: this.state.contacts.filter(function(contact) {
          return contact.ID !== id
        })}, this.getLogs);
    }, (err) => {
      console.log('err', err);
    });
  }
  
  render() {
    if(this.state.loading){
      return <SuspenseLoader/>
    } else {
      return (
        <>
          <Helmet>
            <title>Обврска</title>
          </Helmet>
          <Box sx={{ mt: 3 }}>
            <Grid
              sx={{ px: 2 }}
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12} md={11}>
                <TabsWrapper
                  onChange={this.handleTabsChange}
                  value={this.state.currentTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  indicatorColor="primary"
                >
                  {this.state.tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}
                </TabsWrapper>
              </Grid>

              <Grid item xs={12} md={1}>
                <Box sx={{ display: 'flex' }}>
                  <Tooltip arrow placement="top" title={'Назад'}>
                    <IconButton
                      component={RouterLink}
                      to={'/obvrski'}
                      color="primary"
                    >
                      <ArrowBackTwoToneIcon />
                    </IconButton>
                  </Tooltip>

                </Box>


              </Grid>

              <Grid item xs={12}>
                {this.state.currentTab === 'obvrska' && <EditPredmetTab trosok={this.state.trosok} renderLogs={this.getLogs} logs={this.state.logs} type={this.props.type} clients={this.state.clients}  contacts={this.state.contacts}  userID={this.state.userID} id={this.props.match.params.id} predmet={this.state.predmet} updatedState={this.updatedState} client={this.state.client} />}
                {this.state.currentTab === 'clients' && <Clients clients={this.state.clients} deleteClient={this.deleteClient} renderClients={this.getClients} predmet={this.state.predmet} user={this.state.client} />}
                {this.state.currentTab === 'contacts' && <Contacts contacts={this.state.contacts} deleteContact={this.deleteContact} renderContacts={this.getContacts} predmet={this.state.predmet} user={this.state.client}  />}
              </Grid>
            </Grid>
          </Box>
          <Footer />
        </>
      );
    }

  }
  
}

export default  withRouter(ManagementPredmetView);
