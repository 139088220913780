import React from 'react';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import Results from './Results';

class ManagementUsers extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
    this.renderUsers();
  }

  renderUsers = () => {
    window.makeRequest("GET", `/api/PredmetUsersView?predmetID=${this.props.id}`, null, true, (callback) => {
      this.setState({clients: callback.body.recordset}, () => this.props.reloadPredmet());
    }, (err) => {
      console.log('err', err);
    
    });
  }

  render() {
    return (
      <>

        <PageTitleWrapper>
          <PageHeader  type={this.props.type}  userID={this.props.userID} clients={this.state.clients} renderUsers={this.renderUsers} id={this.props.id} predmet={this.props.predmet} />
        </PageTitleWrapper>
  
        <Grid
          sx={{ pl: 1 }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Results type={this.props.type} renderUsers={() => this.renderUsers()} id={this.props.id} predmet={this.props.predmet} clients={this.state.clients} />
          </Grid>
        </Grid>
      </>
    );
  }
  
}

export default ManagementUsers;
