import {  useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { Box, Drawer, IconButton, Tooltip, alpha } from '@mui/material';
import { styled } from '@mui/material/styles';
import SidebarMenu from './SidebarMenu';
import Logo from 'src/components/LogoSign';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-left: ${theme.spacing(2)};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);
const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 5;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`
);
const SidebarWrapperClosed = styled(Box)(
  ({ theme }) => `
        // width: ${theme.sidebar.width};
        // min-width: ${theme.sidebar.width};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 5;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.xs}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2)};
`
);

function Sidebar(props) {
  const [ sideBarOpen, setSideBarOpen ] = useState(false);
  const closeSidebar = () => {
    setSideBarOpen(false);
  };
  const openSidebar = () => {
    setSideBarOpen(true);
  }


  return (
    <>
        <Drawer
          anchor="left"
          open={sideBarOpen}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <Box sx={{  mt: 2, mb: -9.5 }}>
            <Logo />
          </Box>
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
              </TopSection>
              <SidebarMenu onClick={() => closeSidebar()} type={props.type} />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
        {sideBarOpen === false &&
        <SidebarWrapperClosed  onClick={openSidebar}>
          <Tooltip    title="Мени">
            <IconButtonPrimary onClick={() => closeSidebar()}>
              <MenuTwoToneIcon color='action' />
            </IconButtonPrimary>
          </Tooltip>
        </SidebarWrapperClosed>}
    </>
  );
}

export default Sidebar;
