import React from 'react';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Zoom,
  Typography,
  TextField,
  Autocomplete,
  Button
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import { withSnackbar } from 'notistack';


const roles = [
  { label: 'Админ', value: 'admin' },
  { label: 'Корисник', value: 'user' },
  { label: 'Клиент', value: 'client' },
  { label: 'Сметководство', value: 'smetkovodstvo' },

];
const position = [
  { label: 'Адвокат', value: 'advokat' },
  { label: 'Приправник', value: 'pripravnik' },
  { label: 'Стучен соработник', value: 'strucensorabotnik' },
  { label: 'Клиент', value: 'client' },
  { label: 'Сметководство', value: 'smetkovodstvo' }
];

class PageHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        open: false,
        showEmbs: false,
        embs: '',
        email: '',
        username: '',
        password: '',
        first_name: '',
        role: '',
        uloga: '',
        emailError: '',
        roleError: '',
        usernameError: '',
        passwordError: '',

        
    }

  }

  handleCreateUserOpen = () => {
    this.setState({open :true});
  };

  handleCreateUserClose = () => {
    this.setState({open: false, embs: '', username: '',first_name: '',password: '', email: '', role: '', uloga: '',roleError: '', status: '',  emailError: '',usernameError: '',passwordError: '',first_nameError: ''
    }, this.props.reload())
  };



  handleInputChangeRole = (e, value, test) => {
    roles.map((role) => {
      if(role.label === value) {
        this.setState({role: role.value, roleError: ''})
      }
    })
  }
  handleInputChangePosition = (e, value, test) => {
    position.map((pos) => {
      if(pos.label === value) {
        this.setState({position: pos.value})
      }
    })
  }

  handleChange = (event) => {
    if(event.target.name === 'email') {
      this.checkEmail(event.target.value);
    }
    if(event.target.name === 'username') {
      this.checkUsername(event.target.value);
    }

    if(event) {
      this.setState({
        [event.target.name]: event.target.value,
        [event.target.name+'Error']: '',})
    }
  }

  validate = () => {
      let emailError = '';
      let usernameError = '';
      let passwordError = '';
      let first_nameError = '';
      let roleError = '';

      let email = this.state.email;
      let username = this.state.username;
      let password = this.state.password;
      let first_name = this.state.first_name;
      let role = this.state.role;


      switch (true) {
        case username.length < 1:
          usernameError = 'Корисничкото име не може да биде празно поле';
          break;
        case username.length < 4:
          usernameError = 'Корисничкото име мора да има 4 или повеќе карактери';
          break;
        case usernameError === '' && this.state.checkUsername === true:
          usernameError = 'Веќе има регистран корисник со Корисничкото име';
          break;
        default:
      }

      switch (true) {
        case email.length < 1:
          emailError = 'Е-маил не смее да биде празно поле';
          break;
        case /\S+@\S+\.\S+/.test(email) !== true:
          emailError = 'Внесете валидна Е-маил адреса';
          break;
        case emailError === '' && this.state.checkEmail === true:
          emailError = 'Внесениот е-маил е веќе регистриран';
          break;
        default:


      }
      switch (true) {
        case password.length < 1:
          passwordError = 'Лозинката е задолжителна';
          break;
        default:

      }
      switch (true) {
        case first_name.length < 1:
          first_nameError = 'Името е задолжително';
          break;
        default:
      }
      switch (true) {
        case role.length < 1:
          roleError = 'Задолжително изберете Тип';
          break;
        default:
      }

      if(usernameError || emailError ||  passwordError || first_nameError || roleError) {
        this.setState({ usernameError, emailError,  passwordError, first_nameError,roleError,error: true });
        return false;
      }

      return true;
  }

  checkEmail  = (email) => {
    window.makeRequest("POST", `/api/checkemail`, {email: email}, true, (callback) => {
      this.setState({checkEmail: false})
    }, (err) => {
      this.setState({checkEmail: true})
    });
  }
  checkUsername  = (username) => {
    window.makeRequest("POST", `/api/checkusername`, {username: username}, true, (callback) => {
      this.setState({checkUsername: false})
    }, (err) => {
      this.setState({checkUsername: true})
    });
  }

  handleCreateUserSuccess = () => {
    const isValid = this.validate();
    if(isValid) {
      window.makeRequest("POST", `/api/register`, {
        first_name: this.state.first_name,
        username: this.state.username,
        password: this.state.password,
        email: this.state.email,
        role: this.state.role,
        uloga: this.state.position,
        status: 'active',
        embs: this.state.embs,
        failed_attempt: 0,
        locked_user: 0,
        created_at: new Date(),
      }, true, (callback) => {
        let id = callback.body.recordset[0].id;
        if(this.state.role === 'admin') {
          window.makeRequest("POST", `/api/registerAdmin`, {id: id}, true, (callback) => {
          }, (err) => {
            console.log('err', err);
          });
          window.makeRequest("POST", `/api/registerAdminO`, {id: id}, true, (callback) => {
          }, (err) => {
            console.log('err', err);
          });
        }
        if(this.state.role === 'client' && this.state.embs !== ''){
          window.makeRequest("GET", `/api/Clients?embg_embs=${this.state.embs}`, false, true, (callback) => {
            callback.body.recordset.length > 0 &&
            window.makeRequest("GET", `/api/pivotPredmetClients?ClientID=${callback.body.recordset[0].ID}`, false, true, (callback) => {
              callback.body.recordset.length > 0 &&
              callback.body.recordset.forEach((record) => {
                window.makeRequest("POST", `/api/PredmetUsers`, {
                  userID: parseInt(id),
                  predmetID: parseInt(record.predmetID),
                  user_read: true,
                  user_write: false,
                  created_at: new Date(),
                }, true, (callback) => {

                }, (err) => {
                  console.log('err', err);
                });
              })

            }, (err) => {
              console.log('err', err);
            });
          }, (err) => {
            console.log('err', err);
          });

        }
        this.handleCreateUserClose();
      }, (err) => {
        console.log('err', err);
      });

      this.props.enqueueSnackbar('Корисникот е успешно креиран', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
    }

  };


  render() {
    return (
      <>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h3" component="h3" gutterBottom>
              {'Корисници'}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: { xs: 2, sm: 0 } }}
              onClick={this.handleCreateUserOpen}
              variant="contained"
              startIcon={<AddTwoToneIcon fontSize="small" />}
            >
              {'Креирај корисник'}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={this.state.open}
          onClose={this.handleCreateUserClose}
        >
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Нов корисник'}
            </Typography>
            <Typography variant="subtitle2">
              {
                'Потполнете ги празните полина за да креирате и да додадете нов корисник'
              }
            </Typography>
          </DialogTitle>

                <DialogContent dividers sx={{ p: 3 }}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} >
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            error={!!this.state.usernameError}
                            label={'Корисничко име'}
                            helperText={this.state.usernameError}
                            name="username"
                            onChange={this.handleChange}
                            value={this.state.username}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TextField
                            fullWidth
                            error={!!this.state.first_nameError}
                            helperText={this.state.first_nameError}
                            label={'Име'}
                            name="first_name"
                            onChange={this.handleChange}
                            value={this.state.first_name}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}  md={6}>
                          <TextField
                            fullWidth
                            error={!!this.state.emailError}
                            helperText={this.state.emailError}
                            label={'Е-маил адреса'}
                            name="email"
                            onChange={this.handleChange}
                            type="email"
                            value={this.state.email}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            error={!!this.state.passwordError}
                            helperText={this.state.passwordError}
                            label={'Лозинка'}
                            name="password"
                            onChange={this.handleChange}
                            type="password"
                            value={this.state.password}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            disablePortal

                            options={roles}
                            getOptionLabel={(option) => option.label}
                            onInputChange={this.handleInputChangeRole}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                error={!!this.state.roleError}
                                helperText={this.state.roleError}
                                {...params}
                                label={'Тип'}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Autocomplete
                            disablePortal
                            onInputChange={this.handleInputChangePosition}
                            options={position}
                            getOptionLabel={(option) => option.label}
                            renderInput={(params) => (
                              <TextField
                                fullWidth
                                {...params}
                                label={'Улога'}
                              />
                            )}
                          />
                        </Grid>
                        {this.state.role === 'client' &&
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label={'Ембс/Ембг'}
                            name="embs"
                            onChange={this.handleChange}
                            type="text"
                            value={this.state.embs}
                            variant="outlined"
                          />
                        </Grid>
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions sx={{ p: 3 }}>
                  <Button color="secondary" onClick={this.handleCreateUserClose}>
                    {'Затвори'}
                  </Button>
                  <Button
                    type="submit"
                    // startIcon={
                    //   isSubmitting ? <CircularProgress size="1rem" /> : null
                    // }
                    onClick={this.handleCreateUserSuccess}
                    variant="contained"
                  >
                    {'Креирај'}
                  </Button>
                </DialogActions>
        </Dialog>
      </>
    );
  }
  
}

export default withSnackbar(PageHeader);
