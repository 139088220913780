import {
  Box,
  Typography,
  Card,
  Divider,
} from '@mui/material';
import Documents from './Documents/index'


function DocumentsTab(props) {

  return (
    <Card>
    
     
     
      <Box p={3}>
        <Typography variant="h3" sx={{ pb: 2 }}>
          Прикажување на документите поврзани со клиентот
        </Typography>
        <Documents clientID={props.clientID} userID={props.user.ID} />
      </Box>
      <Divider />
 
    </Card>
  );
}

export default DocumentsTab;
