import React from 'react';
import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import PageTitleWrapper from 'src/components/PageTitleWrapper';
import { Grid } from '@mui/material';
import SuspenseLoader from 'src/components/SuspenseLoader';
import { Suspense, lazy } from 'react';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

import Results from './Results';


class ManagementProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }
  componentDidMount () {
    this.getObvrski();
  }

  getObvrski = () => {
    this.setState({loading: true})
    window.makeRequest("GET", `/api/clientsWithPaymentListing`, null, true, (callback) => {
      let obvrska = callback.body && callback.body.sort((a, b) => a - b).reverse();
      this.setState({ projects: obvrska && obvrska, loading: false })
    }, (err) => {
      console.log('err', err);
    });

  }
  loading = () => {
    this.setState({loading: true})
  }
  stopLoading = () => {
    this.setState({loading: false})
  }
 
 
  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return (
        <>
          <Helmet>
            <title>Наплата</title>
          </Helmet>
          <PageTitleWrapper>
            <PageHeader  />
          </PageTitleWrapper>
          <Grid
            sx={{ px: 4 }}
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            spacing={3}
          >
            <Grid item xs={12}>
              <Results loading={this.loading} stopLoading={this.stopLoading} reload={this.getObvrski} type={this.props.type}  clients={this.state.projects} />
            </Grid>
          </Grid>
          <Footer />
        </>
      );
    }

  }
}

export default ManagementProjects;
