import React from 'react';

import { Helmet } from 'react-helmet-async';
import { Grid } from '@mui/material';

import Results from './Results';

class ManagementProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Обврски</title>
        </Helmet>

        <Grid
          sx={{ px: 0 }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Results reload={this.props.reload} deleteObvrska={this.props.deleteObvrska}  projects={this.props.obvrski} />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default ManagementProjects;
