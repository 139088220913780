import React from 'react';
import {
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Button,
  } from '@mui/material';


const status = [
  { label: 'Активен', value: 'aktiven' },
  { label: 'Неактивен', value: 'neaktiven' },
  { label: 'Блокиран', value: 'blokiran' },

];

const banks = [
  { label: 'Стопанска Банка АД Скопје', value: 'stopanska_ad_skopje' },
  { label: 'НЛБ Тутунска Банка АД Скопје', value: 'nlb_tutunska_skopje' },
  { label: 'Комерцијална банка АД Скопје', value: 'komercijalna_skopje' },
  { label: 'Халк Банка АД Скопје', value: 'halk_skopje' },
  { label: 'Шпаркасе Банка Македонија АД Скопје', value: 'sparkase' },
  { label: 'Силк Роуд Банка АД Скопје', value: 'silk_road' },
  { label: 'ТТК Банка АД Скопје', value: 'ttk_skopje' },
  { label: 'Капитал Банка АД Скопје', value: 'kapital_skopje' },
  { label: 'Прокредит Банка АД Скопје', value: 'prokredit' },
  { label: 'Стопанска Банка АД Битола', value: 'stopanska_ad_bitola' },
  { label: 'Народната Банка на Република Северна Македонија', value: 'narodna' },
  { label: 'Универзална Инвестициона Банка АД Скопје', value: 'univerzalna_investiciona' },
  { label: 'Македонска Банка за подршка на развојот АД Скопје', value: 'makedonska_banka_za_poddrska_na_razvojot' },
];
  
class EditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
          Naziv: '',
            Adresa: '',
            embg_embs: '',
            tel_broj: '',
            broj_smetka: '',
            banka: '',
            status: '',
            email: '',

        }
    }
    componentDidMount() {
      this.setState({
        Naziv: this.props.client.Naziv,
        Adresa: this.props.client.Adresa,
        embg_embs: this.props.client.embg_embs,
        tel_broj: this.props.client.tel_broj,
        broj_smetka: this.props.client.broj_smetka,
        banka: this.props.client.banka,
        email: this.props.client.email,
        status: this.props.client.status,
      })

    }



    onClickForm = () =>  {
        window.makeRequest("PUT", `/api/Clients?ID=${this.props.client.ID}`,
        {
            Naziv: this.state.Naziv ? this.state.Naziv : this.props.client.Naziv,
            Adresa: this.state.Adresa ? this.state.Adresa : this.props.client.Adresa,
            embg_embs: this.state.embg_embs ? this.state.embg_embs : this.props.client.embg_embs,
            tel_broj: this.state.tel_broj ? this.state.tel_broj : this.props.client.tel_broj,
            broj_smetka: this.state.broj_smetka ? this.state.broj_smetka : this.props.client.broj_smetka,
            banka: this.state.banka ? this.state.banka : this.props.client.banka,
            email: this.state.email ? this.state.email : this.props.client.email,
            status: this.state.status ? this.state.status : this.props.client.status,
        }
        , true, (callback) => {
          this.props.success();
        }, (err) => {
            console.log('err', err);
        });    
    }

    handleChange = (e) => {
        this.setState({[e.target.name]: e.target.value});
    }


    render() {
        return(
          <>
            <DialogContent dividers sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} >
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={'Назив'}
                        name="Naziv"
                        onChange={this.handleChange}
                        value={this.state.Naziv ? this.state.Naziv : this.props.client.Naziv}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label={'Адреса'}
                        name="Adresa"
                        onChange={this.handleChange}
                        value={this.state.Adresa ? this.state.Adresa : this.props.client.Adresa}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'ЕМБГ/ЕМБС'}
                        name="embg_embs"
                        type='text'
                        onChange={this.handleChange}
                        value={this.state.embg_embs ? this.state.embg_embs : this.props.client.embg_embs}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Телефон'}
                        name="tel_broj"
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.tel_broj ? this.state.tel_broj : this.props.client.tel_broj}

                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label={'Сметка'}
                        name="broj_smetka"
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.broj_smetka ? this.state.broj_smetka : this.props.client.broj_smetka}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <InputLabel >Банка</InputLabel>
                        <Select
                          value={this.state.banka? this.state.banka : this.props.client.banka}
                          label="Банка"
                          name='banka'
                          onChange={this.handleChange}
                        >
                          {banks.map((bank) => {
                            return<MenuItem key={bank.value} value={bank.value}>{bank.label}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Е-маил'}
                        name="email"
                        onChange={this.handleChange}
                        type="email"
                        value={this.state.email ? this.state.email : this.props.client.email}
                        variant="outlined"
                      />
                    </Grid>
                   
                    <Grid item xs={12} md={6}>
                     <FormControl fullWidth>
                      <InputLabel >Статус</InputLabel>
                      <Select
                        value={this.state.status ? this.state.status : this.props.client.status}
                        label="Статус"
                        name='status'
                        onChange={this.handleChange}
                      >
                        {status.map((stat) => {
                          return<MenuItem key={stat.value} value={stat.value}>{stat.label}</MenuItem>
                        })}
                      </Select>
                    </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button color="secondary" onClick={this.props.handleCreateUserClose}>
                {'Затвори'}
              </Button>
              <Button
                type="submit"
                onClick={this.onClickForm}
                variant="contained"
              >
                {'Измени'}
              </Button>
            </DialogActions>
          </>
        );

    }
}


export default EditForm;
