import React from 'react';
import {
  Box,
  Container,
} from '@mui/material';
import JWTLogin from '../LoginJWT';
import { styled } from '@mui/material/styles';
import Logo from 'src/components/LogoSign';
import EmailRequest from '../EmailRequest'

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    flex-direction: column;
`
);

const TopWrapper = styled(Box)(
  () => `
  display: flex;
  width: 100%;
  flex: 1;
  padding: 20px;
`
);

class CRMLogin extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: true,
      code: false,
      username: ''
    }
  }

  changeStateUser = (username) => {this.setState({user: false, code: true, username: username})};
  returnUser = () => {this.setState({user: true, code: false, username: ""})};

  render(){
    return (
      <>
        <MainContent>
          <TopWrapper>
            <Container maxWidth="sm">
              <Logo />

              {
                this.state.code ?
                <EmailRequest returnUser={this.returnUser} username={this.state.username} />
                :
                <JWTLogin  change={this.changeStateUser} />
              }
            </Container>
          </TopWrapper>
        </MainContent>
      </>
    );
  }

}

export default CRMLogin;
