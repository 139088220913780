import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';

import {
  Grid,
  Zoom,
  CircularProgress,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import AddObvrskaModal from './AddObvrskaModal';

function PageHeader(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();




  const handleCreateProjectSuccess = () => {
    enqueueSnackbar(t('Успешно креирање на нова обрска'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      },
      TransitionComponent: Zoom
    });

    setOpen(false);
  };

  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center">
        <AddObvrskaModal type={props.type} reload={props.reload} success={handleCreateProjectSuccess} theme={theme} />
      </Grid>
    </>
  );
}

export default PageHeader;
