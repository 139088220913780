import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import NumberFormat from 'react-number-format';

function BillingButtonPercentage(props) {

  return <NumberFormat
      fullWidth
      label={props.label}
      name={props.name}
      onChange={props.onChange}
      value={props.value}
      variant="outlined"
      InputProps={{endAdornment: props.icon &&
          <InputAdornment position="end" >
            <IconButton
              onClick={props.onClick}
              edge="end"
              color='primary'
              style={{backgroundColor: props.active && '#E9ECF7'}}
              size='medium'
            >
              %
            </IconButton>
          </InputAdornment>
      }}
      customInput={TextField}
      thousandSeparator={true}
    />

}

export default BillingButtonPercentage;
