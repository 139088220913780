import React from 'react';
import {
  Grid,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  TextField,
  Button
} from '@mui/material';

class EditContact extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
    }
  }
  componentDidMount() {
    if(this.props.client) {
      this.setState({
        naziv_sprotivna_stranka: this.props.client.naziv_sprotivna_stranka,
        embg_embs: this.props.client.embg_embs,
        adresa: this.props.client.adresa,
        telefon: this.props.client.telefon,
        polnomosnikID: this.props.client.polnomosnikID,
        email: this.props.client.email,
      })
    }
  }
  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  updateContact = () => {
    window.makeRequest("PUT", `/api/Contacts?ID=${this.props.client.ID}`, {
      naziv_sprotivna_stranka: this.state.naziv_sprotivna_stranka,
      embg_embs: this.state.embg_embs,
      adresa: this.state.adresa,
      telefon: this.state.telefon,
      polnomosnikID: this.state.polnomosnikID,
      email: this.state.email,
    }, true, (callback) => {
      this.props.created(true);

    }, (err) => {
      console.log('err', err);
    });
  }

render() {
  return(
    <Dialog
      fullWidth
      maxWidth="md"
      open={this.props.open}
      onClose={this.props.onClose}
    >
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {'Едитирај странка'}
        </Typography>
        <Box component="div" sx={{ display: 'space-between' }}>
          <Box component="div" sx={{ flexGrow: 1, pt: 0.5 }}  display='flex' justifyContent='flex-start' >
            <Typography variant="subtitle2">
              {'Изменете ги полината'}
            </Typography>
          </Box>

        </Box>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 3 }}>

        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={'Назив'}
                  name="naziv_sprotivna_stranka"
                  onChange={this.onChange}
                  value={this.state.naziv_sprotivna_stranka}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={'ЕМБГ/ЕМБС'}
                  name="embg_embs"
                  onChange={this.onChange}
                  value={this.state.embg_embs}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={'Адреса'}
                  name="adresa"
                  onChange={this.onChange}
                  value={this.state.adresa}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={'Телефонски број'}
                  name="telefon"
                  onChange={this.onChange}
                  value={this.state.telefon}
                  type='text'
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  label={'Е-маил адреса'}
                  name="email"
                  onChange={this.onChange}
                  type="email"
                  value={this.state.email}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12} >
                <TextField
                  fullWidth
                  label={'Полномошник'}
                  name="polnomosnikID"
                  onChange={this.onChange}
                  type="email"
                  value={this.state.polnomosnikID}
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>

        <Button color="secondary" onClick={this.props.onClose}>
          {'Затвори'}
        </Button>
        <Button
          type="submit"
          onClick={this.updateContact}
          variant="contained"
        >
          {'Промени'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
}

export default EditContact;
