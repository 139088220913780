import React from 'react';
import {
  withRouter,
} from "react-router-dom";

import { Box } from '@mui/material';
import HeaderNotifications from './Notifications';
import moment from "moment/moment";

class HeaderButtons extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      notifications: [],
    }
  }

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications = () => {
    window.makeRequest("GET", `/api/notifications?seen=false`,false, true, (callback) => {
      if(callback.body && callback.body.recordset.length > 0){
        let array = [];
        callback.body.recordset.forEach((record) => {
            let duration = moment.duration(moment().diff(record.created_at));
            let days = duration.asDays();
              if(days >= 7){
                window.makeRequest("DEL", `/api/notifications?ID=${record.ID}`,{
                  seen: true,
                  updated_at: new Date(),
                }, true, (callback) => {

                }, (err) => {
                  console.log('err', err);
                });
              } else {
                array.push(record);
              }
        })
        let countNewNotifications = array.filter(notify => notify.seen === false).length;
        let newNotifications = array.filter(notify => notify.seen === false);
        let notifications = newNotifications.sort((a, b) => a - b).reverse();
        this.setState({notifications: notifications,countNew: countNewNotifications});
      }


    }, (err) => {
      console.log('err', err);
    });
  }

  predmetPreview = (id, type) => {
    if(type === 'obvrska') {
      this.props.history.push(`/obvrska/${id}`)
    } else {
      this.props.history.push(`/predmet/${id}`)
    }
  }




  render() {
    return (
      <Box sx={{ mr: 1 }}>
        <HeaderNotifications predmetPreview={(e,t) => this.predmetPreview(e, t)} getNotifications={this.getNotifications} notifications={this.state.notifications} countNew={this.state.countNew}/>
      </Box>
    );
  }

}

export default withRouter(HeaderButtons);
