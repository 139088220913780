import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Slide,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableContainer,
  TableRow,
  Button,
  Typography,
  Dialog,
  Zoom
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from 'src/components/Label';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone';
import UploadSingleDocument from './UploadSingleDocument';
import DocView from '../../../components/DocViewer/DocView';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);



const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});



const applyFilters = (clients, query, filters) => {
  return clients.filter((client) => {
    let matches = true;

    if (query) {
      const properties = ['Naziv', 'embg_embs','Adresa', 'banka'];
      let containsQuery = false;

      properties.forEach((property) => {
        if (client[property] && client[property].toLowerCase().includes(query.toLowerCase())) {
          containsQuery = true;
        }
      });

      if (!containsQuery) {
        matches = false;
      }
    }

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value && client[key] !== value) {
        matches = false;
      }
    });

    return matches;
  });
};

const applyPagination = (clients, page, limit) => {
  return clients.slice(page * limit, page * limit + limit);
};

const   Results = ({ clients,predmet, deleteComment,renderDocuments, ...rest }) => {
  const [selectedItems, setSelectedClients] = useState([]);
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState({});
  const [deleteDocument, setDeleteDocument] = useState("");
  const [uploadDocument, setUploadDocument] = useState(false);
  const [documentUpdateID, setDocumentUpdateID] = useState(null);
  const [viewFile, setViewFile] = useState(false);
  const [path, setPath] = useState(null);
  const [clientID, setClientID] = useState(null);


  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };



  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
  };

  const filteredClients = applyFilters(clients, query, filters);
  const paginatedClients = applyPagination(filteredClients, page, limit);
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = (client, val) => {
    setDeleteDocument(client.ID);
    setClientID(client.clientID);
    setOpenConfirmDelete(true);


  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
    setDeleteDocument("");
    setClientID(null);

  };

  const uploadEditDocument = (docID) => {
    setUploadDocument(true);
    setDocumentUpdateID(docID);
  }
  const uploadEditDocumentClose = (success, created) => {
    setUploadDocument(false);
    setDocumentUpdateID(null);
    if(created === true) {
      enqueueSnackbar(t('Документот е успешно прикачен'), {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center'
        },
        TransitionComponent: Zoom
      });
      renderDocuments();
    }
  }

  const setOpenFile = (path) => {
    if(path && path.trim() !== ""){
      setPath(path);
      setViewFile(true);
    }

  }
  const closeViewFile = () => {
    setPath(null);
    setViewFile(false);
  }


  const handleDeleteCompleted = () => {
    deleteComment(deleteDocument, clientID);
    setOpenConfirmDelete(false);
    setDeleteDocument("");
    setClientID(null);

    enqueueSnackbar(t('Документот е успешно избришан'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  };

  return (
    <>
     
      {clients.length > 0 && (
        <Card>
          {paginatedClients.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("Во моментов нема документи")}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                      </TableCell>
                      <TableCell>{t('Документ')}</TableCell>
                      <TableCell>{t('Статус')}</TableCell>
                      <TableCell align="center">{t('Actions')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedClients.map((client) => {
                      return (
                        <TableRow hover key={client.ID}>
                          <TableCell >
                          <TextSnippetTwoToneIcon />
                          </TableCell>
                          <TableCell>
                          <Box display="flex" alignItems="center" pb={0.5}>
                            <Typography sx={{ pl: 1 }} fontWeight="bold" variant="h6">
                            {client.title}
                            </Typography>
                          </Box>
                          </TableCell>
                          <TableCell>
                            {client.path && client.path.trim() !== "" ?
                            <Label color="success">{t('Прикачен')}</Label>
                          : <Label color="error">{t('Неприкачен')}</Label>}
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                                <Tooltip title={t('Преглед')} onClick={() => setOpenFile(client.path)} arrow>
                                <IconButton
                                  color="primary"
                                >
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <><Tooltip title={t('Прикачи')}  arrow>
                                <IconButton
                                  onClick={() => uploadEditDocument(client.ID)}
                                  color="primary"
                                >
                                  <DriveFolderUploadIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t('Избриши')} arrow>
                                <IconButton
                                  onClick={() => handleConfirmDelete(client)}
                                  color="primary"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip></>
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box p={2}>
                <TablePagination
                  component="div"
                  count={filteredClients.length}
                  onPageChange={handlePageChange}
                  onRowsPerPageChange={handleLimitChange}
                  page={page}
                  rowsPerPage={limit}
                  rowsPerPageOptions={[5, 10, 15]}
                />
              </Box>
            </>
          )}
        </Card>
      )}
    
      {clients.length === 0 && (
        <Card sx={{ textAlign: 'center', p: 3 }}>
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{ my: 5 }}
            gutterBottom
          >
            {t(
              'Нема прикажани документи'
            )}
          </Typography>
        </Card>
      )}

      <DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {t('Дали сте сигурни да го избришете документот')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {t('Затвори')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {t('Избриши')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>
      <DialogWrapper
        open={uploadDocument}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={uploadEditDocumentClose}
      >
       <UploadSingleDocument  ID={documentUpdateID} onClose={uploadEditDocumentClose} />
       </DialogWrapper>

        <DocView
          open={viewFile}
          onClose={closeViewFile}
          path={path}
        />
    </>
  );
};

Results.propTypes = {
  clients: PropTypes.array.isRequired,
  predmet: PropTypes.array.isRequired,


};

Results.defaultProps = {
  clients: [],
  predmet: [],

};

export default Results;
