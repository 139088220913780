import React from 'react';
import {
  Box,
  Typography,
  Card,
  Divider,
  Tooltip,
  Grid,
  Fab,
} from '@mui/material';
import { Suspense, lazy } from 'react';
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment/moment";
import SuspenseLoader from 'src/components/SuspenseLoader';
import DownloadIcon from '@mui/icons-material/Download';


import UserObvrski from './UserObvrski';
import UserPredmeti from './UserPredmeti';



class ActivityTab extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      predmeti: [],
      headers: [
        { label: "Реден бр.", key: "index" },
        { label: "Име/назив на странка", key: "clientsCSV" },
        { label: "Име/назив на спротивна странка", key: "contactsCSV" },
        { label: "Институција", key: "sud" },
        { label: "Судски број", key: "broj_predmet" },
        { label: "Основ", key: "osnov" },
        { label: "Вредност на спорот", key: "vrednost" },
        { label: "Фаза на предмет", key: "faza_predmet" },
        { label: "Статус на предмет", key: "status" },
      ],
      headersObvrski: [
        { label: "Реден бр.", key: "index" },
        { label: "Име/назив на странка", key: "clientsCSV" },
        { label: "Име/назив на спротивна странка", key: "contactsCSV" },
        { label: "Судски број", key: "broj_predmet" },
        { label: "opis", key: "opis" },
        { label: "Почеток", key: "start_date" },
        { label: "Краен рок", key: "start_date" },
        { label: "Статус", key: "status" },
      ],
    }
  }
  componentDidMount() {
    if(this.props.user) {
      window.makeRequest("GET", `/api/getUsersActivityPredmeti?userID=${this.props.user.ID}`, null, true, (callback) => {
        let filteredObvrski = [];
        let filteredArr = [];

        if(callback.body !== 'no results') {
            callback.body.forEach((record, index) => {
                  record.clientsCSV = [];
                  record.contactsCSV = [];
                  record.index = record.index + 1;
                  record.clientsCSV = record.clients.map(cli => cli.Naziv);
                  record.contactsCSV = record.contacts.map(cli => cli.naziv_sprotivna_stranka);
                  filteredArr.push(record);
            })
            filteredArr.sort((a, b) =>  a.ID - b.ID).reverse();
            filteredArr.map((fil, index) => {
              fil['index'] = index + 1;
            })
        }

        window.makeRequest("GET", `/api/getUsersActivityObvrski?userID=${this.props.user.ID}`, null, true, (callback) => {
              if(callback.body !== 'no results') {
                callback.body.filter((record, index) => {
                  record.clientsCSV = [];
                  record.contactsCSV = [];
                  record.opis  = record.opis.replace(/<\/?[^>]+(>|$)/g, "")
                  record.index = record.index + 1;
                  record.clientsCSV = record.clients.map(cli => cli.Naziv);
                  record.contactsCSV = record.contacts.map(cli => cli.naziv_sprotivna_stranka);
                  filteredObvrski.push(record);
                })
                filteredObvrski.sort((a, b) =>  a.ID - b.ID).reverse();

                filteredObvrski = callback.body.sort((a, b) => a - b).reverse();
                filteredObvrski.map((fil, index) => {
                  fil['index'] = index + 1;
                })
              }
              this.setState({predmeti: filteredArr,obvrski: filteredObvrski, loading: false})

            }, (err) => {
              console.log('err', err);
            });

      }, (err) => {
        console.log('err', err);
      });


    }

  }


  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    }else {
      return (
        <Card>
          <Box p={3}>
            <Grid item xs={12} display={'space-between'}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
            >
              <Typography variant="h2" sx={{ pb: 2 }}>
                Прикажување на активностите
              </Typography>
              <Box>
                <Tooltip title="Превземи Предмети" arrow>
                  <Fab  size="small"   >
                    <CSVLink headers={this.state.headers}  filename={"Корисник-"+moment(new Date()).format('DD.MM.yyyy')+'.csv'}
                      // onClick={() => console.log('you clicked')}
                             data={this.state.predmeti}
                    >
                      <DownloadIcon color={'primary'}   />
                    </CSVLink>
                  </Fab>
                </Tooltip>
              </Box>


            </Grid>

            <Divider sx={{mt:1, mb:1}}/>
            <Typography variant="h4" mb={1}>
              Предмети
            </Typography>
            <UserPredmeti predmeti={this.state.predmeti} />
            <Divider sx={{mt:2, mb:2}}/>
            <Grid item xs={12} display={'space-between'}
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
            >
              <Typography variant="h4" mb={1}>
                Обврски
              </Typography>
              <Box >
                <Tooltip title="Превземи Обврски" arrow>
                  <Fab  size="small"   >
                    <CSVLink headers={this.state.headersObvrski}  filename={"Корисник-"+moment(new Date()).format('DD.MM.yyyy')+'.csv'}
                      // onClick={() => console.log('you clicked')}
                             data={this.state.obvrski}
                    >
                      <DownloadIcon color={'primary'}   />
                    </CSVLink>
                  </Fab>
                </Tooltip>
              </Box>
            </Grid>

            <UserObvrski obvrski={this.state.obvrski} />
          </Box>
          <Divider />

        </Card>
      );
    }

  }

}

export default ActivityTab;
