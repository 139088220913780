import {
  Box,
  Container,
  Button,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';


function Status404() {

  return (
    <>
      <Container sx={{ textAlign: 'center', display:'flex', justifyContent:'center', pt: 12}}   >
        <Container >
              <Box textAlign="center">
                <img alt="404" height={180} src="/static/images/status/404.svg" />
              </Box>
              <Button component={RouterLink} to={'/'}   variant="" size="large">
                {'<-'}
              </Button>
        </Container>
      </Container>
    </>
  );
}

export default Status404;
