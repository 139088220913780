import React from 'react';
import {
    DialogActions,
    DialogContent,
    Grid,
    TextField,
    InputLabel,
    MenuItem,
    FormControl,
    Select,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    CircularProgress
  } from '@mui/material';
import NumberFormat from 'react-number-format';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';



const StatusClient = [
  { title: 'Тужител' },
  { title: 'Тужен' },
  { title: 'Оштетен' },
  { title: 'Обвинет' },
  { title: 'Предлагач' },
  { title: 'Противник' },
  { title: 'Доверител' },
  { title: 'Должник' },
];
  const StatusPredmet = [
    { title: 'Незапочнат' },
    { title: 'Активен' },
    { title: 'Завршен' },
  ];

const FazaPredmet = [
  { title: 'Tужба' },
  { title: 'Предлог за времена мерка' },
  { title: 'Одговор на тужба' },
  { title: 'Рочиште' },
  { title: 'Супервештачење' },
  { title: 'Прекин' },
  { title: 'Првостепена пресуда' },
  { title: 'Жалба' },
  { title: 'Второстепена пресуда' },
  { title: 'Ревизија' },
  { title: 'Решение за утврдени побарувања (стечај)' },
  { title: 'Решение за запирање и заклучување (стечај)' },
  { title: 'План за конечна распределба (стечај)' },
  { title: 'План за реорганизација (стечај)' },
  { title: 'Апликација ЕСЧП' },
  { title: 'Арбитража' },
  { title: 'Опомена пред тужба'},
];

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };


class EditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          loaded: true,
          status_klient: this.props.predmet.status_klient,
          broj_predmet: this.props.predmet.broj_predmet,
          interen_broj: this.props.predmet.interen_broj,
          reference_broj: this.props.predmet.reference_broj,
          osnov: this.props.predmet.osnov,
          vrednost: this.props.predmet.vrednost,
          sud: this.props.predmet.sud,
          faza_predmet: this.props.predmet.faza_predmet,
          status: this.props.predmet.status,
          vrednostErr: '',
          changedStatus: false,
          createKlauzuli: false,
          changedPredmet: false,
        }
    }
    componentDidMount() {

    }

    validate = () => {
    let vrednostErr = '';
    let vrednost = this.state.vrednost;

    switch (true) {
      case isNaN(vrednost):
        vrednostErr = 'Невалиден број';
        break;
      default:
    }
    if(vrednostErr) {
      this.setState({ vrednostErr,error: true });
      return false;
    }

    return true;
    }
    onClickForm = () =>  {
      const isValid = this.validate();

      isValid&&
        window.makeRequest("PUT", `/api/Predmet?ID=${this.props.predmet.ID}`,
        {
            status_klient: this.state.status_klient,
            broj_predmet: this.state.broj_predmet,
            interen_broj: this.state.interen_broj,
            reference_broj: this.state.reference_broj,
            osnov: this.state.osnov,
            vrednost: this.state.vrednost,
            sud: this.state.sud,
            faza_predmet: this.state.faza_predmet,
            status: this.state.status,
        }
        , true, (callback) => {
          if(callback.body && callback.body.rowsAffected[0] === 1 && this.state.changedPredmet === true) {
            window.makeRequest("PUT", `/api/Obvrski?predmetID=${this.props.predmet.ID}`,
              {
                broj_predmet: this.state.broj_predmet,
                updated_at: new Date(),
              }
              , true, (callback) => {
              }, (err) => {
                console.log('err', err);
              });
          }
          if(callback.body && this.state.status === 'Завршен'){
            if(this.props.predmet) {
              let Members = [];
              let Contacts = [];
              let Clients = [];
                this.props.predmet.members.map((member) => {
                  Members.push(member.userID, member.user_write);
                })
                this.props.predmet.clients.map((client) => {
                  Clients.push(client.ID);
                })
                this.props.predmet.contacts.map((contact) => {
                  Contacts.push(contact.ID);
                })

              window.makeRequest("PUT", `/api/obvrskaKlauzuli?predmetID=${this.props.predmet.ID}`,
                {
                  broj_predmet: this.props.predmet.broj_predmet,
                  members: Members,
                  contacts: Contacts,
                  clients: Clients,
                }
                , true, (callback) => {
                }, (err) => {
                  console.log('err', err);
                });
            }

          }

            if(callback.body && this.state.status === 'Активен' && this.state.changedStatus){

              window.makeRequest("PUT", `/api/Predmet?ID=${this.props.predmet.ID}`,
                {
                  klauzuli: false,
                  updated_at: new Date(),
                }
                , true, (callback) => {


                }, (err) => {
                  console.log('err', err);
                });

              window.makeRequest("DEL", `/api/Obvrski?predmetID=${this.props.predmet.ID}&opis=<p>Клаузули</p>`,null, true, (callback) => {

                if(callback.body.rowsAffected.length > 0) {
                  this.props.reloadObvrska();
                  }

              }, (err) => {
                  if(err){
                    console.log('err', err);
                  }

              });

            }

                // this.props.reloadObvrska();
            this.setState({changedPredmet: false, createKlauzuli: false, changedStatus: false});
        }, (err) => {
            console.log('err', err);
        }, this.props.handleEditPredmetClose(true, this.state.changedPredmet));

    }
    handleChange = (e) => {
      let name = e.target.name;
      switch(name) {
        case 'vrednost':
          let removed = e.target.value.replace(/\,/g,'');
          this.setState({[e.target.name]: removed});
              break;
        case 'broj_predmet':
          this.setState({[e.target.name]: e.target.value, changedPredmet: true})
          break;
        case 'status':
          this.setState({[e.target.name]: e.target.value, changedStatus: true})
          break;
        default:
          this.setState({[e.target.name]: e.target.value})
      }

    }

    changeCreateKlauzuli = (e) => {
      if(this.state.createKlauzuli === true){
        this.setState({createKlauzuli: false})
      }else {
        this.setState({createKlauzuli: true})
      }
    }


    render() {
        return(
          <>
            <DialogContent dividers sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}  md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Статус клиент</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          value={this.state.status_klient && this.state.status_klient}
                          name='status_klient'
                          label="Статус клиент"
                          onChange={this.handleChange}
                        >
                          {StatusClient.map((status) => {
                            return<MenuItem key={status.title} value={status.title}>{status.title}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}  md={6}>
                    <TextField
                        fullWidth
                        label={'Број на предмет'}
                        name="broj_predmet"
                        onChange={this.handleChange}
                        value={this.state.broj_predmet && this.state.broj_predmet}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Интерен број'}
                        name="interen_broj"
                        type='text'
                        onChange={this.handleChange}
                        value={this.state.interen_broj !== 'null' ? this.state.interen_broj : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label={'Референтен број'}
                        name="reference_broj"
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.reference_broj !== 'null' ? this.state.reference_broj : ''}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}  md={6}>
                      <TextField
                        fullWidth
                        label={'Основ'}
                        name="osnov"
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.osnov && this.state.osnov}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}  md={6}>
                      <NumberFormat
                        customInput={TextField}
                        fullWidth
                        label={'Вредност'}
                        name="vrednost"
                        error={!!this.state.vrednostErr}
                        helperText={this.state.vrednostErr && this.state.vrednostErr}
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.vrednost && this.state.vrednost}
                        variant="outlined"
                        thousandSeparator={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        name="sud"
                        label={'Институција'}
                        onChange={this.handleChange}
                        type="text"
                        value={this.state.sud && this.state.sud}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}  md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Фаза на предмет</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          value={this.state.faza_predmet && this.state.faza_predmet}
                          name='faza_predmet'
                          label="Фаза на предмет"
                          onChange={this.handleChange}
                        >
                          {FazaPredmet.map((status) => {
                            return<MenuItem key={status.title} value={status.title}>{status.title}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}  md={6}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Статус на предмет</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          value={this.state.status && this.state.status}
                          name='status'
                          label="Статус на предмет"
                          onChange={this.handleChange}
                        >
                          {StatusPredmet.map((status) => {
                            return<MenuItem key={status.title} value={status.title}>{status.title}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                    </Grid>
                    {this.state.status === 'Завршен' &&
                    <Grid item xs={12} >
                      <FormGroup >
                        <FormControlLabel    style={{userSelect: 'none'}}
                                             control={<Checkbox onClick={this.changeCreateKlauzuli}
                          icon={<BookmarkBorderIcon  />}
                          checkedIcon={<BookmarkIcon  />}
                        />} label="Креирај обврска за превземање клаузули" />
                      </FormGroup>
                    </Grid>}
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button color="secondary" onClick={this.props.handleEditPredmetClose}>
                {'Затвори'}
              </Button>
              <Button
                type="submit"
                onClick={() => this.onClickForm(this.props)}
                // startIcon={
                //     this.props.isSubmitting ? <CircularProgress size="1rem" /> : null
                // }
                // disabled={Boolean(this.props.errors.submit) || this.props.isSubmitting}
                variant="contained"
              >
                {'Измени'}
              </Button>
            </DialogActions>
          </>
        );

    }
}


export default EditForm;
