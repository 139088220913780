import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Zoom,
  Typography,
  CircularProgress,
  Button,
  useTheme
} from '@mui/material';
import { useSnackbar } from 'notistack';
import ListLog from '../../../components/Logs/ListLog';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);


function AddComment(props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [submitting, setSubmitting] = useState(false);



  const [value, setValue] = useState(null);
  const [comment, setComment] = useState("");
  const [openLogs, setOpenLogs] = useState(false);

  
  const handleCreateProjectOpen = () => {
    setOpen(true);
    setComment("");

  };

  const handleOpenLogs = () => {
    setOpenLogs(true);
  }
  const handleCloseLogs = () => {
    setOpenLogs(false);
  }

  const handleCreateProjectClose = () => {
    setOpen(false);
  };

  const changeComment = (comment) => {
    setComment(comment);
  }
  const CreateComment = () => {
    window.makeRequest("POST", `/api/komentarPredmet`, {
      predmetID:   parseInt(props.id),
      userID:   props.userID,
      komentar:   comment,
      created_at:   new Date(),
    }, true, (callback) => {
      setComment("");
      handleCreateProjectSuccess();
      props.renderComments()
    }, (err) => {
      if(err && comment.length > 250) {
        handleCreateProjectErr()
      }
      console.log('err', err);
    });
  }
  const handleCreateComment = () => {

    setSubmitting(true);
    setTimeout(() => {
      CreateComment()
    }, 1000)

   
  }
  const handleCreateProjectErr = () => {
    enqueueSnackbar(t('Имате внесено повеке од 250 карактери'), {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
    setSubmitting(false);

    // setOpen(false);
  };
  const handleCreateProjectSuccess = () => {

    enqueueSnackbar(t('Успешно креиравте коментар'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });

    setOpen(false);
    setSubmitting(false);
  };


  return (
    <>
      <Grid container  alignItems="center" justifyContent="flex-end">
        {props.predmet.user_write === true && <><Grid item mr={0.5}>
              <ListLog type={'predmet'} logs={props.logs} onClick={handleOpenLogs} predmetID={props.id} onClose={handleCloseLogs} open={openLogs}/>
            </Grid>
             <Grid item >
             <Button 
              onClick={handleCreateProjectOpen}
              variant="outlined" size="small">
              {t('+ Коментар')}
            </Button>
        </Grid></>}
      </Grid>
      {props.predmet.user_write === true && <Dialog
        fullWidth
        maxWidth="md"
        open={open}
        onClose={handleCreateProjectClose}
      >
        <DialogTitle sx={{ p: 3 }}>
          <Typography variant="h4" gutterBottom>
            {t('Додади нов коментар')}
          </Typography>
          <Typography variant="subtitle2">
            {t('Пополнете ги полината за да внесете коментар')}
          </Typography>
        </DialogTitle>
       
              <DialogContent dividers sx={{ p: 3 }}>
                <Grid container spacing={0}>
      
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} sx={{ pb: { xs: 1, md: 0 } }}>
                      <b>{t('Коментар')}:</b>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <EditorWrapper>
                      <ReactQuill value={comment} onChange={changeComment} />
                    </EditorWrapper>
                  </Grid>
        
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} sx={{ pb: { xs: 1, md: 0 } }}>
                    </Box>
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`
                    }}
                    item
                    xs={12}
                    sm={8}
                    md={9}
                  >
                    <Button
                      sx={{ mr: 2 }}
                      type="submit"
                      onClick={handleCreateComment}
                      startIcon={
                        submitting ? <CircularProgress size="1rem" /> : null
                      }
                      disabled={submitting}
                      variant="contained"
                      size="large"
                    >
                      {t('Креирај')}
                    </Button>
                    <Button
                      color="secondary"
                      size="large"
                      variant="outlined"
                      onClick={handleCreateProjectClose}
                    >
                      {t('Затвори')}
                    </Button>
                  </Grid>
                </Grid>
              </DialogContent>
      </Dialog>}
    </>
  );
}

export default AddComment;
