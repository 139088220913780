import React from 'react';

import { Helmet } from 'react-helmet-async';
import PageHeader from './PageHeader';
import Footer from 'src/components/Footer';
import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import Results from './Results';

class ManagementUsers extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
    }
  }

  componentDidMount() {
   this.getUsers();
  }
  getUsers = () => {
    window.makeRequest("GET", `/api/Users`, null, true, (callback) => {
      this.setState({users: callback.body.recordset});
    }, (err) => {
      console.log('err', err);

    });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Корисници</title>
        </Helmet>
        <PageTitleWrapper>
          <PageHeader reload={this.getUsers}/>
        </PageTitleWrapper>
  
        <Grid
          sx={{ px: 4 }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Results reload={this.getUsers} users={this.state.users} />
          </Grid>
        </Grid>
        <Footer />
      </>
    );
  }
  
}

export default ManagementUsers;
