import React from 'react';
import Results from './Results';

class ManagementProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    }
  }
  componentDidMount () {
  }

  render() {
    return (
      <>
        <Results reload={this.props.reload}   projects={this.props.obvrski} />
      </>
    );
  }
}

export default ManagementProjects;
