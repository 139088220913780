import React from 'react';
import {
  DialogActions,
  DialogContent,
  Grid,
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
  Button,
} from '@mui/material';


const roles = [
  {label: 'Админ', value: 'admin'},
  {label: 'Корисник', value: 'user'},
  {label: 'Клиент', value: 'client'},
  {label: 'Сметководство', value: 'smetkovodstvo'},


];
const position = [
  {label: 'Адвокат', value: 'advokat'},
  {label: 'Приправник', value: 'pripravnik'},
  {label: 'Стучен соработник', value: 'strucensorabotnik'},
  {label: 'Клиент', value: 'client'},
  {label: 'Сметководство', value: 'smetkovodstvo'},

];


class EditForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      uloga: this.props.user.uloga && this.props.user.uloga,
      role: this.props.user.role && this.props.user.role,
      email: this.props.user.email && this.props.user.email,
      username: this.props.user.username && this.props.user.username,
      first_name: this.props.user.first_name && this.props.user.first_name,
    }
  }


  componentDidMount() {
  }

  handleInputChangeRole = (e, value, test) => {
    this.setState({role: e.target.value})
  }

  handleInputChangePosition = (e, value, test) => {
    this.setState({uloga: e.target.value})
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }
  onClickForm = () => {
    window.makeRequest("PUT", `/api/Users?ID=${this.props.user.ID}`,
      {
        email: this.state.email ? this.state.email : this.props.user.email,
        first_name: this.state.first_name ? this.state.first_name : this.props.user.first_name,
        username: this.props.user.username,
        role: this.state.role ? this.state.role : this.props.user.role,
        uloga: this.state.uloga ? this.state.uloga : this.props.user.uloga,
      }
      , true, (callback) => {
        if (this.state.role === 'admin') {
          window.makeRequest("POST", `/api/registerAdmin`, {id: this.props.user.ID}, true, (callback) => {
          }, (err) => {
            console.log('err', err);
          });
          window.makeRequest("POST", `/api/registerAdminO`, {id: this.props.user.ID}, true, (callback) => {
          }, (err) => {
            console.log('err', err);
          });
        }
        this.props.handleCreateUserClose();
        this.props.update();
      }, (err) => {
        console.log('err', err);
      });
  }

  render() {
    return (
      <>
        <DialogContent dividers sx={{p: 3}}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={'Корисничко име'}
                    name="username"
                    disabled
                    onChange={this.handleChange}
                    value={this.state.username}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label={'Име'}
                    name="first_name"
                    onChange={this.handleChange}
                    value={this.state.first_name}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label={'Е-маил адреса'}
                    name="email"
                    onChange={this.handleChange}
                    type="email"
                    value={this.state.email}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Тип</InputLabel>
                    <Select
                      fullWidth
                      value={this.state.role}
                      label="Тип"
                      onChange={this.handleInputChangeRole}
                    >
                      {roles.map((role) => {
                        return <MenuItem key={role.value} value={role.value}>{role.label}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel>Улога</InputLabel>
                    <Select
                      value={this.state.uloga}
                      label="Улога"
                      onChange={this.handleInputChangePosition}
                    >
                      {position.map((pos) => {
                        return <MenuItem key={pos.value} value={pos.value}>{pos.label}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{p: 3}}>
          <Button color="secondary" onClick={this.props.handleCreateUserClose}>
            {'Затвори'}
          </Button>
          <Button
            type="submit"
            onClick={this.onClickForm}
            // startIcon={
            //     this.props.isSubmitting ? <CircularProgress size="1rem" /> : null
            // }
            // disabled={Boolean(this.props.errors.submit) || this.props.isSubmitting}
            variant="contained"
          >
            {'Измени'}
          </Button>
        </DialogActions>
      </>
    );

  }
}


export default EditForm;
