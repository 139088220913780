import * as React from 'react';
import TextField from '@mui/material/TextField';
import {DateTimePicker as Date} from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';


export default function DateTimePicker(props){
  return<LocalizationProvider  dateAdapter={AdapterDateFns}><Date
    autoOk
    label={props.label}
    value={props.value}
    onChange={props.onChange}
    name={props.name}
    inputFormat={props.inputFormat}
    renderInput={(params) => <TextField fullWidth {...params} />}
  /></LocalizationProvider>
}
