import React from 'react';
import {
    Button,
  } from '@mui/material';
  import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';

class Logout extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
        }
    }

    Logout = () => {
        window.logout();
    }

    render() {
        return(
            <>
            <Button color="primary" fullWidth onClick={this.Logout}>
                <LockOpenTwoToneIcon sx={{ mr: 1 }} />
                {'Одјави се'}
            </Button>
            </>
        )
    }
}

export default Logout;
