import React from 'react';
import {
    Button,
    List,
    CardContent,
    ListItemAvatar,
    DialogContent,
    Dialog,
    DialogTitle,
    Grid,
    Typography,
    Pagination,
    Stack,
    Divider,
    ListItem,
    Box,
    ListItemText
} from '@mui/material';
import Text from 'src/components/Text';
import moment from "moment/moment";


const renderMethod = (method) => {
    switch(method){
        case 'POST':
            return 'Додавање';
        case 'PUT':
            return 'Промена';
        case 'DELETE':
            return 'Бришење';
        default:
            return method;
    }
};
const renderTable = (table) => {
    switch(table){
        case 'komentarPredmet':
            return 'Коментар';
        case 'komentarObvrski':
            return 'Коментар';
        case 'PredmetUsers':
            return 'Вработени';
        case 'ObvrskaUsers':
            return 'Вработени';
        case 'Predmet':
            return 'Предмет';
        case 'Obvrska':
            return 'Обврска';
        case 'pivotPredmetClients':
            return 'Клиент';
        case 'pivotObvrskiClients':
            return 'Клиент';
        case 'pivotPredmetContacts':
            return 'Странка';
        case 'pivotObvrskiContacts':
            return 'Странка';
        case 'documentsPredmet':
            return 'Документ';
        case 'documentsObvrski':
            return 'Документ';
      case 'Obvrski':
        return 'Обврска';
        default:
            return table;
    }
};


class ListLog extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loaded: true,
            logs: [],
            currentPage: 1,
            logsPerPage: 5,
            type: this.props.type === 'predmet' ? 'предметот' : 'обврската' ,
        }
    }

    componentDidMount() {
      if(this.props.logs) {
        this.setState({logs: this.props.logs});
      }
    }

    handleChange = (e,v) => {
        this.setState({currentPage: v})
    };
    renderLog = (e) => {
        return<ul key={10}>
            {e && Object.keys(e).map((key, index) => {
                let keyC = '';
                const renderKey = (key) => {
                    switch(key){
                        case 'status_klient':
                            return keyC = "Статус клиент";
                        case 'broj_predmet':
                            return keyC = "Број на предмет";
                        case 'osnov':
                            return keyC = "Основ";
                        case 'vrednost':
                            return keyC = "Вредност";
                        case 'sud':
                            return keyC = "Суд";
                        case 'faza_predmet':
                            return keyC = "Фаза на предмет";
                        case 'status':
                            return keyC = "Статус";
                        case 'system_number':
                            return keyC = "Системски број";
                        case 'userID':
                            return keyC = "Корисник број";
                        case 'predmetID':
                            return keyC = "Предмет";
                        case 'obvrskaID':
                            return keyC = "Обврска";
                        case 'user_read':
                            return keyC = "Корисник прегледува";
                        case 'user_write':
                            return keyC = "Корисник едитира";
                        case 'clientID':
                            return keyC = "Клиент";
                        case 'contactID':
                            return keyC = "Спротивна странка";
                        case 'updated_at':
                            return keyC = "Изменуван";
                        case 'title':
                            return keyC = "Наслов";
                        case 'path':
                            return keyC = "Патека до фајлот";
                        case 'komentar':
                            return keyC = "Коментар";
                        case 'interen_broj':
                            return keyC = "Интерен број";
                        case 'reference_broj':
                            return keyC = "Референтен број";
                        case 'created_at':
                            return keyC = "Креиран";
                        case 'opis':
                            return keyC = "Опис";
                        case 'start_date':
                            return keyC = "Датум на почеток";
                        case 'kraen_rok':
                            return keyC = "Краен рок";
                        case 'klauzuli':
                            return keyC = "Клаузули";
                        case 'status_obvrska':
                            return keyC = "Статус на обврска";
                        case 'system_number_obvrska':
                          return keyC = "Креирање обврска за предмет";
                        default:
                            return key;
                    }
                };
                const renderValue = (value, key) => {
                    if(key === 'kraen_rok' || key === 'start_date' || key === 'created_at' || key === 'updated_at'){
                        return moment(value).format("DD.MM.YYYY");
                    } else if(value === true){
                        return value = 'true'
                    } else if (value === false ){
                        return value = 'false';
                    } else if(key === 'komentar' || key === 'opis'){
                      return value = <Box alignItems={'center'} mb={-1} mt={-0.8} ml={0.4} ><Typography  style={{lineHeight: 0.5}}  dangerouslySetInnerHTML={{__html: value}}
                                                 variant="body3"   >
                        </Typography></Box>;
                    }
                    else {
                        return value
                    }
                }
                return (
                    <li style={{ display: 'flex'}} key={key + index}>{renderKey(key)} : {renderValue(e[key], key)}</li>
                )
            })}
        </ul>;
    }


    render() {
        const { logs } = this.props;
        const { currentPage, logsPerPage } = this.state;
        const indexOfLastLog = currentPage * logsPerPage;
        const indexOfFirstLog = indexOfLastLog - logsPerPage;
        const currentLogs = logs && logs.slice(indexOfFirstLog, indexOfLastLog);

        return (
            <>
                <Button
                    onClick={this.props.onClick}
                    variant="outlined" size="small">
                    {'Логови'}
                </Button>

                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={this.props.open}
                    onClose={this.props.onClose}
                >
                    <DialogTitle sx={{ p: 3 }}>
                        <Typography variant="h4" gutterBottom>
                            {'Преглед на логови'}
                        </Typography>
                        <Typography variant="subtitle2">
                            {`Последни измени на ${this.state.type}`}
                        </Typography>
                    </DialogTitle>

                    <DialogContent>

                        <Divider />
                        <CardContent>
                            <Stack  spacing={2}>
                              {logs && currentLogs.map((log) => {
                                return (
                                  <List key={log.ID} disablePadding>
                                    <ListItem disableGutters>
                                      <ListItemAvatar>
                                      </ListItemAvatar>
                                      <ListItemText
                                        primary={
                                          <Text color="black">
                                            <b>{renderMethod(log.method)}</b><b> на {renderTable(log.log_table)} </b>
                                            <b>{log.body !== 'undefined' && this.renderLog(JSON.parse(log.body))}</b>
                                          </Text>
                                        }
                                        primaryTypographyProps={{
                                          variant: 'body1',
                                          color: 'textPrimary',
                                          gutterBottom: true,
                                          noWrap: true
                                        }}
                                        secondary={
                                          <Text color="success">{'Извршено од корисникот ' + log.name}</Text>
                                        }
                                        secondaryTypographyProps={{variant: 'body2', noWrap: true}}
                                      />
                                    </ListItem>
                                    <Divider sx={{my: 1}}/>
                                  </List>
                                )
                              })}
                                <Grid container alignItems="center" justifyContent="center" >
                                    <Pagination defaultPage={currentPage} shape={'rounded'}  size='medium' page={currentPage}  onChange={this.handleChange} count={logs && Math.ceil(logs.length / logsPerPage)} variant="outlined" color="primary" />
                                </Grid>
                            </Stack>
                        </CardContent>
                    </DialogContent>
                </Dialog>

            </>
        );
    }

}

export default ListLog;
