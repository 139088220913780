import PaymentIcon from '@mui/icons-material/Payment';
const menuItems = [
    //crm medarski
    {
      heading: 'CRM Medarski',
      items: [
        {
          name: 'Наплата',
          icon: PaymentIcon,
          link: '/naplata'
        },

      
      ]
    },

];

export default menuItems;
