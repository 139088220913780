import { useTranslation } from 'react-i18next';
import 'react-quill/dist/quill.snow.css';

import {
  Grid,
  Typography,
  useTheme,
  Zoom,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import AddPredmetModal from './AddPredmetModal';


function PageHeader(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();


  const handleCreateProjectSuccess = () => {
    enqueueSnackbar(t('Успешно креирање на нов предмет'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  };

  const Header = () => {
    return<Grid item xs={6}>
      <Typography variant="h3" component="h3" gutterBottom>
        {'Предмети'}
      </Typography>
      <Typography variant="subtitle2">
        {'Преглед на вашите активни предмети'}
      </Typography>
    </Grid>
  }

  return (
    <>
      {props.type === 'client' ? <Header /> :
      <Grid container justifyContent="space-between" alignItems="center">
        <AddPredmetModal  type={props.type} header={<Header />} reload={props.reload} success={handleCreateProjectSuccess} theme={theme} />
      </Grid>
      }
    </>
  );
}

export default PageHeader;
