import React from 'react';
import {
  Box,
  Card,
  Typography,
  Link,
  TextField,
  Button
} from '@mui/material';

class EmailRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      codeError: '',
      code: "",
    }
  }

  validate = () => {
    let codeError = this.state.codeError;
    let code = this.state.code;

    switch (true) {
      case code.length < 1:
        codeError = 'Кодот не може да биде празно поле';
        break;
      case code.length !== 6:
        codeError = 'Кодот мора да има 6 карактери';
        break;
      default:
    }
    if(codeError) {
      this.setState({ codeError,  error: true });
      return false;
    }

    return true;
  }
  handleLogin = () => {
    const isValid = this.validate();
    if(isValid) {
      try{
        Object.keys(this.state.credentials);
        window.login(this.state.credentials);
      }catch(e){
        this.setState({codeError: "Невалиден код"});
      }
    }
  }

  checkCode = (code) => {
    window.makeRequest("POST", `/api/activateCode`, {code: code, username: this.props.username}, true, (callback) => {
      let loginObj = callback.body;
      if(loginObj === "not valid") {
        this.setState({codeError: "Невалиден код"});
      } else {
        this.setState({credentials: callback.body, codeError: ''});
      }
      // if(loginObj === 'object' && loginObj !== null){
      //   this.setState({credentials: callback.body});
      // } else {
      //   this.setState({ codeError: "Невалиден код"});
      // }

    }, (err) => {
      console.log('err', err);
    });
  }

  handleChange = (e) => {
    if(e.target.value.length <= 6) {
      this.setState({code: e.target.value, codeError: ''});
      if(e.target.value.length === 6) {
        this.checkCode(e.target.value);
      }
    }

  }

  render(){
    return (
      <>
        <Card sx={{mt: 3, px: 4, pt: 5, pb: 3}}>
          <Box>
            <Typography variant="h3" sx={{mb: 1}}>
              {'Внесете го кодот од вашата е-маил адреса'}
            </Typography>
            <Typography variant="body2" color={'primary'} >
              {'Испратен е код на вашата е-маил адреса!'}
            </Typography>
            <TextField
              error={!!this.state.codeError}
              fullWidth
              margin="normal"
              autoFocus
              helperText={this.state.codeError}
              label={'6 цифрен код'}
              name="code"
              // onBlur={handleBlur}
              onChange={this.handleChange}
              type="username"
              value={this.state.code}
              variant="outlined"
            />
            <Button
              sx={{ mt: 3 }}
              color="primary"
              startIcon={this.state.isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={this.state.isSubmitting}
              type="submit"
              fullWidth
              size="large"
              onClick={this.handleLogin}
              variant="contained"
            >
              {'Потврди'}
            </Button>
          </Box>
        </Card>
        <Box mt={3} textAlign="right">
          <Typography
            component="span"
            variant="subtitle2"
            color="text.primary"
            fontWeight="bold"
          >
            {'Сакате да се најавите повторно?'}
          </Typography>{' '}
          <Link onClick={() => this.props.returnUser()} style={{cursor: 'pointer', userSelect: 'none'}}  underline="none" >
            <b>Најави се</b>
          </Link>
        </Box>
      </>
    );
  }

}

export default EmailRequest;
