import { useState, forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  Checkbox,
  Slide,
  Tooltip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Button,
  Typography,
  ListItemAvatar,
  ListItemText,
  Dialog,
  Zoom
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import LaunchTwoToneIcon from '@mui/icons-material/LaunchTwoTone';
import Label from 'src/components/Label';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import { useSnackbar } from 'notistack';

const DialogWrapper = styled(Dialog)(
  () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
);

const AvatarError = styled(Avatar)(
  ({ theme }) => `
      background-color: ${theme.colors.error.lighter};
      color: ${theme.colors.error.main};
      width: ${theme.spacing(12)};
      height: ${theme.spacing(12)};

      .MuiSvgIcon-root {
        font-size: ${theme.typography.pxToRem(45)};
      }
`
);

const ButtonError = styled(Button)(
  ({ theme }) => `
     background: ${theme.colors.error.main};
     color: ${theme.palette.error.contrastText};

     &:hover {
        background: ${theme.colors.error.dark};
     }
    `
);



const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});


const Results = ({ clients, predmet,id,renderUsers,...rest }) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useState("");

  const handleSelectOneClients = (event, clientID, write) => {
    let userWrite = write !== true;
    window.makeRequest("PUT", `/api/ObvrskaUsers?ID=${clientID.ID}`, {
      user_write: userWrite,
      updated_at: new Date,
    }, true, (callback) => {
      renderUsers();

      // renderUsers();
      // console.log('callback', callback);
    }, (err) => {
      console.log('err', err);
    });



  };


  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

  const handleConfirmDelete = (user) => {
    setOpenConfirmDelete(true);
    setUser(user);
  };

  const closeConfirmDelete = () => {
    setOpenConfirmDelete(false);
  };

  const handleDeleteCompleted = (e, val) => {
    setOpenConfirmDelete(false);
    enqueueSnackbar(t('Вработениот е успешно избришан'), {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });


    window.makeRequest("DEL", `/api/ObvrskaUsers?obvrskaID=${user.obvrskaID}&ID=${user.ID}`, null, true, (callback) => {
      renderUsers();
    }, (err) => {
      console.log('err', err);
    });

  };

  const returnStatusUser = (e) => {
    let color = '';
    let text = '';
    switch(e){
      case 'advokat':
        color = 'primary';
        text = 'Адвокат';
        break;
      case 'pripravnik':
        color = 'secondary';
        text = 'Приправник';
        break;
      case 'client':
        color = 'info';
        text = 'Клиент';
        break;
      case 'strucensorabotnik':
        color = 'warning';
        text = 'Стручен соработник';
        break;
      case 'smetkovodstvo':
        color = 'warning';
        text = 'Сметководство';
        break;
    }

    return<Label color={color}>{text}</Label>;
  }

  return (
    <>
     
      {clients.length > 0 && (
        <Card>
          {clients.length === 0 ? (
            <>
              <Typography
                sx={{ py: 10 }}
                variant="h3"
                fontWeight="normal"
                color="text.secondary"
                align="center"
              >
                {t("Во моментов нема активни вработени")}
              </Typography>
            </>
          ) : (
            <>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                    {predmet.user_write === true && rest.type === 'admin' ? <><TableCell padding="checkbox">
                      </TableCell></> : '' }
                      <TableCell>{t('Име')}</TableCell>
                      <TableCell>{t('Статус')}</TableCell>
                      {predmet.user_write === true &&<TableCell align="center">{t('Actions')}</TableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {clients.map((client) => {
                      return (
                        <TableRow hover key={client.ID} selected={client.user_write && true}>
                          {predmet.user_write === true && rest.type === 'admin' ? <TableCell padding="checkbox">
                            <Checkbox
                              checked={client.user_write && true}
                              onChange={(event) =>
                                handleSelectOneClients(event, client, client.user_write)
                              }
                              value={client.ID}
                            />
                          </TableCell>: ''}
                          <TableCell>
                          <Box display="flex" alignItems="center">
                            <ListItemAvatar>
                              <Avatar src="" />
                            </ListItemAvatar>
                            <ListItemText
                              primary={client.first_name}
                              primaryTypographyProps={{ variant: 'h5', noWrap: true }}
                              secondaryTypographyProps={{
                                variant: 'body2',
                                color: 'textSecondary',
                                noWrap: true
                              }}
                            />
                          </Box>
                          </TableCell>
                          <TableCell>
                            {returnStatusUser(client.uloga)}
                          </TableCell>
                          <TableCell align="center">
                            <Typography noWrap>
                            {predmet.user_write === true && rest.type === 'admin' ?<><Tooltip title={t('Преглед')} arrow>
                                <IconButton
                                  component={RouterLink}
                                  to={'/user/' + client.userID}
                                  color="primary"
                                >
                                  <LaunchTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t('Избриши')} arrow>
                                <IconButton
                                  onClick={() => handleConfirmDelete(client)}
                                  color="primary"
                                >
                                  <DeleteTwoToneIcon fontSize="small" />
                                </IconButton>
                              </Tooltip></> : ''}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Card>
      )}
    
      {clients.length === 0 && (
        <Card sx={{ textAlign: 'center', p: 3 }}>
          <Typography
            align="center"
            variant="h4"
            fontWeight="normal"
            color="text.secondary"
            sx={{ my: 5 }}
            gutterBottom
          >
            {t(
              'Нема прикажани клиенти'
            )}
          </Typography>
        </Card>
      )}

      {predmet.user_write === true &&<DialogWrapper
        open={openConfirmDelete}
        maxWidth="sm"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={closeConfirmDelete}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          p={5}
        >
          <AvatarError>
            <CloseIcon />
          </AvatarError>

          <Typography align="center" sx={{ py: 4, px: 6 }} variant="h2">
            {t('Дали сте сигурни да го избришете вработениот?')}
            ?
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              sx={{ mx: 1 }}
              onClick={closeConfirmDelete}
            >
              {t('Затвори')}
            </Button>
            <ButtonError
              onClick={handleDeleteCompleted}
              size="large"
              sx={{ mx: 1, px: 3 }}
              variant="contained"
            >
              {t('Избриши')}
            </ButtonError>
          </Box>
        </Box>
      </DialogWrapper>}
    </>
  );
};

Results.propTypes = {
  clients: PropTypes.array.isRequired,
  predmet: PropTypes.any

};

Results.defaultProps = {
  clients: [],

};

export default Results;
