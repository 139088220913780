import React from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from 'src/components/Footer';
import {
  withRouter,
} from "react-router-dom";
import { Box, Tabs, Tab, Grid, IconButton, Tooltip  } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowBackTwoToneIcon from '@mui/icons-material/ArrowBackTwoTone';
import { Link as RouterLink } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';


const TabsWrapper = styled(Tabs)(
  () => `
    .MuiTabs-scrollableX {
      overflow-x: auto !important;
    }
`
);

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);
import ActivityTab from './ActivityTab';
import EditProfileTab from './EditProfileTab';
const LoginLogsTab = Loader(lazy(() => import('./LoginLogsTab')));
const SecurityTab = Loader(lazy(() => import('./SecurityTab')));


class ManagementUsersView extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      currentTab: 'edit_profile',
      loading: true,
      user: '',
      tabs : [
        { value: 'edit_profile', label: 'Профил' },
        { value: 'activity', label: 'Активности' },
        { value: 'notifications', label: 'Логови' },
        { value: 'security', label: 'Лозинка' }
      ]
    }


    
  }

  componentDidMount() {
    this.renderUser();
    this.renderLogs();
  }

  renderUser = () => {
    let id = this.props.match.params.id;
    id && window.makeRequest("GET", `/api/Users?ID=${id}`, null, true, (callback) => {
      this.setState({user: callback.body.recordset[0],  currentTab: 'edit_profile', loading: false})
    }, (err) => {
      console.log('err', err);
    
    });
  }
  renderLogs = () => {
    let id = this.props.match.params.id;
    id && window.makeRequest("GET", `/api/logs_users?userID=${id}`, null, true, (callback) => {
      let logs = callback.body.recordset.sort((a, b) => a - b).reverse();
      this.setState({logs_user: logs})
    }, (err) => {
      console.log('err', err);

    });
  }
  updatedState =  () => {
    this.renderUser();
    this.setState({updated: true});

  }

  handleTabsChange = (event, value) => {
    this.setState({currentTab: value});
  };

  
  render() {
    if(this.state.loading) {
      return <SuspenseLoader/>
    } else {
      return (
        <>
          <Helmet>
            <title>Корисници</title>
          </Helmet>

          <Box sx={{ mt: 3 }}>

            <Grid
              sx={{ px: 4 }}
              container
              direction="row"
              justifyContent="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={12} md={12}>

              </Grid>
              <Grid item xs={12} md={11}>

                <TabsWrapper
                  onChange={this.handleTabsChange}
                  value={this.state.currentTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  textColor="primary"
                  indicatorColor="primary"
                >

                  {this.state.tabs.map((tab) => (
                    <Tab key={tab.value} label={tab.label} value={tab.value} />
                  ))}

                </TabsWrapper>

              </Grid>

              <Grid item xs={12} md={1}>
                <Box sx={{ display: 'flex' }}>
                  <Tooltip arrow placement="top" title={'Назад'}>
                    <IconButton
                      component={RouterLink}
                      to={'/users'}
                      // onClick={handleBack}
                      color="primary"
                      // sx={{ p: 0, mr: 2 }}
                    >
                      <ArrowBackTwoToneIcon />
                    </IconButton>
                  </Tooltip>

                </Box>


              </Grid>



              <Grid item xs={12}>

                {this.state.currentTab === 'activity' && <ActivityTab user={this.state.user} />}
                {this.state.currentTab === 'edit_profile' && <EditProfileTab updatedState={() => this.updatedState()} user={this.state.user} />}
                {this.state.currentTab === 'notifications' && <LoginLogsTab user={this.state.user} logs={this.state.logs_user} />}
                {this.state.currentTab === 'security' && <SecurityTab user={this.state.user} />}

              </Grid>

            </Grid>
          </Box>
          <Footer />
        </>
      );
    }

  }
  
}

export default  withRouter(ManagementUsersView);
