import { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  CardContent,
  Card,
  Box,
  Divider,
  Button,
  Switch ,
  CircularProgress
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';

import Text from 'src/components/Text';
import Label from 'src/components/Label';
import EditForm from './EditForm';

function EditClientTab(props) {



  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  

  const handleEditClientOpen = () => {
    setOpen(true);
  };

  const handleEditClientClose = () => {
    setOpen(false);
  };

  const handleEditClientSuccess = () => {
    enqueueSnackbar('Клиентот е успешно едитиран', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      // TransitionComponent: Slide
    });
    setOpen(false);
    props.updatedState();


  };

    const renderStatus = (stat) => {
     let status = [
        { label: 'Активен', value: 'aktiven' },
        { label: 'Неактивен', value: 'neaktiven' },
        { label: 'Блокиран', value: 'blokiran' },

      ];
      let st = '';
      status.map((status) => {
        if(status.value === stat) {
          st = status.label;
        }
      })
    return st;
  }

  const returnBankName = (name) => {
    const banks = [
      { label: 'Стопанска Банка АД Скопје', value: 'stopanska_ad_skopje' },
      { label: 'НЛБ Тутунска Банка АД Скопје', value: 'nlb_tutunska_skopje' },
      { label: 'Комерцијална банка АД Скопје', value: 'komercijalna_skopje' },
      { label: 'Халк Банка АД Скопје', value: 'halk_skopje' },
      { label: 'Шпаркасе Банка Македонија АД Скопје', value: 'sparkase' },
      { label: 'Силк Роуд Банка АД Скопје', value: 'silk_road' },
      { label: 'ТТК Банка АД Скопје', value: 'ttk_skopje' },
      { label: 'Капитал Банка АД Скопје', value: 'kapital_skopje' },
      { label: 'Прокредит Банка АД Скопје', value: 'prokredit' },
      { label: 'Стопанска Банка АД Битола', value: 'stopanska_ad_bitola' },
      { label: 'Народната Банка на Република Северна Македонија', value: 'narodna' },
      { label: 'Универзална Инвестициона Банка АД Скопје', value: 'univerzalna_investiciona' },
      { label: 'Македонска Банка за подршка на развојот АД Скопје', value: 'makedonska_banka_za_poddrska_na_razvojot' },
    ];
    let nameBank = '';

    banks.forEach((bank) => {
      if(bank.value === name) {
        nameBank = bank.label;
      }
    })
    return nameBank;



  }

  const checkForPrivateClient = () => {
    window.makeRequest("PUT", `/api/Clients?ID=${props.client.ID}`,
      {
        IsPrivate: props.client && props.client.IsPrivate === false,
      }
      , true, (callback) => {
        handleEditClientSuccess();
      }, (err) => {
        console.log('err', err);
      });
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <Box
              p={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box>
                <Typography variant="h4" gutterBottom>
                  {t('Информации за клиентот')}
                </Typography>
                <Typography variant="subtitle2">
                  {t('Преглед и ажурирање на податоците на клиентот')}
                </Typography>
              </Box>
              <Button variant="text" onClick={handleEditClientOpen} startIcon={<EditTwoToneIcon />}>
                {t('Измени')}
              </Button>
            </Box>
            <Divider />
            <CardContent sx={{ p: 4 }}>
              <Typography variant="subtitle2">
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Назив')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>{props.client.Naziv}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('ЕМБГ/ЕМБС')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>{props.client.embg_embs}</b>
                    </Text>
                  </Grid>
               
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Адреса')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>{props.client.Adresa}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Телефон')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>{props.client.tel_broj}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Сметка')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>{props.client.broj_smetka}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Банка')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>{returnBankName(props.client.banka)}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Е-маил')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Text color="black">
                      <b>{props.client.email}</b>
                    </Text>
                  </Grid>
                  <Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Статус')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9}>
                    <Label color="success">
                      <DoneTwoToneIcon fontSize="small" />
                      <b>{t(renderStatus(props.client.status))}</b>
                    </Label>
                  </Grid>
                  {props.type === 'admin' && <><Grid item xs={12} sm={4} md={3} textAlign={{ sm: 'right' }}>
                    <Box pr={3} pb={2}>
                      {t('Приватен')}:
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={8} md={9} >
                    <Switch checked={props.client.IsPrivate} onClick={checkForPrivateClient}  />
                  </Grid></>}
                </Grid>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleEditClientClose}
    >
      <DialogTitle sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {t('Едитирај Клиент')}
        </Typography>
        <Typography variant="subtitle2">
          {t(
            'Изменете ги полината за да го едитирате клиентот'
          )}
        </Typography>
      </DialogTitle>
          <EditForm
          success={handleEditClientSuccess}
          client={props.client}
          handleCreateUserClose={handleEditClientClose}
          onClose={handleEditClientClose}

          />
      </Dialog>
    </>
  );
}

export default EditClientTab;
