import React from 'react';
import PageHeader from './PageHeader';
import { Grid, Typography } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import Results from './Results';

const Header = () => {
  return<Grid item>
    <Typography variant="h3" component="h3" gutterBottom>
      {'Документи'}
    </Typography>
  </Grid>
}

class ManagementUsers extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      clients: [],
      users: [],
      predmet: [],
      loadedClients: false,
    }

    
  }



  componentDidMount() {
    if(this.props.predmet) {
      this.setState({predmet: this.props.predmet})
    }
     this.renderDocuments();
  }
 
  renderDocuments = () => {
    window.makeRequest("GET", `/api/documentsPredmet?predmetID=${this.props.id}`, null, true, (callback) => {
      let documentsPredmet = callback.body.recordset.sort((a, b) => (b.ID - a.ID))
      this.setState({clients: documentsPredmet}, this.props.renderLogs);
    }, (err) => {
      console.log('err', err);
    });
  }


  deleteComment = (id) => {
    window.makeRequest("DEL", `/api/documentsPredmet?predmetID=${this.props.id}&ID=${id}`, null, true, (callback) => {
      let filteredArray = this.state.clients.filter(item => item.ID !== id);
      this.setState({clients: filteredArray}, this.props.renderLogs);
    }, (err) => {
      console.log('err', err);
    });
  }






  render() {
    return (
      <>
        <PageTitleWrapper>
          {this.props.type === 'client' ? <Header/> :
            <PageHeader header={<Header/>} renderDocuments={() => this.renderDocuments()} userID={this.props.userID}
                        id={this.props.id} predmet={this.props.predmet}/>
          }
        </PageTitleWrapper>
  
        <Grid
          sx={{ pr: 1 }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Results type={this.props.type} renderDocuments={() => this.renderDocuments()} deleteComment={this.deleteComment} predmet={this.state.predmet} clients={this.state.clients} />
          </Grid>
        </Grid>
      </>
    );
  }
  
}

export default ManagementUsers;

