import DocViewer, {DocViewerRenderers} from "react-doc-viewer/build/index";
import FileViewer from 'react-file-viewer';
import styles from './doc.css';

// function Doc(props) {
//
//   return <DocViewer
//     style={{width: '100%', height: '100%'}}
//     // width={'auto'}
//     pluginRenderers={DocViewerRenderers}
//     documents={props.viewLink}
//     theme={{
//       width: '500px',
//       height: '500px',
//       primary: "#5296d8",
//       secondary: "#ffffff",
//       tertiary: "#5296d899",
//       text_primary: "#ffffff",
//       text_secondary: "#5296d8",
//       text_tertiary: "#00000099",
//       disableThemeScrollbar: false,
//     }}
//     config={{
//       header: {
//         disableHeader: true,
//         disableFileName: true,
//         retainURLParams: true
//       }}}
//   />
// }
//
// export default Doc;


function Doc(props) {
  return<div style={{height: '100%', width: '100%',minHeight: '500px',minWidth: '300px',  placeContent: 'center', textAlign:'center', overflowX: 'hidden', }} >
    <FileViewer
      className={styles}
      fileType={props.viewLink[0].uri.split('.').pop()}
      filePath={props.viewLink[0].uri}
    />
  </div>

}

export default Doc;
