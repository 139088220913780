import * as React from 'react'
import { Box, Card,  } from '@mui/material';
import { styled } from '@mui/material/styles';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';

import Logo from './Logo';

const HeaderWrapper = styled(Card)(
  ({ theme }) => `
    height: ${theme.header.height};
    color: ${theme.header.textColor};
    padding: ${theme.spacing(0, 2)};
    right: 0;
    z-index: 6;
    background-color: ${theme.colors.primary.main};
    position: fixed;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 0;
`
);

const BoxLogoWrapper = styled(Box)(
  ({ theme }) => `
  margin-right: ${theme.spacing(2)};

  @media (min-width: ${theme.breakpoints.values.lg}px) {
    width: calc(${theme.sidebar.width} - ${theme.spacing(4)});
  }
    
`
);

function Header(props) {

  return (
    <HeaderWrapper>
      <Box display="flex" alignItems="center">
        <BoxLogoWrapper>
          <Logo />
        </BoxLogoWrapper>
      </Box>
      <Box display="flex" alignItems="center">
        {props.type === 'admin' && <HeaderButtons />}
        {props.type === 'user' && <HeaderButtons />}
        <HeaderUserbox logout={props.logout} />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
