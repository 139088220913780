import React from  'react';
import { styled } from '@mui/material/styles';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
  Grid,
  Dialog,
  DialogTitle,
  Tooltip,
  Chip,
  DialogContent,
  DialogActions,
  Box,
  ButtonGroup,
  Typography,
  TextField,
  CircularProgress,
  Avatar,
  Autocomplete,
  Button,
} from '@mui/material';
import AddTwoToneIcon from '@mui/icons-material/AddTwoTone';
import AddClientModal from './../components/AddClientModal';
import AddContactModal from './../components/AddContactModal';
import moment from 'moment';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import DateTimePicker from './controls/DateTimePicker/DateTimePicker'

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);
const StatusObvrska = [
  { title: 'Отворена' },
  { title: 'Нереализирана' },
  { title: 'Реализирана' },
];


class AddObvrskaModal extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      isSubmitting: false,
      open: false,
      naznaceniPredmet: [],
      clientsPredmet: [],
      contactsPredmet: [],
      start_date: new Date(),
      kraen_rok: new Date(),
      opis: '',
      status_obvrska: '',
      contacts: [],
      clients: [],
      users: [],
      advokat_id: '',
      openAddClientModal: false,
      openAddContactModal: false,
      broj_predmet: '',
      faza_predmet: '',
    }



  }

  componentDidMount() {
    this.renderClients();
    this.renderContacts();
  }

  handleCreateProjectOpen = () => {
    if(this.props.predmet){
      window.makeRequest("GET", `/api/previewUsers?role=user`, null, true, (callback) => {
        let users = [];
        let fillter = [];
        if(callback.body && callback.body.length > 0) {
          users = callback.body;
          // this.setState({users: users});
        }
        fillter = this.props.predmet.members.filter(o1 => users.some(o2 => o1.userID === o2.ID));


        this.setState({
          naznaceniPredmet: fillter,
          users: users,
          clientsPredmet: this.props.predmet.clients,
          contactsPredmet: this.props.predmet.contacts,
          open:true
        })
      }, (err) => {
        console.log('err', err);
      });
    }
  }
  handleCreateProjectClose = () => {
    this.setState({
      open: false,
      start_date:  new Date(),
      kraen_rok:  new Date(),
      opis: '',
      status_obvrska: '',

    })
  }
  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value})
  }


  renderClients = () => {
    window.makeRequest("GET", `/api/getClients`, null, true, (callback) => {
      if(callback.body && callback.body.length > 0) {
        let clients = callback.body.sort((a, b) => a.ID - b.ID).reverse();
        this.setState({clients: clients});
      }

    }, (err) => {
      console.log('err', err);
    });
  }
  renderContacts = () => {
    window.makeRequest("GET", `/api/Contacts`, null, true, (callback) => {
      if(callback.body && callback.body.recordset.length > 0) {
        let contacts = callback.body.recordset.sort((a, b) => a.ID - b.ID).reverse();
        this.setState({contacts: contacts});
      }
    }, (err) => {
      console.log('err', err);
    });
  }

  newObvrska = () => {
    window.makeRequest("POST", `/api/Obvrski`, {
      opis: this.state.opis,
      predmetID: this.props.predmet.ID,
      broj_predmet: this.props.predmet.broj_predmet ? this.props.predmet.broj_predmet : this.props.predmet.system_number,
      start_date: this.state.start_date,
      kraen_rok: this.state.kraen_rok,
      status_obvrska: this.state.status_obvrska,
      created_at: new Date(),
    }, true, (callback) => {
      if(callback.body.recordset[0].id) {
        let id = callback.body.recordset[0].id;
        this.systemNumber(id);
        this.naznaceniPredmet(id);
        this.clientsPredmet(id);
        this.contactsPredmet(id);
        this.setState({
          open: false,
          isSubmitting: false,
          start_date: moment(),
          end_date: moment(),
          opis: '',
        },() => this.props.success())
      }
    }, (err) => {
      console.log('err', err);
    });
  }
  createPredmet = () => {
    this.setState({isSubmitting: true});
    setTimeout(() => {
      this.newObvrska();
    }, 1000)
  }
  systemNumber = (id) => {
    window.makeRequest("PUT", `/api/Obvrski?ID=${id}`, {
      system_number_obvrska: id+1000,
    }, true, (callback) => {
    }, (err) => {
      console.log('err', err);
    });
  }
  naznaceniPredmet = (id) => {
    this.state.naznaceniPredmet.forEach((user) => {
      window.makeRequest("POST", `/api/ObvrskaUsers`, {
        userID: user.userID ? user.userID : user.ID,
        obvrskaID: id,
        user_read: true,
        user_write: !!(user.user_write && user.user_write === true),
        created_at: new Date(),
      }, true, (callback) => {
      }, (err) => {
        console.log('err', err);
      });
    })

  }
  clientsPredmet = (id) => {
    this.state.clientsPredmet.forEach((client) => {
      window.makeRequest("POST", `/api/pivotObvrskiClients`, {
        obvrskaID: id,
        clientID: client.ID,
        created_at: new Date(),
      }, true, (callback) => {
      }, (err) => {
        console.log('err', err);
      });
    })

  }
  contactsPredmet = (id) => {
    this.state.contactsPredmet.forEach((contact) => {
      window.makeRequest("POST", `/api/pivotObvrskiContacts`, {
        obvrskaID: id,
        contactID: contact.ID,
        created_at: new Date(),
      }, true, (callback) => {
      }, (err) => {
        console.log('err', err);
      });
    })

  }
  onChangeStatusObvrska = (e, val) => {val?this.setState({status_obvrska: val.title}):this.setState({status_obvrska: ""})}
  onChangeNaznaceniPredmet = (e, val) => {
    val?this.setState({naznaceniPredmet: val}):this.setState({naznaceniPredmet: []})

  }
  onChangeClients = (e,val) => {val?this.setState({clientsPredmet: val}):this.setState({clientsPredmet: []})}
  onChangeContacts = (e,val) => {val?this.setState({contactsPredmet: val}):this.setState({contactsPredmet: []})}
  CloseContactModal = () => {this.setState({openAddContactModal: false}, this.renderContacts)}
  CloseClientModal = () => {this.setState({openAddClientModal: false}, this.renderClients)}
  onChangeOpis = (e) => {this.setState({opis: e})}
  handleChangeStartDate = (e) => {this.setState({start_date: e, kraen_rok: e})}
  handleChangeEndDate = (e) => {this.setState({kraen_rok: e})}

    render() {
    let clients = [];
    let contacts = [];
    let users = [];
    if(this.state.users.length > 0 && this.state.naznaceniPredmet.length > 0){
      users = this.state.users.filter(o1 => !this.state.naznaceniPredmet.some(o2 => o1.ID === o2.userID));
    }else {
      users = this.state.users;
    }
    if(this.state.clients.length > 0 && this.state.clientsPredmet.length > 0){
      clients = this.state.clients.filter(o1 => !this.state.clientsPredmet.some(o2 => o1.ID === o2.ID));
    } else {
      clients = this.state.clients;
    }
    if(this.state.contacts.length > 0 && this.state.contactsPredmet.length > 0){
      contacts = this.state.contacts.filter(o1 => !this.state.contactsPredmet.some(o2 => o1.ID === o2.ID));
    }else {
      contacts = this.state.contacts;
    }


    const { classes, theme } = this.props;
    return(
      <>
        <Grid pr={2}>
          <Button
            sx={{ mt: { xs: 2, sm: 0 } }}
            onClick={this.handleCreateProjectOpen}
            variant="contained"
            startIcon={<AddTwoToneIcon fontSize="small" />}
          >
            {'Обврска'}
          </Button>
        </Grid>
        <Dialog
          open={this.state.open}
          onClose={this.handleCreateProjectClose}
          maxWidth="md">
          <DialogTitle sx={{ p: 3 }}>
            <Typography variant="h4" gutterBottom>
              {'Нова обврска'}
            </Typography>
            <Typography variant="subtitle2">
              {'Потполнете ги празните полина за да креирате и да додадете нова обврска'}
            </Typography>
          </DialogTitle>

          <DialogContent dividers sx={{ pb: 3, pl: 3, pr:3 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} >
                    <Grid>
                      <p>{'Опис на обврска'}:</p>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                    >
                      <EditorWrapper>
                        <ReactQuill onChange={this.onChangeOpis} value={this.state.opis ? this.state.opis : ''} />
                      </EditorWrapper>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      autoOk
                      label={'Почеток'}
                      name='start_date'
                      inputFormat = "dd-MM-yyyy  HH:mm"
                      value={this.state.start_date}
                      onChange={this.handleChangeStartDate}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <DateTimePicker
                      autoOk
                      label={'Краен рок'}
                      inputFormat = "dd-MM-yyyy  HH:mm"
                      name='kraen_rok'
                      value={this.state.kraen_rok}
                      onChange={this.handleChangeEndDate}
                      renderInput={(params) => <TextField fullWidth {...params} />}
                    />
                  </Grid>

                  <Grid item xs={12}   >
                    <Autocomplete
                      sx={{ m: 0 }}
                      limitTags={2}
                      onChange={this.onChangeStatusObvrska}
                      options={StatusObvrska}
                      renderOption={(props, option) => (
                        <li {...props}>
                          {option.title}
                        </li>
                      )}
                      getOptionLabel={(option) => option.title}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder={'Статус на обврска...'}
                        />
                      )}
                    />
                  </Grid>


                  <Grid
                    item
                    xs={12}
                  >
                    <Autocomplete
                      multiple
                      sx={{ m: 0 }}
                      limitTags={2}
                      options={users}
                      onChange={this.onChangeNaznaceniPredmet}
                      defaultValue={this.state.naznaceniPredmet}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Avatar sx={{ mr: 1 }}   />
                          {option.first_name}
                        </li>
                      )}
                      getOptionLabel={(option) => option.first_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder={'Селектирај назначени за обврската...'}
                        />
                      )}
                      renderTags={(users, getTagProps) =>
                        users.map((ev, index) => (
                          <Chip
                            key={ev.ID}
                            label={ev.first_name}
                            {...getTagProps({ index })}
                            avatar={<Avatar />}
                          />
                        ))
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={6}
                  >
                    <Autocomplete
                      multiple
                      sx={{ m: 0 }}
                      limitTags={2}
                      onChange={this.onChangeClients}
                      options={clients}
                      defaultValue={this.state.clientsPredmet}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Avatar sx={{ mr: 1 }}   />
                          {option.Naziv}
                        </li>
                      )}
                      getOptionLabel={(option) => option.Naziv}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder={'Селектирај Клиенти за обврската...'}
                        />
                      )}
                      renderTags={(clients, getTagProps) =>
                        clients.map((ev, index) => (
                          <Chip
                            label={ev.Naziv}
                            {...getTagProps({ index })}
                            avatar={<Avatar />}
                          />
                        ))
                      }
                    />
                  </Grid>
                  <Grid
                    sx={{
                      mb: `${theme.spacing(3)}`
                    }}
                    item
                    xs={12}
                    md={6}
                  >
                    <Autocomplete
                      multiple
                      sx={{ m: 0 }}
                      limitTags={2}
                      onChange={this.onChangeContacts}
                      noOptionsText={'Странката не е пронајдена кликнете на копчето странка за да додадете'}
                      options={contacts}
                      defaultValue={this.state.contactsPredmet}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <Avatar sx={{ mr: 1 }}   />
                          {option.naziv_sprotivna_stranka}
                        </li>
                      )}
                      getOptionLabel={(option) => option.naziv_sprotivna_stranka}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                          placeholder={'Селектирај Спротивни странки за обврската...'}
                        />
                      )}
                      renderTags={(users, getTagProps) =>
                        users.map((ev, index) => (
                          <Chip
                            label={ev.naziv_sprotivna_stranka}
                            {...getTagProps({ index })}
                            avatar={<Avatar />}
                          />
                        ))
                      }
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12} >
                  <ButtonGroup disableElevation >
                    <Tooltip title={'Нов Клиент'}  arrow>
                      <Button
                        onClick={() => this.setState({openAddClientModal: true})}
                        variant="outlined" size="small">
                        {'+ Клиент'}
                      </Button>
                    </Tooltip>
                    <Tooltip title={'Нова Странка'}  arrow>
                      <Button
                        onClick={() => this.setState({openAddContactModal: true})}
                        variant="outlined" size="small">
                        {'+ Странка'}
                      </Button>
                    </Tooltip>
                  </ButtonGroup>

                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ p: 3 }}>
            <Button color="secondary" onClick={this.handleCreateProjectClose}>
              {'Затвори'}
            </Button>
            <Button
              type="submit"
              onClick={this.createPredmet}
              disabled={this.state.isSubmitting}
              startIcon={
                this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
              }
              variant="contained"
            >
              {'Креирај'}
            </Button>
          </DialogActions>
        </Dialog>
        <AddClientModal onClose={this.CloseClientModal}  open={this.state.openAddClientModal} />
        <AddContactModal onClose={this.CloseContactModal}  open={this.state.openAddContactModal} />
      </>
    );
  }
}

export default AddObvrskaModal;

