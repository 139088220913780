import React from 'react';
import PageHeader from './PageHeader';
import { Grid } from '@mui/material';
import PageTitleWrapper from 'src/components/PageTitleWrapper';

import Results from './Results';

class ManagementUsers extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      loading: true,
      clients: [],
      users: [],
      loadedClients: false,
    }
  }
  componentDidMount() {
     this.renderDocuments();
  }
 
  renderDocuments = () => {
    window.makeRequest("GET", `/api/documentsObvrski?obvrskaID=${this.props.id}`, null, true, (callback) => {
      let documentsObvrski = callback.body.recordset.sort((a, b) => (b.ID - a.ID))
      this.setState({clients: documentsObvrski}, this.props.renderLogs);
    }, (err) => {
      console.log('err', err);
    });
  }


  deleteComment = (id) => {
    window.makeRequest("DEL", `/api/documentsObvrski?obvrskaID=${this.props.id}&ID=${id}`, null, true, (callback) => {
      let filteredArray = this.state.clients.filter(item => item.ID !== id)
      this.setState({clients: filteredArray}, this.props.renderLogs);
    }, (err) => {
      console.log('err', err);
    });
  }






  render() {
    return (
      <>
        <PageTitleWrapper>
          <PageHeader renderDocuments={() => this.renderDocuments()} userID={this.props.userID} id={this.props.id} predmet={this.props.predmet} />
        </PageTitleWrapper>
  
        <Grid
          sx={{ pr: 1 }}
          container
          direction="row"
          justifyContent="center"
          alignItems="stretch"
          spacing={3}
        >
          <Grid item xs={12}>
            <Results renderDocuments={() => this.renderDocuments()}  predmet={this.props.predmet} deleteComment={this.deleteComment} clients={this.state.clients} />
          </Grid>
        </Grid>
      </>
    );
  }
  
}

export default ManagementUsers;
