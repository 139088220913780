import React from 'react';
import {
    DialogActions,
    DialogContent,
    Grid,
    FormControl,
    Select,
    InputLabel,
    MenuItem,
    Box,
    TextField,
    Button,
    CircularProgress
  } from '@mui/material';
  import DatePicker from '@mui/lab/DatePicker';
  import ReactQuill from 'react-quill';
  import 'react-quill/dist/quill.snow.css';
  import { styled } from '@mui/material/styles';
  import moment from 'moment';

const EditorWrapper = styled(Box)(
  ({ theme }) => `

    .ql-editor {
      min-height: 100px;
    }

    .ql-toolbar.ql-snow {
      border-top-left-radius: ${theme.general.borderRadius};
      border-top-right-radius: ${theme.general.borderRadius};
    }

    .ql-toolbar.ql-snow,
    .ql-container.ql-snow {
      border-color: ${theme.colors.alpha.black[30]};
    }

    .ql-container.ql-snow {
      border-bottom-left-radius: ${theme.general.borderRadius};
      border-bottom-right-radius: ${theme.general.borderRadius};
    }

    &:hover {
      .ql-toolbar.ql-snow,
      .ql-container.ql-snow {
        border-color: ${theme.colors.alpha.black[50]};
      }
    }
`
);


const StatusObvrska = [
  { title: 'Отворена' },
  { title: 'Нереализирана' },
  { title: 'Реализирана' },
];


class  EditForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: true,
            value: null,
            obvrskaID: this.props.obvrska.ID,
            opis: this.props.obvrska.opis,
            status_obvrska: this.props.obvrska.status_obvrska,
            start_date: this.props.obvrska.start_date,
            kraen_rok: this.props.obvrska.kraen_rok,
        }
    }
    componentDidMount() {
    }

    onClickForm= () =>  {
      this.state.obvrskaID &&
        window.makeRequest("PUT", `/api/Obvrski?ID=${this.state.obvrskaID}`,
        {
            opis: this.state.opis,
            status_obvrska: this.state.status_obvrska,
            start_date: this.state.start_date,
            kraen_rok: this.state.kraen_rok,
            updated_at: new Date(),

        }
        , true, (callback) => {

        }, (err) => {
            console.log('err', err);
        }, this.props.handleEditPredmetClose());

    }

    onChange = (e) => {
      this.setState({[e.target.name]: e.target.value});
    }
    onChangeOpis = (e, val) => {this.setState({opis: e})};


  render() {
        return(
          <>
            <DialogContent dividers sx={{ p: 3 }}>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={12}>
                  <Grid container spacing={3}>
                    <Grid mt={-3}item xs={12} >
                      <Grid>
                        <p>{'Опис на обврска'}:</p>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                      >
                        <EditorWrapper>
                          <ReactQuill onChange={this.onChangeOpis} value={this.state.opis} />
                        </EditorWrapper>
                      </Grid>
                    </Grid>
                    
                    <Grid item xs={12}  >
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Статус на обврска</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          value={this.state.status_obvrska}
                          name='status_obvrska'
                          label="Статус на обврска"
                          onChange={this.onChange}
                        >
                          {StatusObvrska.map((status) => {
                            return<MenuItem key={status.title} value={status.title}>{status.title}</MenuItem>
                          })}
                        </Select>
                      </FormControl>
                        </Grid>


                        <Grid item xs={12} >
                      <DatePicker
                        label={'Датум на почеток...'}
                        value={this.state.start_date}
                        inputFormat={moment(this.state.start_date).format('DD/MM/YYYY')}
                        onChange={(newValue) => {
                          this.setState({start_date: newValue});
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}  >
                      <DatePicker
                        label={'Краен рок...'}
                        value={this.state.kraen_rok}
                        inputFormat={moment(this.state.kraen_rok).format('DD/MM/YYYY')}
                        onChange={(newValue) => {
                          this.setState({kraen_rok: newValue});
                        }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
              <Button color="secondary" onClick={this.props.handleClose}>
                {'Затвори'}
              </Button>
              <Button
                type="submit"
                onClick={this.onClickForm}
                // startIcon={
                //     this.props.isSubmitting ? <CircularProgress size="1rem" /> : null
                // }
                // disabled={Boolean(this.props.errors.submit) || this.props.isSubmitting}
                variant="contained"
              >
                {'Измени'}
              </Button>
            </DialogActions>
          </>
        );

    }
}


export default EditForm;
