import React from 'react';
import {
  Card,
  CardHeader,
  List,
  ListItem,
  Typography,
  Divider,
  Avatar,
  Box,
  Pagination,
  ListItemText,
  ListItemAvatar,
  AccordionSummary,
  AccordionDetails,
  Accordion,
  Stack,
  Grid,
  Tooltip,
  IconButton,
  useTheme
  } from '@mui/material';
  import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
  import { styled } from '@mui/material/styles';
  import AddComment from './AddComment';
  import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
  import moment from 'moment';
import Label from 'src/components/Label';

  const AccordionSummaryWrapper = styled(AccordionSummary)(
    () => `
        &.Mui-expanded {
          min-height: 48px;
        }
  
        .MuiAccordionSummary-content.Mui-expanded {
          margin: 12px 0;
        }
    `
  );
  class Notifications extends React.Component  {
    constructor(props){
      super(props);
      this.state = {
        loaded: true,
        commentsPredmet: [],
        currentPage: 1,
        commentsPerPage: 3,
      }

    }
    componentDidMount() {
      this.renderComments();
    }

    renderComments = () => {
      window.makeRequest("GET", `/api/KomentarObvrskiUsersView?obvrskaID=${this.props.id}`, null, true, (callback) => {
        let commentsObvrska = callback.body.recordset.sort((a, b) => a - b).reverse()
        this.setState({commentsPredmet: commentsObvrska}, this.props.renderLogs);
      }, (err) => {
        console.log('err', err);
      
      });
    }

    deleteComment = (id) => {
      window.makeRequest("DEL", `/api/komentarObvrski?obvrskaID=${this.props.id}&ID=${id}`, null, true, (callback) => {
        let filteredArray = this.state.commentsPredmet.filter(item => item.ID !== id)
        this.setState({commentsPredmet: filteredArray}, this.props.renderLogs);
      }, (err) => {
        console.log('err', err);
      });
    }
    handleChange = (e,v) => {
      this.setState({currentPage: v})
    };
    returnStatusUser = (e) => {
      let color = '';
      let text = '';
      switch(e){
        case 'advokat':
          color = 'primary';
          text = 'Адвокат';
          break;
        case 'pripravnik':
          color = 'secondary';
          text = 'Приправник';
          break;
        case 'client':
          color = 'info';
          text = 'Клиент';
          break;
        case 'strucensorabotnik':
          color = 'warning';
          text = 'Стручен соработник';
          break;
        case 'smetkovodstvo':
          color = 'warning';
          text = 'Сметководство';
          break;
      }

      return<Label color={color}>{text}</Label>;
    }

    render() {
      const { commentsPredmet, currentPage, commentsPerPage } = this.state;
      const indexOfLastComment = currentPage * commentsPerPage;
      const indexOfFirstComment = indexOfLastComment - commentsPerPage;
      const currentComments = commentsPredmet.slice(indexOfFirstComment, indexOfLastComment);

      return (
        <Card>
          <CardHeader
            action={
              <AddComment logs={this.props.logs} userID={this.props.userID} renderComments={() => this.renderComments()} id={this.props.id} predmet={this.props.predmet} />
            } 
          />
          <Divider />
          <Accordion defaultExpanded={true}>
          <AccordionSummaryWrapper expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4">{'Коментари'}</Typography>
          </AccordionSummaryWrapper>
          <Divider />
  
            <AccordionDetails sx={{ p: 0 }}>
              <Stack  spacing={2}>

            {currentComments && currentComments.map((comment) => {
              // let name = this.getName(comment.userID);
              return(
                  <List key={comment.ID} disablePadding>

                    <ListItem sx={{py: 2.5, display: 'block'}}>
                      <Box display="flex" alignItems="center">
                        <ListItemAvatar>
                          <Avatar src=""/>
                        </ListItemAvatar>
                        <ListItemText
                          primary={comment.first_name}
                          primaryTypographyProps={{variant: 'h5', noWrap: true}}
                          secondary={this.returnStatusUser(comment.uloga)}
                          secondaryTypographyProps={{
                            variant: 'body2',
                            color: 'textSecondary',
                            noWrap: true
                          }}
                        />
                        <Box sx={{minWidth: 130}} display='flex' alignItems="center">


                          <Typography variant="subtitle1" align="right" pl={2} noWrap>
                            {moment(comment.created_at).format("DD.MM.YYYY")}
                          </Typography>
                          <Box pl={2} pr={2}>
                            {this.props.predmet.user_write === true && <Tooltip title={'Избриши коментар'} arrow>
                              <IconButton
                                onClick={() => this.deleteComment(comment.ID)}
                                color='error'
                                size="small"
                              >
                                <DeleteTwoToneIcon fontSize="small"/>
                              </IconButton>
                            </Tooltip>}
                          </Box>
                        </Box>
                      </Box>
                      <Typography dangerouslySetInnerHTML={{__html: comment.komentar}} target="_blank"
                                  variant="subtitle2" color="text.primary" sx={{pt: 1}}>
                      </Typography>
                    </ListItem>
                    <Divider component="li"/>
                  </List>
              );
              
            })}
           


              <Grid container alignItems="center" justifyContent="center" pb={1} >
                {commentsPredmet.length > 3 && <Pagination defaultPage={currentPage} shape={'rounded'}  size='medium' page={currentPage}  onChange={this.handleChange} count={Math.ceil(commentsPredmet.length / commentsPerPage)} variant="outlined" color="primary" />}
              </Grid>
            </Stack>
          </AccordionDetails>
          </Accordion>
                  
        </Card>
      );
    }
    
  }
  
  export default Notifications;
