import React from 'react';
import 'react-quill/dist/quill.snow.css';

import {
  Grid,
  Chip,
  DialogContent,
  Box,
  TextField,
  CircularProgress,
  Avatar,
  Autocomplete,
  Button,
} from '@mui/material';



class AddUser extends React.Component  {

  constructor(props) {
    super(props);
    this.state = {
      loaded: true,
      addUsers: [],
      isSubmitting: false,
    }
  }

  adduser = () => {
    let ALength = this.state.addUsers.length;
    let count = 0;
    this.state.addUsers.forEach((value) => {
      window.makeRequest("POST", `/api/PredmetUsers`, {
        userID:   value.ID,
        predmetID:   parseInt(this.props.id),
        user_read: true,
        user_write: true,
        created_at: new Date,
      }, true, (callback) => {
        count++;
        if(ALength === count) {
          this.props.addUsers();
          this.setState({isSubmitting: false})
        }
      }, (err) => {
        console.log('err', err);
      });
    });
  }
  handleCreateUsers = () => {
    this.setState({isSubmitting: true});
    setTimeout(() => {
      this.adduser()
    }, 1000)

 
  };
  onChangeVraboten = (event, val) => {this.setState({ addUsers: val})};
 
  render() {
    let users = this.props.clients.filter(o1 => !this.props.clients2.some(o2 => o1.ID === o2.userID));

    return (
      <>
        <DialogContent dividers sx={{ p: 3 }}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              justifyContent="flex-end"
              textAlign={{ sm: 'right' }}
            >
              <Box
                pr={3}
                sx={{ pt: 2, pb: { xs: 1, md: 0 } }}
                alignSelf="center"
              >
                <b>{'Вработени'}:</b>
              </Box>
            </Grid>
            <Grid
              sx={{
                mb: 2
              }}
              item
              xs={12}
              sm={8}
              md={9}
            >
              <Autocomplete
                multiple
                sx={{ m: 0 }}
                limitTags={2}
                onChange={this.onChangeVraboten}
                options={users}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Avatar sx={{ mr: 1 }} src={''} />
                    {option.first_name}
                  </li>
                )}
                getOptionLabel={(option) => option.first_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder={'Селектирај вработени за додавање на предметот...'}
                  />
                )}
                renderTags={(members, getTagProps) =>
                  members.map((ev, index) => (
                    <Chip
                      label={ev.first_name}
                      {...getTagProps({ index })}
                      avatar={<Avatar src={ev.avatar} />}
                    />
                  ))
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              textAlign={{ sm: 'right' }}
            ></Grid>
            <Grid
              sx={{
                mb: 2
              }}
              item
              xs={12}
              sm={8}
              md={9}
            >
              <Button
                sx={{ mr: 2 }}
                type="submit"
                // startIcon={
                //   isSubmitting ? <CircularProgress size="1rem" /> : null
                // }
                // disabled={Boolean(errors.submit) || isSubmitting}
                variant="contained"
                size="large"
                onClick={() => this.handleCreateUsers()}
                disabled={this.state.isSubmitting}
                startIcon={
                  this.state.isSubmitting ? <CircularProgress size="1rem" /> : null
                }

              >
                {'Додај'}
              </Button>
              <Button
                color="secondary"
                size="large"
                variant="outlined"
                onClick={this.props.closeDialog}
              >
                {'Затвори'}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  }
  
}

export default AddUser;
