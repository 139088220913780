import React from 'react';
import {
  Button,
  Card,
  Box,
  CardHeader,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PreviewContactsClients from "../Preview/PreviewContactsClients";


const AvatarWrapper = styled(Avatar)(
  ({ theme }) => `
    width: ${theme.spacing(4)};
    height: ${theme.spacing(4)};
`
);


class ListingContactsClients extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      openContact: false,
      openClient: false,
      client: [],
    }
  }
  onClose = () => {
    this.setState({openContact: false,openClient: false, client: ''})
  }
  handleOpen = (e) => {

    this.setState({client: e,openClient: true})
  }

  handleOpenContact = (e) => {
    this.setState({contact: e,openContact: true})
  }


  render() {
    return (
      <Card variant="outlined" style={{borderTop: 0}}>
        {this.props.sprotivna ?
          <CardHeader style={{textAlign: 'left'}} title={this.props.sprotivna}  />
          :
          <CardHeader style={{textAlign: 'left'}} title={'Клиенти'}  />
        }
        <Divider/>
        {this.props.clients && this.props.clients.map((client) => {
          return<List key={client.ID}>
              <ListItem alignItems="center">
                <ListItemAvatar>
                  <AvatarWrapper
                    variant="rounded"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <>
                      <b>{client.Naziv}</b>
                    </>
                  }
                />
                {this.props.type !== 'client' && <Box alignSelf="center">
                  <Button size="small" variant="outlined" onClick={() => this.handleOpen(client)}>
                    {'Преглед'}
                  </Button>
                </Box>}
              </ListItem>
            </List>
        })
        }
        {this.props.contacts && this.props.contacts.map((contact) => {
          return<List key={contact.ID}>
              <ListItem alignItems="center">
                <ListItemAvatar>
                  <AvatarWrapper
                    variant="rounded"
                  />
                </ListItemAvatar>

                <ListItemText
                  primary={
                    <>
                      <b>{contact.naziv_sprotivna_stranka}</b>
                    </>
                  }
                />
                {this.props.type !== 'client' && <Box alignSelf="center">
                  <Button size="small" variant="outlined" onClick={() => this.handleOpenContact(contact)}>
                    {'Преглед'}
                  </Button>
                </Box>}
              </ListItem>
            </List>
        })
        }
        {this.state.client &&
        <PreviewContactsClients client={this.state.client} onClose={this.onClose} open={this.state.openClient}  />}
        {this.state.contact &&
        <PreviewContactsClients contact={this.state.contact} onClose={this.onClose} open={this.state.openContact}  />}

      </Card>
    );
  }

}

export default ListingContactsClients;
