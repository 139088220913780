import React, {forwardRef} from 'react';
import {
    DialogTitle,
    Grid,
    Slide,
    DialogContent ,
    Button,
    Typography,
    Dialog,
} from '@mui/material';
import {styled} from "@mui/material/styles/index";
import Doc from './Doc';

const DialogWrapper = styled(Dialog)(
    () => `
      .MuiDialog-paper {
        overflow: visible;
      }
`
)
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});


class DocView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            load: false,
            dpath: `/api/download?path=`,
            doc: '',
            viewLink: null,
            click: ``,
            patt: null,

        }
    }

    componentDidMount() {
        if(this.props.path !== null) {
            let path = this.props.path;
            let uri = this.state.dpath;
            let combine = uri+path;
            this.setState({path : path, click:combine,  viewLink: [{uri: combine}], patt: combine});
            this.getPath();
        }
    }

    getPath = () => {
        this.setState({load: true})
    };

    readFile = () => {
        if(this.props.path !== null) {
            let path = this.props.path;
            let uri = this.state.dpath;
            let combine = uri+path;
            this.setState({path : path, click:combine,  viewLink: [{uri: combine}], patt: combine}, () => this.getPath());
        }
    }


    onClose = () => {
        this.setState({click: '',viewLink: null, load: false}, this.props.onClose())
    }


    render() {
        return (
            <DialogWrapper
                open={this.props.open}
                fullWidth
                maxWidth={'full'}
                TransitionComponent={Transition}
                keepMounted
                onClose={this.onClose}
            >
                <DialogTitle sx={{p: 1.5}} display='inline-flex' justifyContent='space-between'>
                    <Typography variant="h4" gutterBottom>
                        {'Преглед на документ'}
                    </Typography>
                    <Grid
                        sx={{
                            mb: 0
                        }}
                        item

                    >
                        <Button
                            sx={{mr: 2}}
                            color="secondary"
                            size="small"
                            variant="outlined"
                            onClick={this.onClose}
                        >
                            {'Затвори'}
                        </Button>
                        <Button
                            href={this.state.click}
                            disabled={this.state.click === ''}
                            // onClick={this.state.test[0].uri}
                            type="submit"
                            variant="contained"
                            size="small"
                        >
                            {'Превземи'}
                        </Button>

                        <Button
                            sx={{ml: 2}}
                        color="secondary"
                        size="small"
                        variant="outlined"
                        onClick={this.readFile}
                        >
                        {'Преглед'}
                        </Button>
                    </Grid>
                </DialogTitle>
                        {this.state.load && <Doc  viewLink={this.state.viewLink} />}
            </DialogWrapper>
        )
    };

}

export default DocView;
