import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import image from '../Logo/icon-256x256.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        width: 8em;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  const { t } = useTranslation();

  return (
    <Tooltip title={t('Медарски')} arrow>
      <LogoWrapper to="/">
             <img style={{width: 100, height: 100,marginTop: -20, marginBottom: -20, }} src={image} />
      </LogoWrapper>
    </Tooltip>
  );
}

export default Logo;
